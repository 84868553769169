<!-- Vista inicial de Buja -->
<div id="init" #initialView>
  <div [@fadeIn]="isVisible" [@fadeOut]="isVisible"
    class="initial-view d-flex gap-5 flex-column justify-content-center align-items-center">

    <div class="d-flex flex-wrap gap-4 justify-content-center align-items-center text-center text-md-start">
      <div class="text-center position-relative burbuja-container" *ngFor="let initialButton of initialButtonsData"
        (click)="skipInitialView(initialButton)" (keydown.enter)="skipInitialView(initialButton)" tabindex="0">
        <img src="../../../assets/images/assetsbuja/buja.png" class="burbuja img-fluid" alt="Buja">

        <div class="position-absolute top-50 start-50 translate-middle ">
          <img src="../../../assets/images/assetsbuja/bujalogo.png" class="buja-logo img-fluid" alt="Buja">
          <p class="burbuja-text">{{isLoading ? 'Conectando...' : 'Clic para iniciar'}}</p>
        </div>
      </div>

      <img src="../../../assets/images/assetsbuja/asistentelimpieza.png" class="asistente-limpieza img-fluid"
        alt="Buja">
    </div>

    <div class="footer-buja">
      <div class="d-flex flex-wrap justify-content-center align-items-center">
        <img src="../../../assets/images/assetsbuja/relaxpontumente.png" class="relax-buja img-fluid" alt="Buja">
        <img src="../../../assets/images/assetsbuja/blancoxlogo.png" width="200px" class="blancox-logo img-fluid"
          alt="Blancox">
      </div>
    </div>
  </div>
</div>
<!-- Vista inicial de Buja -->
import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Directive({
  selector: '[appSafeHtml]'
})
export class SafeHtmlDirective implements OnChanges {

  @Input() appSafeHtml!: string | SafeHtml;

  constructor(private el: ElementRef, private sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['appSafeHtml']) {
      const html = this.sanitizer.bypassSecurityTrustHtml(this.appSafeHtml ? this.appSafeHtml.toString() : '');
      this.el.nativeElement.innerHTML = html;
    }
  }

}

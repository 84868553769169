import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UseCasesService } from 'src/app/services/chat/use-cases.service';
import { fadeIn, fadeOut } from '../ui-view/ui-view.component';
import { PrintService } from 'src/app/services/utils/print.service';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pdf-viewer-modal',
  templateUrl: './pdf-viewer-modal.component.html',
  styleUrls: ['./pdf-viewer-modal.component.scss'],
  animations: [fadeOut, fadeIn]
})
export class PdfViewerModalComponent implements OnInit {
  pdfUrl = '';
  modalStatus = 'hidden';
  showPdf = false;
  documentToPrint!: Blob;
  documentType = '';
  byeMessage = "Adios";

  @ViewChild('pdfModal', { static: true }) pdfModal!: ElementRef;
  modal!: NgbModalRef;

  constructor(
    private modalService: NgbModal,
    private useCasesService: UseCasesService,
    private printService: PrintService,
    private webSocketService: WebSocketService) { }

  ngOnInit(): void {
    this.initServices();
  }

  private initServices() {
    this.useCasesService.getPrintDocument().subscribe((blobDocument) => {
      this.documentToPrint = blobDocument;
      this.pdfUrl = URL.createObjectURL(blobDocument);
      this.showPdf = true;
      this.modalStatus = 'visible';
      this.modal = this.modalService.open(this.pdfModal, { centered: true, backdrop: "static", size: "lg" })
    });
    this.useCasesService.getDocumentType().subscribe((docType: string) => {
      this.documentType = docType;
    })

    this.webSocketService.getEndConversationEvent().subscribe(() => {
      if (this.modal)
        this.modal.close();
    })
  }

  public cancel() {
    this.modalStatus = 'hidden';
    this.showPdf = false;
    this.sendGoodbye();
    this.modal.close();
  }

  public printDocument() {
    this.printService.printBlob(this.documentToPrint, this.documentType);
    this.cancel();
  }

  private sendGoodbye() {
    this.webSocketService.SendMessageThroughSocket(this.byeMessage);
  }
}

import { Injectable, OnInit } from '@angular/core';
import * as THREE from 'three';
import { ThreeClockService } from '../utils/three-clock.service';
import { Subscription } from 'rxjs';
import { AvatarLoaderService } from '../avatar/avatar-loader.service';
import { ThreeAvatar } from 'src/app/models/three-avatar.model';
import { AzureLipsyncService } from '../lipsync/azure-lipsync.service';
import { UnityControllerService } from 'src/app/services/unity-controller.service';
import { avatarAnimTimescale } from './avatar-animation-timescale';

@Injectable({
  providedIn: 'root'
})
export class AnimationSystemService {
  subscriptions$: Subscription[] = [];

  private mixer: THREE.AnimationMixer;
  private idleClips: THREE.AnimationAction[][] = [];
  private talkClips: THREE.AnimationAction[][] = [];
  private welcomeClips: THREE.AnimationAction[][] = [];
  private defaultClip: THREE.AnimationAction[][] = [];
  private pointClips: THREE.AnimationAction[][] = [];
  private finishCycle!: any;
  private lastClip: THREE.AnimationAction[] = [];
  private lastTalkClip: THREE.AnimationAction[] | null = null;
  private currentAnimationDuration!: number;
  private isExecutingTalkAnimation = false;
  private currentAudioDuration!: number;
  private animationTimeCount = 0;
  private avatarHasAnimations = false;
  private avatarName!: string;

  constructor(private clockService: ThreeClockService, private avatarLoaderService: AvatarLoaderService,
    private lipsyncService: AzureLipsyncService, private unityControllerService: UnityControllerService) {
    this.idleClips = [];
    this.talkClips = [];
    this.welcomeClips = [];
    this.pointClips = [];
    this.mixer = new THREE.AnimationMixer(new THREE.Mesh);
    this.initServices();
  }

  initServices() {
    this.subscriptions$.push(
      this.avatarLoaderService.getAvatarInfo().subscribe((avatarInfo: ThreeAvatar) => {
        this.avatarName = avatarInfo.name;
        if(avatarInfo.animations) {
          this.avatarHasAnimations = true;
          this.setAnimationMixer(avatarInfo.object as THREE.Mesh, avatarInfo.animations);
        }
      }),
      this.lipsyncService.getMovementState().subscribe(movements => this.playAnimation(movements)),
      this.lipsyncService.getLipsyncState().subscribe((talking: boolean) => {
        if (!talking) this.playAnimation('default');
      }),
      this.unityControllerService.getSpeechBlendData().subscribe((speechData: any) => {
        this.currentAudioDuration = speechData.audioDuration / 10000000;
      })
    );
  }

  public setAnimationMixer(mesh: THREE.Mesh, animations: THREE.AnimationClip[]) {
    this.mixer = new THREE.AnimationMixer(mesh);
    this.loadAnimations(animations);
  }

  public update() {
    if(!this.avatarHasAnimations) return;
    this.mixer.update(this.clockService.clock.getDelta());
    this.checkAnimationState();
  }

  private loadAnimations(animations: THREE.AnimationClip[]) {
    animations.forEach(animation => {
      this.fixAnimations(animation);
      if (animation.name.toLowerCase().includes("idling")) {
        this.defaultClip.push([this.mixer.clipAction(animation)]);
      }
      else
        if (animation.name.toLowerCase().includes("idle")) {
          if(this.idleClips.length === 0) this.idleClips.push([this.mixer.clipAction(animation)]);
          else this.idleClips[0].push(this.mixer.clipAction(animation));
        }
        else if (animation.name.toLowerCase().includes("talk")) {
          this.talkClips.push([this.mixer.clipAction(animation)]);
        }
        else if (animation.name.toLowerCase().includes('greeting')) {
          if(this.welcomeClips.length === 0) this.welcomeClips.push([this.mixer.clipAction(animation)]);
          else this.welcomeClips[0].push(this.mixer.clipAction(animation));
        }
        else if(animation.name.toLocaleLowerCase().includes('point')) {
          this.pointClips.push([this.mixer.clipAction(animation)]);
        }
    });
    if (this.defaultClip.length === 0) {
      this.defaultClip.push(this.idleClips[0]);
    }
    this.mixer.timeScale = 1;
    this.finishCycle = this.onCycleFinished.bind(this);
    this.mixer.addEventListener('loop', this.finishCycle);
    this.defaultClip[0].forEach((animAction) => {
      animAction.timeScale = avatarAnimTimescale[this.avatarName] ? avatarAnimTimescale[this.avatarName].default : 1;
      animAction.play();
    });
    this.lastClip = this.defaultClip[0];
    this.currentAnimationDuration = this.lastClip[0].getClip().duration;
  }

  private fixAnimations(animation: THREE.AnimationClip) {
    animation.tracks.forEach(track => {
      if (track.name.toLowerCase().includes("jawroot") || track.name.toLowerCase().includes("base_head") || track.name.toLowerCase().includes("eye")) {
        const index = animation.tracks.indexOf(track);
        animation.tracks.splice(index, 3);
      }
    })
  }

  public playAnimation(animation: string) {
    if(!this.avatarHasAnimations) return;
    if (animation.toLowerCase().includes("talk")) {
      if(this.talkClips.length > 0) this.setAnimation(this.talkClips, avatarAnimTimescale[this.avatarName] ? avatarAnimTimescale[this.avatarName].talk : 1);
    }
    else if (animation.toLowerCase().includes("idle")) {
      if(this.idleClips.length > 0) this.setAnimation(this.idleClips, avatarAnimTimescale[this.avatarName] ? avatarAnimTimescale[this.avatarName].idle : 1);
    }
    else if (animation.toLowerCase().includes("greeting") || animation.toLowerCase().includes("goodbye")) {
      if(this.welcomeClips.length > 0) this.setAnimation(this.welcomeClips, avatarAnimTimescale[this.avatarName] ? avatarAnimTimescale[this.avatarName].greeting : 1);
    }
    else if (animation.toLocaleLowerCase().includes("point")) {
      if(this.pointClips.length > 0) this.setAnimation(this.pointClips, avatarAnimTimescale[this.avatarName] ? avatarAnimTimescale[this.avatarName].point : 1);
    }
    else {
      this.setDefaultAnimation();
    }
  }

  private setDefaultAnimation() {
    if (this.lastClip === this.defaultClip[0]) return;
    this.animationTimeCount = 0;
    this.defaultClip[0].forEach((action) => {
      action.timeScale = avatarAnimTimescale[this.avatarName] ? avatarAnimTimescale[this.avatarName].default : 1;
      action.reset();
    });
    this.defaultClip[0].forEach((action) => action.fadeIn(0.5).play());
    this.lastClip.forEach((action) => action.fadeOut(0.5));
    this.lastClip = this.defaultClip[0];
    this.currentAnimationDuration = this.lastClip[0].getClip().duration;
    this.isExecutingTalkAnimation = false;
  }

  private setAnimation(clips: THREE.AnimationAction[][], timeScale: number, fadeDuration = 0.75) {
    let index = Math.floor(Math.random() * clips.length);
    if (this.lastClip.length === 0) {
      clips[index].forEach((clip) => clip.play());
      this.defaultClip[0].forEach((clip, i) => clip.crossFadeTo(clips[index][i], 0.5, true));
      this.lastClip = clips[index];
      return;
    }
    if (this.lastClip[0] == clips[index][0] || (this.lastTalkClip && this.lastTalkClip[0] == clips[index][0])) {
      index = index == clips.length - 1 ? 0 : index + 1;
    }
    clips[index].forEach(action => action.timeScale = timeScale);
    clips[index].forEach(action => action.reset());
    this.lastClip.forEach((action, i) => action.crossFadeTo(clips[index][i], fadeDuration, false).play());
    this.lastClip = clips[index];
    this.currentAnimationDuration = this.lastClip[0].getClip().duration;
    if (clips === this.talkClips) {
      this.lastTalkClip = clips[index];
      this.isExecutingTalkAnimation = true;
      this.animationTimeCount += this.currentAnimationDuration;
    }
    else {
      this.isExecutingTalkAnimation = false;
    }
  }

  private onCycleFinished() {
    if (this.lastClip === this.defaultClip[0]) {
      this.setAnimation(this.idleClips, avatarAnimTimescale[this.avatarName] ? avatarAnimTimescale[this.avatarName].idle : 1, 0);
    }
    else if (!this.talkClips.includes(this.lastClip)) {
      this.setDefaultAnimation();
    }
  }

  private checkAnimationState() {
    if (!this.lastTalkClip || !this.lastTalkClip[0].time) return;
    if (this.isExecutingTalkAnimation && this.lastTalkClip[0].time / this.currentAnimationDuration > 0.7) {
      if (this.currentAudioDuration - this.animationTimeCount > 4.5 ) {
        this.setAnimation(this.talkClips, avatarAnimTimescale[this.avatarName] ? avatarAnimTimescale[this.avatarName].talk : 1, this.currentAnimationDuration - this.lastTalkClip[0].time);
      }
      else {
        this.setDefaultAnimation();
      }
    }
  }
}

import { CameraMovementService } from '../services/movement/camera/camera-movement.service';
import * as THREE from 'three';
import { GazeFacadeService } from '../services/movement/gaze/gaze-facade.service';
import { cameraPositions, cameraRotations, targetPositions, epmPositions, epmRotations, targetEpm } from '../dictionaries/camera-positions';

export class Resizer {
    private container: HTMLDivElement;
    private camera: THREE.PerspectiveCamera;
    private target: THREE.Object3D;
    private renderer: THREE.WebGLRenderer;
    private cameraPositions: { [key: string]: THREE.Vector3 };
    private cameraRotations: { [key: string]: THREE.Quaternion };
    private targetPositions: { [key: string]: THREE.Vector3 };
    private currentPos = 'Standard';
    private lastPos = 'Standard';

    constructor(
        camera: THREE.PerspectiveCamera,
        renderer: THREE.WebGLRenderer,
        container: HTMLDivElement,
        target: THREE.Object3D,
        epm: boolean,
        private cameraMovementService: CameraMovementService,
        private headMovementService: GazeFacadeService
    ) {
        this.container = container;
        this.camera = camera;
        this.target = target;
        this.renderer = renderer;
        this.cameraPositions = epm ? epmPositions : cameraPositions;
        this.cameraRotations = epm ? epmRotations : cameraRotations;
        this.targetPositions = epm ? targetEpm : targetPositions;
        this.setSize();
        window.addEventListener('resize', () => {
            this.setSize();
        });
        cameraMovementService.getCameraState().subscribe((cameraState: string) => {
            this.currentPos = cameraState;
            this.setSize();
        });
    }

    private setSize() {
        this.camera.aspect = this.container.clientWidth / this.container.clientHeight;
        
        this.currentPos = window.innerWidth / window.innerHeight < 1 ? "Mobile" : this.currentPos;
        if(this.currentPos === "Mobile") this.currentPos = window.innerWidth <= 450 && window.innerHeight <= 570 ? "Widget" : this.currentPos;
        this.camera.fov = 30;
        this.configureCamera();
        this.camera.updateProjectionMatrix();

        this.renderer.setSize(
            this.container.clientWidth,
            this.container.clientHeight
        );
        this.renderer.setPixelRatio(window.devicePixelRatio);
    }

    private configureCamera() {
        this.setCameraPosition();
        this.changeCamera();
    }

    private setCameraPosition() {
        if (this.currentPos.includes("Mid"))
            this.currentPos = "Mid"
        else if (this.currentPos.includes("CloseUp"))
            this.currentPos = "CloseUp"

        const cameraPos = this.cameraPositions[this.currentPos];
        const cameraRot = this.cameraRotations[this.currentPos];
        this.camera.position.set(cameraPos!.x, cameraPos!.y, cameraPos!.z);
        this.camera.setRotationFromQuaternion(cameraRot!);
        this.setTargetPosition();
    }

    private setTargetPosition() {
        const targetPos = this.targetPositions[this.currentPos];
        this.target.position.set(targetPos.x, targetPos.y, targetPos.z);
        this.headMovementService.setTargetPoint(this.target);
    }

    private changeCamera() {
        this.cameraMovementService.changeStandardCameraTarget(this.cameraPositions["Standard"], this.cameraRotations["Standard"]);
        this.cameraMovementService.changeMidCameraTarget(this.cameraPositions["Mid"], this.cameraRotations["Mid"]);
        this.cameraMovementService.changeCloseUpCameraTarget(this.cameraPositions["CloseUp"], this.cameraRotations["CloseUp"]);
    }

    setNoChat(value: boolean) {
        if (value) {
            this.lastPos = this.currentPos;
            this.currentPos = "NoChat"
        }
        else
            this.currentPos = this.lastPos;
        this.setSize();
    }
}

import { Injectable } from '@angular/core';
import { QueueProcessorService } from './chat/queue-processor.service';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UrlParams } from 'src/app/models/parameters.model';
import { TranslateService } from '@ngx-translate/core';
import { DatumButtons } from '../models/tenant-data.model';
import { Conversation } from '../models/conversation.model';
import { TranslatorService } from './translator.service';
import { TipoNLP } from '../models/enums/tiponlp';import { ConversationState } from '../models/enums/conversation.state';
import { ExternalNlpDict, LlmEventDict, SpeakerEventDict, WatsonEventDict } from '../models/constants/websocket-event-dict';
import { FileDTO } from '../models/file-attachment.model';

declare global {
  interface Window { SendLanguageChange(langVoice: string): void; }
  interface Window { receiveAvatarLoadedUnityController(): void; }
}

declare const window: Window & typeof globalThis;

interface addressInfo {
  idDepartamento: string;
  idMunicipio: string;
  direccion: string;
}

const startDictionary: { [key: string]: string } = {
  "es": "Hola",
  "en": "Hello",
  "fr": "Salut",
  "pt": "Olá",
  "de": "Hallo",
  "it": "Ciao",
  "zh-Hans": "你好"
}
@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  private holaStartMessage = "Hola";
  private watsonStartFlow!: string;
  private socket: any;
  private languageCode?: string = 'es';
  private tenantG!: string;
  private canalG!: string;
  private tokenG!: string;
  private ubicationG!: string;
  private idSession: string | null = null;
  private idConnection: string | null = null;
  private idClienteG = '0';

  private _inConnectionProcess!: boolean;
  private _isConnected = false;
  private _connectedFirstTime = false;

  private nlpType!: TipoNLP;
  private conversationStatus = ConversationState.STARTING;

  private eventConnection = new Subject<boolean>();
  private endConversation = new Subject<void>();
  private eventServiceUnavailable = new Subject<boolean>();
  private eventChatState = new Subject<boolean>();
  private maxSessionsState = new Subject<boolean>();

  public paramsWS!: UrlParams;

  private addressInfo: addressInfo = {
    idDepartamento: "5",
    idMunicipio: "0",
    direccion: "0",
  };

  constructor(
    private queueProcessorService: QueueProcessorService,
    private translate: TranslateService,
    private translatorService: TranslatorService
  ) {
    // Get the language code from the browser
    const lang = this.translate.getBrowserLang();
    this.languageCode = lang;
    // Get the language code from the service
    this.translate.onLangChange.subscribe((event: any) => {
      this.languageCode = event.lang;
      this.holaStartMessage = startDictionary[this.languageCode!];
      const langVoice = translatorService.getVoiceCode();
      try {
        window.SendLanguageChange(langVoice);
      } catch (error) {
        return;
      }
    });

    window.receiveAvatarLoadedUnityController = () => {
      this.setChatState(true);
    }
  }

  public setEndConversation(endConversationStatus: boolean) {
    if (this.conversationStatus === ConversationState.STARTING) {
      return;
    }

    this.conversationStatus = endConversationStatus ? ConversationState.FINISHING : ConversationState.TALKING;
    // this._isEndConversation = endConversationStatus; // POR SI ACASO FALLA LA LINEA ANTERIOR
    this.SendMessageThroughSocket("");
    this.endConversation.next();
  }

  public getEndConversationEvent(): Observable<void> {
    return this.endConversation.asObservable();
  }

  public getTokenEvent(): Observable<any> {
    return this.eventConnection.asObservable();
  }

  public getServiceUnavailableEvent(): Observable<any> {
    return this.eventServiceUnavailable.asObservable();
  }

  /* ------ Websocket Connection ------ */
  public setUpWs(paramsWS: UrlParams, reconnection = false) {
    this.setChatState(true);
    this.paramsWS = paramsWS;
    this.tenantG = paramsWS.Tenant;
    const tokenLocalStorage = localStorage.getItem('token') || environment.webSocket.token;
    this.tokenG = tokenLocalStorage;
    this.canalG = paramsWS.Canal;
    this.ubicationG = paramsWS.Ubicacion || '';
    this.idSession = reconnection ? sessionStorage.getItem('idSession') : null;
    this.idConnection = reconnection ? sessionStorage.getItem('idConnection') : null;

    if (!this._isConnected) {
      const urlWebSocket = environment.webSocket.url
      this.socket = new WebSocket(urlWebSocket, "websocket");
      this._isConnected = true;
      this._inConnectionProcess = true;

      const sendMessage = () => {
        if (this.socket.readyState === WebSocket.OPEN) {
          this.conversationStatus = ConversationState.STARTING;

          this.SendMessageThroughSocket("");
          this.setChatState(true);
        } else {
          setTimeout(sendMessage, 100);
        }
      };

      this.socket.onopen = () => {
        sendMessage();
      };

      this.socket.onmessage = (data: { data: any }) => {       
        const dataParsed = JSON.parse(data.data);
        if(dataParsed.idSession) sessionStorage.setItem('idSession', dataParsed.idSession);
        if(dataParsed.idConnection) sessionStorage.setItem('idConnection', dataParsed.idConnection);

        if (dataParsed.estado === 'sesiones excedidas') {
          this.setMaxSessionsState(true);
        }

        if (this._inConnectionProcess) {
          this._inConnectionProcess = false;

          if (!this._connectedFirstTime) {
            this.eventConnection.next(true);
            this._connectedFirstTime = true;
          } else if(this.nlpType === TipoNLP.ninguno) {
            this.conversationStatus = ConversationState.STARTING
            this.SendMessageThroughSocket('');
          }
        } else {
          const tokenReceived = dataParsed.metadata?.token;
          localStorage.setItem('token', tokenReceived);
          this.tokenG = tokenReceived;
          this.handlerMessageIncoming(dataParsed);
        }
      };

      this.socket.onclose = () => {
        this.socket = null;
        this._isConnected = false;
        this._inConnectionProcess = false;
        this.setUpWs(this.paramsWS, true);
      };
    }
  }

  public restartConversation() {
    this.setChatState(true);
    this.conversationStatus = ConversationState.STARTING;
    const message = this.nlpType === TipoNLP.llm ? this.holaStartMessage : this.watsonStartFlow;
    this.SendMessageThroughSocket(message);
  }

  public startConversation(data: DatumButtons) {
    this.nlpType = data.tipoNLP;
    let message = "";
    this.paramsWS.Flujo = data.textoBoton;
    this.paramsWS.IdFlujo = data.idFlujo;

    if (this.nlpType === TipoNLP.llm || this.nlpType === TipoNLP.nlpExterno) {
      message = this.holaStartMessage;

    } else {
      this.watsonStartFlow = data.intencion
      message = data.intencion;
    }

    if (this._isConnected) {
      this.SendMessageThroughSocket(message);
    }
  }

  public handlerMessageIncoming(jsonData: Conversation) {
    this.setChatState(true);
    this.idClienteG = jsonData.metadata.idCliente;
    if(jsonData.conversation?.length === 0) return;
    this.queueProcessorService.addToQueue(jsonData);
    if (jsonData?.conversation![0].detail!.length > 0) {
      if (jsonData.conversation![0].detail![0].accion === "showModalServiceUnavailable") {
        this.eventServiceUnavailable.next(true);
      }
    }
  }

  public SendMessageThroughSocket(messageToSend: string, file?: FileDTO) {
    this.setChatState(false);
    let jsonMessage = {};
    if (this._inConnectionProcess) {
      jsonMessage = this.getOpenSessionData();
      this._isConnected = true;
      if (this.socket) this.socket.send(jsonMessage);
      return;
    }

    jsonMessage = this.getNlpMessage(messageToSend, file);
    window.totalSTT_Time = 0;
    if (this.socket) this.socket.send(jsonMessage);
  }

  private getNlpMessage(message: string, file?: FileDTO){
    let json = {};
    switch(this.conversationStatus){
      case ConversationState.STARTING:
        switch(this.nlpType){
          case TipoNLP.watson:
            json = this.getWatsonStartConversationData(message);
            break;
          case TipoNLP.llm:
            json = this.getLlmStartConversationData(message);
            break;
          case TipoNLP.ninguno:
            json = this.getSpeakerStartConversationData(message);
            break;
          case TipoNLP.nlpExterno:
            json = this.getExternalNlpStartConversationData(message);
        }
        this.conversationStatus = ConversationState.TALKING;
        break;
      case ConversationState.TALKING:
        switch(this.nlpType){
          case TipoNLP.watson:
            json = this.getWatsonConversationData(message);
            break;
          case TipoNLP.llm:
            json = this.getLlmConversationData(message);
            break;
          case TipoNLP.nlpExterno:
            json = this.getExternalNlpConversationData(message, file);
        }
        break;
      case ConversationState.FINISHING:
        switch(this.nlpType){
          case TipoNLP.watson:
            json = this.getWatsonStopConversationData();
            break;
          case TipoNLP.llm:
            json = this.getLlmStopConversationData();
            break;
          case TipoNLP.nlpExterno:
            json = this.getExternalNlpStopConversationData();
        }
        this.conversationStatus = ConversationState.STARTING;
    }
    return json;
  }

  //#region JSON-Builders
  private getOpenSessionData(): string {
    return JSON.stringify({
      event: "openSession",
      data: {
        ...this.getBasicInfo(),
        idSession: this.idSession,
        idConnection: this.idConnection
      },
    });
  }

  private getLlmStartConversationData(messageToSend: string): string {
    return JSON.stringify({
      event: LlmEventDict[ConversationState.STARTING],
      data: {
        ...this.getBasicInfo(),
        language: this.languageCode,
        message: messageToSend,
      },
    });
  }

  private getLlmStopConversationData(): string {
    return JSON.stringify({
      event: LlmEventDict[ConversationState.FINISHING],
      data: {
        ...this.getBasicInfo(),
        idCliente: this.idClienteG
      },
    });
  }

  private getLlmConversationData(messageToSend: string): string {
    const timeSTTG = this.getSttTime();
    return JSON.stringify({
      event: LlmEventDict[ConversationState.TALKING],
      data: {
        ...this.getBasicInfo(),
        idCliente: this.idClienteG,
        language: this.languageCode,
        message: messageToSend,
        tiempoStt: timeSTTG,
      }
    });
  }

  private getWatsonStartConversationData(messageToSend: string): string {
    return JSON.stringify({
      event: WatsonEventDict[ConversationState.STARTING],
      data: {
        ...this.getBasicInfo(),
        language: this.languageCode,
        transaction: messageToSend,
        officeInfo: {
          nameLocation: "",
          idLocation: "",
        },
      },
    });
  }

  private getWatsonStopConversationData(): string {
    return JSON.stringify({
      event: WatsonEventDict[ConversationState.FINISHING],
      data: {
        ...this.getBasicInfo(),
        idCliente: this.idClienteG
      },
    });
  }

  private getWatsonConversationData(messageToSend: string): string {
    const timeSTTG = this.getSttTime();
    const { idDepartamento, idMunicipio, direccion } = this.addressInfo
    return JSON.stringify({
      event: WatsonEventDict[ConversationState.TALKING],
      data: {
        ...this.getBasicInfo(),
        idCliente: this.idClienteG,
        language: this.languageCode,
        message: messageToSend,
        tiempoStt: timeSTTG,
        officeInfo: {
          nameLocation: "",
          idLocation: "",
        },
        addressInfo: {
          idDepartamento: idDepartamento,
          idMunicipio: idMunicipio,
          direccion: direccion,
        },
      },
    });
  }

  private getSpeakerStartConversationData(messageToSend: string): string {
    return JSON.stringify({
      event: SpeakerEventDict[ConversationState.STARTING],
      data: {
        ...this.getBasicInfo(),
        language: this.languageCode,
        transaction: messageToSend,
        officeInfo: {
          nameLocation: "",
          idLocation: "",
        },
      },
    });
  }

  private getExternalNlpStartConversationData(messageToSend: string): string {
    return JSON.stringify({
      event: ExternalNlpDict[ConversationState.STARTING],
      data: {
        ...this.getBasicInfo(),
        idConnection: sessionStorage.getItem('idConnection'),
        message: messageToSend,
        language: this.languageCode,
      }
    });
  }

  private getExternalNlpConversationData(messageToSend: string, file?: FileDTO): string {
    const timeSTT = this.getSttTime();
    return JSON.stringify({
      event: ExternalNlpDict[ConversationState.TALKING],
      data: {
        ...this.getBasicInfo(),
        idConnection: sessionStorage.getItem('idConnection'),
        idCliente: this.idClienteG,
        message: messageToSend,
        tiempoStt: timeSTT,
        language: this.languageCode,
        file: file
      }
    });
  }

  private getExternalNlpStopConversationData(){
    return JSON.stringify({
      event: ExternalNlpDict[ConversationState.FINISHING],
      data: {
        ...this.getBasicInfo(),
        idConnection: sessionStorage.getItem('idConnection'),
        idCliente: this.idClienteG,
        language: this.languageCode 
      }
    });
  }

  private getBasicInfo(){
    return {
      tenant: this.tenantG,
      canal: this.canalG,
      token: this.tokenG,
      useCase: this.paramsWS.CasoDeUso,
      flujo: this.paramsWS.Flujo,
      ubicacion: this.ubicationG,
      idFlujo: this.paramsWS.IdFlujo,
      idUseCase: this.paramsWS.IdCasoDeUso,
    }
  }

  getSttTime(): number{
    const timeSTTG = window.totalSTT_Time;
    return Math.ceil(timeSTTG);
  }
  //#endregion

  public setChatState(chatState: boolean) {
    this.eventChatState.next(chatState);
  }

  public getChatState(): Observable<any> {
    return this.eventChatState.asObservable();
  }

  public setAddresInfo(addressInfo: addressInfo) {
    this.addressInfo = addressInfo;
  }

  public sendAddressInfo() {
    this.SendMessageThroughSocket(this.addressInfo.direccion);
  }

  public getIdCliente(): string {
    return this.idClienteG;
  }

  public setMaxSessionsState(maxSessionsState: boolean) {
    this.maxSessionsState.next(maxSessionsState);
  }

  public getMaxSessionsState(): Observable<any> {
    return this.maxSessionsState.asObservable();
  }
}


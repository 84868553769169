import { Component, Input, OnInit } from '@angular/core';
import { AudioControllerService } from 'src/app/services/audio-controller.service';
import { QueueProcessorService } from 'src/app/services/chat/queue-processor.service';
import { SpeechToTextService } from 'src/app/services/chat/speech-to-text.service';
import { UseCasesService } from 'src/app/services/chat/use-cases.service';
import { WebSocketService } from 'src/app/services/web-socket.service';

declare global {
  interface Window { SendUserInteraction: (event: string) => void; }
}

@Component({
  selector: 'app-mic-section',
  templateUrl: './mic-section.component.html',
  styleUrls: ['./mic-section.component.scss']
})
export class MicSectionComponent implements OnInit {

  toggleMicIcon = '<path d="M96 96V256C96 309 139 352 192 352C245 352 288 309 288 256H208C199.2 256 192 248.8 192 240C192 231.2 199.2 224 208 224H288V192H208C199.2 192 192 184.8 192 176C192 167.2 199.2 160 208 160H288V128H208C199.2 128 192 120.8 192 112C192 103.2 199.2 96 208 96H288C288 43 245 0 192 0C139 0 96 43 96 96ZM320 240V256C320 326.7 262.7 384 192 384C121.3 384 64 326.7 64 256V216C64 202.7 53.3 192 40 192C26.7 192 16 202.7 16 216V256C16 345.1 82.2 418.7 168 430.4V464H120C106.7 464 96 474.7 96 488C96 501.3 106.7 512 120 512H192H264C277.3 512 288 501.3 288 488C288 474.7 277.3 464 264 464H216V430.4C301.8 418.7 368 345.1 368 256V216C368 202.7 357.3 192 344 192C330.7 192 320 202.7 320 216V240Z" fill="#DEDEDE"/><rect x="372.508" y="133.656" width="33.3751" height="435.993" rx="15" transform="rotate(60 372.508 133.656)" fill="red"/>';
  toggleMicState = -1;
  showMicrophoneTooltip = false;
  soundMic = 'assets/audio/tono_mic_on.wav';

  micPathDisabled = '<path d="M96 96V256C96 309 139 352 192 352C245 352 288 309 288 256H208C199.2 256 192 248.8 192 240C192 231.2 199.2 224 208 224H288V192H208C199.2 192 192 184.8 192 176C192 167.2 199.2 160 208 160H288V128H208C199.2 128 192 120.8 192 112C192 103.2 199.2 96 208 96H288C288 43 245 0 192 0C139 0 96 43 96 96ZM320 240V256C320 326.7 262.7 384 192 384C121.3 384 64 326.7 64 256V216C64 202.7 53.3 192 40 192C26.7 192 16 202.7 16 216V256C16 345.1 82.2 418.7 168 430.4V464H120C106.7 464 96 474.7 96 488C96 501.3 106.7 512 120 512H192H264C277.3 512 288 501.3 288 488C288 474.7 277.3 464 264 464H216V430.4C301.8 418.7 368 345.1 368 256V216C368 202.7 357.3 192 344 192C330.7 192 320 202.7 320 216V240Z" fill="#DEDEDE"/><rect x="372.508" y="133.656" width="33.3751" height="435.993" rx="15" transform="rotate(60 372.508 133.656)" fill="red"/>';
  micPathMute = '<path d="M96 96V256C96 309 139 352 192 352C245 352 288 309 288 256H208C199.2 256 192 248.8 192 240C192 231.2 199.2 224 208 224H288V192H208C199.2 192 192 184.8 192 176C192 167.2 199.2 160 208 160H288V128H208C199.2 128 192 120.8 192 112C192 103.2 199.2 96 208 96H288C288 43 245 0 192 0C139 0 96 43 96 96ZM320 240V256C320 326.7 262.7 384 192 384C121.3 384 64 326.7 64 256V216C64 202.7 53.3 192 40 192C26.7 192 16 202.7 16 216V256C16 345.1 82.2 418.7 168 430.4V464H120C106.7 464 96 474.7 96 488C96 501.3 106.7 512 120 512H192H264C277.3 512 288 501.3 288 488C288 474.7 277.3 464 264 464H216V430.4C301.8 418.7 368 345.1 368 256V216C368 202.7 357.3 192 344 192C330.7 192 320 202.7 320 216V240Z"/><rect x="384" y="128" width="33.3751" height="435.993" rx="15" transform="rotate(60 372.508 133.648)" fill="#D91515"/>';
  micPathEnabled = '<path d="M96 96V256C96 309 139 352 192 352C245 352 288 309 288 256H208C199.2 256 192 248.8 192 240C192 231.2 199.2 224 208 224H288V192H208C199.2 192 192 184.8 192 176C192 167.2 199.2 160 208 160H288V128H208C199.2 128 192 120.8 192 112C192 103.2 199.2 96 208 96H288C288 43 245 0 192 0C139 0 96 43 96 96ZM320 240V256C320 326.7 262.7 384 192 384C121.3 384 64 326.7 64 256V216C64 202.7 53.3 192 40 192C26.7 192 16 202.7 16 216V256C16 345.1 82.2 418.7 168 430.4V464H120C106.7 464 96 474.7 96 488C96 501.3 106.7 512 120 512H192H264C277.3 512 288 501.3 288 488C288 474.7 277.3 464 264 464H216V430.4C301.8 418.7 368 345.1 368 256V216C368 202.7 357.3 192 344 192C330.7 192 320 202.7 320 216V240Z"/>';

  aspecRatio = window.innerWidth / window.innerHeight;

  @Input() noAvatar!: boolean;
  constructor(
    private speechToTextService: SpeechToTextService,
    private useCasesService: UseCasesService,
    private audioControllerService: AudioControllerService,
    private webSocketService: WebSocketService,
    private queueProcessorService: QueueProcessorService
  ) { }

  ngOnInit(): void {
    navigator.mediaDevices.getUserMedia({ audio: true, video: false });
    this.useCasesService.getMicState().subscribe((state: number) => {
      // State -1: Disabled microphone
      // State 0: Not recognizing
      // State 1: Recognizing
      // State 2: End conversation
      // State 3: Manual not recognizing
      switch (state) {
        case -1:
          this.toggleMicState = -1;
          this.toggleMicIcon = this.micPathDisabled;
          break;
        case 0:
          this.toggleMicState = 0;
          this.toggleMicIcon = this.micPathMute;
          break;
        case 1:
          this.toggleMicState = 1;
          break;
        case 2:
          this.toggleMicState = 2;
          this.toggleMicIcon = this.micPathDisabled;
          break;
        case 3:
          this.toggleMicState = 3;
          this.toggleMicIcon = this.micPathMute;
          break;
        default:
          this.toggleMicState = 0;
          this.toggleMicIcon = this.micPathMute;
          break;
      }

      this.toggleMic();
    });

    this.speechToTextService.getStartRecognizing().subscribe(() => {
      this.toggleMicIcon = this.micPathEnabled;
      this.toggleMicState = 1;
      this.audioControllerService.playAudio(this.soundMic);
      this.audioControllerService.pauseMusic();
    })

    this.useCasesService.getShowMicrophoneTooltip().subscribe((state: boolean) => {
      this.showMicrophoneTooltip = state;

      setTimeout(() => {
        this.showMicrophoneTooltip = false;
      }, 7000);
    });

    this.webSocketService.getChatState().subscribe((state: boolean) => {
      if (state) {
        this.toggleMicState = 0;
        this.toggleMicIcon = this.micPathMute;
      } else {
        this.toggleMicState = -1;
        this.toggleMicIcon = this.micPathDisabled;
      }
    });

    this.queueProcessorService.getLoadingMessage().subscribe(() => {
      this.toggleMicState = -1;
      this.toggleMicIcon = this.micPathDisabled;
    });

    this.queueProcessorService.getProcessingCompleteSubject().subscribe(() => {
      this.toggleMicState = 0;
      this.toggleMicIcon = this.micPathMute;
    });
  }

  userClickedMic() {
    window.SendUserInteraction("micEvent");
    this.toggleMicState = this.toggleMicState == 1 ? 0 : 10;
    if (this.toggleMicState == 0) this.toggleMicIcon = this.micPathMute;
    this.toggleMic();
  }

  toggleMic() {
    if (this.toggleMicState == -1) {
      this.speechToTextService.stopSTT();
      this.audioControllerService.resumeMusic();
    } else if (this.toggleMicState == 0) {
      this.speechToTextService.stopSTT();
      this.audioControllerService.playAudio('/assets/audio/tono_mic_off.wav');
      this.audioControllerService.resumeMusic();
    } else if (this.toggleMicState == 10) {
      this.speechToTextService.startSTT();
    } else if (this.toggleMicState == 2) {
      this.speechToTextService.stopSTT();
      this.audioControllerService.resumeMusic();
    } else if (this.toggleMicState == 3) {
      this.speechToTextService.stopSTT();
      this.audioControllerService.resumeMusic();
    }
  }

}

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.local';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { v4 as uuidv4 } from 'uuid';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslatorService {
  languageState: Subject<string> = new Subject<string>();

  private translateService = environment.translateService;
  language = 'es';
  originalButtonLanguage!: string;
  languageRegion = 'es-MX';
  voiceCode = '';
  originalLanguage!: string;
  private readonly header: HttpHeaders;
  gender!: string;
  voiceWomenLanguageDictionary: { [key: string]: string } = {
    'es': 'es-MX-DaliaNeural',
    'de': 'de-DE-ElkeNeural',
    'fr': 'fr-FR-JacquelineNeural',
    'en': 'en-US-AriaNeural',
    'pt': 'pt-PT-RaquelNeural',
    'zh-Hans': 'zh-CN-XiaochenNeural',
    'it': 'it-IT-IsabellaNeural'
  }
  voiceManLanguageDictionary: { [key: string]: string } = {
    'es': 'es-MX-CecilioNeural',
    'de': 'de-DE-ConradNeural',
    'fr': 'fr-FR-HenriNeural',
    'en': 'en-US-AndrewNeural',
    'pt': 'pt-BR-DonatoNeural',
    'zh-Hans': 'zh-CN-YunyeNeural',
    'it': 'it-IT-BenignoNeural'
  }

  constructor(private http: HttpClient, private ngTranslateService: TranslateService) {
    this.header = new HttpHeaders()
    .set('Ocp-Apim-Subscription-Key', this.translateService.apiKey)
    .set('Ocp-Apim-Subscription-Region', this.translateService.region)
      .set('X-ClientTraceId', uuidv4().toString());
  }

  public setTargetLenguage(code: string){
    if(!(code in this.voiceManLanguageDictionary)){
      console.error(`Language ${code} not supported`);
      code = 'en';
    }
    else if(code.includes("zh")) code = "zh-Hans";
    if(this.originalLanguage === undefined) this.originalLanguage = code;
    this.language = code;
    this.voiceCode = this.getVoice();
    this.languageRegion = `${this.language}-${this.voiceCode.split('-')[1]}`;
    this.languageState.next(this.language);
    this.ngTranslateService.use(this.language);
  }

  public translateText(text: string) : Observable<any> {
    const params = new HttpParams()
    .set('api-version', this.translateService.version)
    .set('to', this.language);

    const data = [{
      text: text
    }];

    return this.http.post(this.translateService.url, data, {headers: this.header, params: params});
  }

  public recognizeLanguage(text: string): Observable<any> {
    const params = new HttpParams()
    .set('api-version', this.translateService.version)

    const data = [{
      text: text
    }]

    return this.http.post(this.translateService.recognizeUrl, data, {headers: this.header, params: params});
  }

  public getLenguageState() : Observable<string>{
    return this.languageState.asObservable();
  }

  public getCurrentLanguage(): string {
    return this.language;
  }

  public getVoice(): string {
    if (this.gender === "1") {
      return this.voiceWomenLanguageDictionary[this.language];
    }
    else {
      return this.voiceManLanguageDictionary[this.language];
    }
  }

  public setVoiceSetting(voice: string, language: string, gndr: string) {
    this.voiceManLanguageDictionary[language] = voice;
    this.voiceWomenLanguageDictionary[language] = voice;
    this.voiceCode = voice;
    this.gender = gndr;
  }

  public setOriginalLanguage(){
    this.setTargetLenguage(this.originalLanguage);
  }

  public setOriginalButtonLanguage(lang: string){
    this.originalButtonLanguage = lang;
  }

  public getRegionCode(): string{
    return this.languageRegion;
  }

  public getVoiceCode(): string {
    return this.voiceCode;
  }

  public getOriginalLanguage(): string{
    return this.originalLanguage;
  }

  public checkStartLanguageDifference(): boolean{
    return this.originalLanguage !== this.originalButtonLanguage;
  }

  public getOriginalButtonLanguage(): string{
    return this.originalButtonLanguage;
  }
}

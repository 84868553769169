<div class="log-container">
  <h2>Log Messages</h2>
  <div class="log-list">
    <ul>
      <li *ngFor="let log of logs" [ngClass]="getLogClass(log)">
        {{ log }}
      </li>
    </ul>
  </div>
</div>

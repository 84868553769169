import { ConversationState } from "../enums/conversation.state";
import { WebSocketEvents } from "../enums/web-socket-events";

export const WatsonEventDict: { [key in ConversationState]: WebSocketEvents } = {
    [ConversationState.STARTING]: WebSocketEvents.WATSON_START_CONVERSATION,
    [ConversationState.TALKING]: WebSocketEvents.WATSON_CONVERSATION,
    [ConversationState.FINISHING]: WebSocketEvents.WATSON_FINISH_CONVERSATION
};

export const LlmEventDict: { [key in ConversationState]: WebSocketEvents } = {
    [ConversationState.STARTING]: WebSocketEvents.LLM_START_CONVERSATION,
    [ConversationState.TALKING]: WebSocketEvents.LLM_CONVERSATION,
    [ConversationState.FINISHING]: WebSocketEvents.LLM_FINISH_CONVERSATION
};

export const ExternalNlpDict: { [key in ConversationState]: WebSocketEvents } = {
    [ConversationState.STARTING]: WebSocketEvents.EXTERNAL_NLP_START_CONVERSATION,
    [ConversationState.TALKING]: WebSocketEvents.EXTERNAL_NLP_CONVERSATION,
    [ConversationState.FINISHING]: WebSocketEvents.EXTENRAL_NLP_FINISH_CONVERSATION
};

export const SpeakerEventDict: { [key in ConversationState]: WebSocketEvents } = {
    [ConversationState.STARTING]: WebSocketEvents.SPEAKER_START_CONVERSATION,
    [ConversationState.TALKING]: WebSocketEvents.SPEAKER_START_CONVERSATION,
    [ConversationState.FINISHING]: WebSocketEvents.SPEAKER_START_CONVERSATION
};
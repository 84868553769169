import { Component, AfterViewInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import * as THREE from 'three';
import { GLTFLoader, GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

// Post Procesing
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import { OutputPass } from 'three/examples/jsm/postprocessing/OutputPass';

import { AudioControllerService } from 'src/app/services/audio-controller.service';
import { getSize } from './buja-sizer';

@Component({
  selector: 'app-buja',
  templateUrl: './buja.component.html',
  styleUrls: ['./buja.component.scss']
})
export class BujaComponent implements AfterViewInit {

  @ViewChild('canvas') canvas!: ElementRef;

  // Stage properties
  public windowWidth: number = window.innerWidth;
  public windowHeight: number = window.innerHeight;
  public aspectRatio: number = this.windowWidth / this.windowHeight;

  public cameraZ = 500;
  public fieldOfView = 1;
  public nearClippingPane = 1;
  public farClippingPane= 1000;

  // Helper properties (Private Properties)
  private camera!: THREE.PerspectiveCamera;
  private renderer!: THREE.WebGLRenderer;
  private scene = new THREE.Scene();
  private gltfModel!: GLTF;
  private points: THREE.Vector2[] = [];
  private numPoints = 20;
  private clock = new THREE.Clock();
  private composer!: EffectComposer;
  private movementOffset = 0.005;

  // line properties
  private splineCurve!: THREE.SplineCurve;
  private splineCurveLine!: THREE.Line;
  private materialSplineCurve = new THREE.MeshStandardMaterial({
    color: 0x000000,
    metalness: 0,
    roughness: 0.5,
    emissive: 0x5D75FF,
    emissiveIntensity: 40,
    transparent: false,
    opacity: 1,
    side: THREE.DoubleSide,
    blending: THREE.AdditiveBlending
  });

  private anilloPosition = new THREE.Vector3();
  private anilloScale = new THREE.Vector3();
  private splineCurveChild!: THREE.Object3D;

  private createRandomPoints!: (isEnabled: boolean) => void;
  private generateRandomPoints = false;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.handleResize();
  }

  constructor(private audioControllerService: AudioControllerService) { }

  ngAfterViewInit(): void {
    this.createScene();
    this.startRendering();
    this.getAudioState();
  }

  private getAudioState() {
    this.audioControllerService.getAudioState().subscribe({
      next: (state: boolean) => {
        this.generateRandomPoints = state;
        this.createRandomPoints(this.generateRandomPoints);
      }, error: (error: any) => {
        console.log(error);
      }
    });
  }

  private createScene() {
    this.scene = new THREE.Scene();

    const loaderGLTF = new GLTFLoader();
    loaderGLTF.load('assets/threejs/models/buja/Buja.glb', (gltf: GLTF) => {
      this.gltfModel = gltf;
      const bujaSize = getSize(this.windowWidth, this.windowHeight);

      if(bujaSize){
        this.movementOffset = bujaSize.movementOffset;
        this.gltfModel.scene.scale.copy(bujaSize.scale);
        this.gltfModel.scene.position.copy(bujaSize.position);
      }

      this.gltfModel.scene.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          child.castShadow = true;
          child.receiveShadow = true;
        }

        if (child instanceof THREE.Mesh && child.name === 'Cristal') {
          const texture = new THREE.TextureLoader().load('assets/threejs/models/buja/BurbujaPNG.png');
          texture.wrapS = THREE.RepeatWrapping;
          texture.wrapT = THREE.RepeatWrapping;
          texture.repeat.set(1, 1);
          child.material = new THREE.MeshBasicMaterial({ map: texture });
          child.renderOrder = 2;
          child.material.transparent = true;
          child.material.side = THREE.DoubleSide;
          child.position.set(0, 3, 0);
          let rotation = 0;
          setInterval(() => {
            rotation += 0.009;
            child.rotation.y = rotation;
          }, 40);
        }

        if (child instanceof THREE.Mesh && child.name === 'BrilloFinal') {
          const texture = new THREE.TextureLoader().load('assets/threejs/models/buja/CristalIntermedio.png');
          texture.wrapS = THREE.RepeatWrapping;
          texture.wrapT = THREE.RepeatWrapping;
          texture.repeat.set(1, 1);
          child.material = new THREE.MeshBasicMaterial({ map: texture });
          child.renderOrder = 1;
          child.material.transparent = true;
          child.material.side = THREE.DoubleSide;
          child.material.blending = THREE.AdditiveBlending;
        }

        if (child instanceof THREE.Mesh && child.name === 'AnilloSpectroFinal') {
          child.rotateZ(THREE.MathUtils.degToRad(182.5));
          child.getWorldPosition(this.anilloPosition);
          child.getWorldScale(this.anilloScale);
          this.splineCurve = new THREE.SplineCurve([
            new THREE.Vector2(-0.59, 0),
            new THREE.Vector2(0.59, 0),
          ]);

          this.createRandomPoints = (isEnabled: boolean) => {
            this.points = [];
            this.points.push(new THREE.Vector2(-0.59, 0));
            for (let i = 1; i < this.numPoints - 1; i++) {
              const t = i / (this.numPoints - 1);
              const point = this.splineCurve.getPoint(t);

              if (isEnabled) {
                const randomOffset = this.getRandomArbitrary(-1, 1);
                point.y += randomOffset;
              }

              this.points.push(point);
            }
            this.points.push(new THREE.Vector2(0.59, 0));

            const geometry = new THREE.BufferGeometry().setFromPoints(this.points);
            if (this.splineCurveLine) {
              child.remove(this.splineCurveLine);
            }
            this.splineCurveLine = new THREE.Line(geometry, this.materialSplineCurve);
            child.add(this.splineCurveLine);
            this.splineCurveChild = child.children[0];
            if (this.windowWidth < 768) {
              this.splineCurveChild.position.y = this.anilloPosition.y * 0.4;
              this.splineCurveChild.scale.y = this.anilloScale.y;
            } else {
              this.splineCurveChild.position.y = this.anilloPosition.y + 0.8;
              this.splineCurveChild.scale.y = this.anilloScale.y + 0.8;
            }

            if (isEnabled) {
              const animatePoints = () => {
                const elapsedTime = this.clock.getElapsedTime();
                const speed = 5;

                for (let i = 1; i < this.numPoints - 1; i++) {
                  const t = i / (this.numPoints - 1);
                  const point = this.splineCurve.getPoint(t);

                  if (isEnabled) {
                    const randomOffset = Math.sin(elapsedTime * speed + i) * 0.2;
                    point.y += randomOffset;
                  }
                  this.points[i].set(point.x, point.y);
                }
                geometry.setFromPoints(this.points);
              };

              setInterval(() => {
                animatePoints();
              }, 1);
            }
          };

          this.generateRandomPoints = false;
          this.createRandomPoints(this.generateRandomPoints);
          const animationSpeed = 0.0007;
          const initialRotation = child.rotation.y;
          const rotationX = child.rotation.x;
          const initialRotationSpline = this.splineCurveLine.rotation.y;
          const rotationXSpline = this.splineCurveLine.rotation.x;

          setInterval(() => {
            child.rotation.y = initialRotation + Math.sin(Date.now() * animationSpeed) * 0.1;
            child.rotation.x = rotationX + Math.sin(Date.now() * animationSpeed) * 0.1;

            this.splineCurveLine.rotation.y = initialRotationSpline + Math.sin(Date.now() * animationSpeed) * 0.1;
            this.splineCurveLine.rotation.x = rotationXSpline + Math.sin(Date.now() * animationSpeed) * 0.1;
          }, 1);
        }
      });

      this.scene.add(this.gltfModel.scene);
      let originalPositionY = this.gltfModel.scene.position.y;
      const delta = 0.009;
      setInterval(() => {
        originalPositionY += delta;
        this.scene.children[0].position.y = this.gltfModel.scene.position.y + Math.sin(originalPositionY) * this.movementOffset;
      }, 15);
    });

    this.camera = new THREE.PerspectiveCamera(
      this.fieldOfView,
      this.aspectRatio,
      this.nearClippingPane,
      this.farClippingPane
    );

    this.camera.position.z = this.cameraZ;
  }

  private getRandomArbitrary(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }

  private handleResize() {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    this.aspectRatio = this.windowWidth / this.windowHeight;

    this.camera.aspect = this.aspectRatio;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(this.windowWidth, this.windowHeight);
    this.composer.setSize(this.windowWidth, this.windowHeight);

    if (this.gltfModel) {
      const bujaSize = getSize(this.windowWidth, this.windowHeight);
      if(!bujaSize) return;
      this.movementOffset = bujaSize.movementOffset;
      this.gltfModel.scene.scale.copy(bujaSize.scale);
      this.gltfModel.scene.position.copy(bujaSize.position);
      if (this.windowWidth < 768) {
        this.splineCurveChild.position.y = this.anilloPosition.y * 0.5;
        this.splineCurveChild.scale.y = this.anilloScale.y;
      } else {
        this.splineCurveChild.position.y = this.anilloPosition.y + 0.8;
        this.splineCurveChild.scale.y = this.anilloScale.y + 0.8;
      }
    }
  }

  private startRendering() {
    this.renderer = new THREE.WebGLRenderer({ canvas: this.canvas.nativeElement, context: this.canvas.nativeElement.getContext('webgl2'), alpha: true });
    this.renderer.setPixelRatio(devicePixelRatio);

    this.composer = new EffectComposer(this.renderer);

    const renderPass = new RenderPass(this.scene, this.camera);
    this.composer.addPass(renderPass);

    const bloomPass = new UnrealBloomPass(new THREE.Vector2(this.windowWidth, this.windowHeight), 0.3, 0.5, 2);
    bloomPass.threshold = 0.3;
    bloomPass.strength = 0.10;
    bloomPass.radius = 0;
    this.composer.addPass(bloomPass);

    const outputPass = new OutputPass();
    this.composer.addPass(outputPass);

    this.handleResize();

    const render = () => {
      requestAnimationFrame(render);
      this.composer.render();
    };
    render();
  }
}

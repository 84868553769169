import { Injectable } from '@angular/core';
import { AvatarLoaderService } from '../../avatar/avatar-loader.service';
import { ThreeAvatar } from 'src/app/models/three-avatar.model';
import { Subscription } from 'rxjs';
import * as THREE from 'three';
import { ThreeClockService } from '../../utils/three-clock.service';

@Injectable({
  providedIn: 'root'
})

export class EyeBlinkService {
  subscriptions$: Subscription[] = [];
  
  private body!: THREE.SkinnedMesh;
  private waitTimeToBlink = 0;
  private startTime = 0;
  private rightBlinkBlendShapeIndex!: number;
  private leftBlinkBlendShapeIndex!: number;
  private isWaitingToBlink = false;
  private isBlinking = false;
  private blinkClosing = false;

  private minTimeToBlink = 3;
  private maxTimeToBlink = 5;
  private blinkSpeed = 0.1;

  private startTimeToBlink = 0;
  private avatarHasBlendshapes = false;

  constructor(private avatarLoaderService: AvatarLoaderService, private clockService: ThreeClockService) {
    this.initServices();
  }

  private initServices() {
    this.subscriptions$.push(
      this.avatarLoaderService.getAvatarInfo().subscribe((avatarInfo: ThreeAvatar) => {
        if(!avatarInfo.skinnedMesh) return;
        this.avatarHasBlendshapes = true;
        this.start(avatarInfo.skinnedMesh)
      })
    );
  }

  private start(baseBody: THREE.SkinnedMesh){
    this.body = baseBody;
    if(!this.body.morphTargetDictionary) return;
    this.rightBlinkBlendShapeIndex = this.body.morphTargetDictionary["Eye_Blink_R"];
    this.leftBlinkBlendShapeIndex = this.body.morphTargetDictionary["Eye_Blink_L"];
    this.startTimeToBlink = this.clockService.clock.getElapsedTime();
    this.isWaitingToBlink = true;
  }

  public update(){
    if(!this.avatarHasBlendshapes) return;
    this.waitToBlink();
    this.blink();
  }

  private waitToBlink(){
    if(!this.isWaitingToBlink) return;
    if(this.clockService.clock.getElapsedTime() > this.startTimeToBlink + this.waitTimeToBlink){
      this.isBlinking = true;
      this.isWaitingToBlink = false;
      this.startTime = this.clockService.clock.getElapsedTime();
    }
  }

  private blink(){
    if(!this.isBlinking || !this.body.morphTargetInfluences) return;
    const elapsedTime = this.clockService.clock.getElapsedTime();
    if(this.blinkClosing){
      this.body.morphTargetInfluences[this.rightBlinkBlendShapeIndex] = 1 - ((this.startTime + this.blinkSpeed - elapsedTime) / this.blinkSpeed);
      this.body.morphTargetInfluences[this.leftBlinkBlendShapeIndex] = 1 - ((this.startTime + this.blinkSpeed - elapsedTime) / this.blinkSpeed);
      if(this.body.morphTargetInfluences[this.leftBlinkBlendShapeIndex] >= 1){
        this.blinkClosing = false;
        this.body.morphTargetInfluences[this.rightBlinkBlendShapeIndex] = 1;
        this.body.morphTargetInfluences[this.leftBlinkBlendShapeIndex] = 1;
        this.startTime = elapsedTime;
      }
    }
    else{
      this.body.morphTargetInfluences[this.rightBlinkBlendShapeIndex] = (this.startTime + this.blinkSpeed - elapsedTime) / this.blinkSpeed;
      this.body.morphTargetInfluences[this.leftBlinkBlendShapeIndex] = (this.startTime + this.blinkSpeed - elapsedTime) / this.blinkSpeed;
      if(this.body.morphTargetInfluences[this.leftBlinkBlendShapeIndex] <= 0){
        this.blinkClosing = true;
        this.isBlinking = false;
        this.body.morphTargetInfluences[this.rightBlinkBlendShapeIndex] = 0;
        this.body.morphTargetInfluences[this.leftBlinkBlendShapeIndex] = 0;
        this.prepareNextBlink();
      }
    }
  }

  private prepareNextBlink(){
    this.waitTimeToBlink = THREE.MathUtils.randFloat(this.minTimeToBlink, this.maxTimeToBlink);
    this.startTimeToBlink = this.clockService.clock.getElapsedTime();
    this.isWaitingToBlink = true;
  }
}

import { Injectable } from '@angular/core';
import { TranslatorService } from '../translator.service';
import { UnityControllerService } from '../unity-controller.service';
import { getWelcomeMessage } from './epm-welcome-messages';

@Injectable({
  providedIn: 'root'
})
export class AvatarInteractionsService {

  constructor(private translatorService: TranslatorService, private unityControllerService: UnityControllerService) { }

  public makeInitialGreeting(avatarName: string) {
    const initialMessage = `Hola, mi nombre es ${avatarName} y te ayudaré en lo que necesites`;
    if(avatarName === 'Ema'){
      const speechData = {
        speech: initialMessage,
        expressions: "",
        movements: "greeting"
      }
      this.unityControllerService.sendSpeechDataToUnity(speechData);
      return;
    }
    this.translatorService.translateText(initialMessage).subscribe({
      next: (response: any) => {
        if (response && response.length > 0) {
          const speechData = {
            speech: response[0].translations[0].text,
            expressions: "",
            movements: "greeting"
          }
          this.unityControllerService.sendSpeechDataToUnity(speechData);
        }
      }
    })
  }

  public sendEPMWelcomeMessage() {
    const speechData = {
      speech: getWelcomeMessage(),
      expressions: "",
      movements: "talk"
    }
    this.unityControllerService.sendSpeechDataToUnity(speechData);
  }
}

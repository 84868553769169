<!-- Show logs -->
<!-- <app-log-display></app-log-display> -->

<!-- Contenedor de la aplicación completa -->
<div id="container-ui" #containerDiv>
  <!-- Modelos que se muestran -->
  <canvas id="canvas" #canvas></canvas>
  <app-loading-avatar [noAvatar]="noAvatar" [center]="centerAvatar" *ngIf="showLoading"></app-loading-avatar>
  <ng-container id="engineComponent" #engineComponent></ng-container>
  <!-- Modelos que se muestran -->
</div>
<!-- Contenedor de la aplicación completa -->

<!-- Alerta conexión lenta -->
<div class="alert-container-tooltip" *ngIf="lowDownloadSpeed">
  <span>{{'ui.initialApp.slowDownload' | translate}}</span>
</div>
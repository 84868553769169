import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { ThreeAvatar } from 'src/app/models/three-avatar.model';
import * as THREE from 'three';
import { TGALoader } from 'three/examples/jsm/loaders/TGALoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { BundleDownloader } from 'src/app/services/bundle-downloader.service';
import { UnityControllerService } from 'src/app/services/unity-controller.service';
import { AvatarMaterials } from './avatar-materials.model';
import { avatarMaterialDictionary } from './avatar_material_dict';

@Injectable({
  providedIn: 'root'
})
export class AvatarLoaderService {
  suscriptions$: Subscription[] = [];
  private avatarInfo = new Subject<ThreeAvatar>();
  private avatarRotation: THREE.Euler = new THREE.Euler(0, 0, 0);
  private avatarPosition = new THREE.Vector3(0, 0, 0);
  private hairAlreadyProcessed = false;
  private materialPath = "../../../../../assets/threejs/models/materials/";
  private avatarName!: string;
  private avatarMaterial!: AvatarMaterials | null;

  private threeAvatar: ThreeAvatar = {
    object: new THREE.Object3D(),
    skinnedMesh: new THREE.SkinnedMesh(),
    lipsyncInfo: {
      tongueSkinnedMesh: new THREE.SkinnedMesh(),
      jawBone: new THREE.Object3D(),
      jawBoneRotation: 0,
    },
    gazeInfo: {
      baseHead: new THREE.Object3D(),
      rightEye: new THREE.Object3D(),
      leftEye: new THREE.Object3D()
    },
    animations: []
  };

  private model!: THREE.Object3D;
  private skinnedMesh!: THREE.SkinnedMesh;
  private tongueSkinnedMesh!: THREE.SkinnedMesh;
  private jawBone!: THREE.Object3D;
  private jawBoneRotation!: number;
  private baseHead!: THREE.Object3D;
  private rightEye!: THREE.Object3D;
  private leftEye!: THREE.Object3D;

  private jawFound = false;

  private excludedObjects: string[] = ["eyeocclusion", "tearline", "base_body"];

  constructor(private bundleDownloaderService: BundleDownloader, private unityControllerService: UnityControllerService) {
    this.initServices();
  }

  private initServices() {
    this.suscriptions$.push(
      this.bundleDownloaderService.getStatusDecompress().subscribe((decompressed: boolean) => {
        if (decompressed && this.unityControllerService.UnityInstance === undefined) {
          this.loadAvatar(this.bundleDownloaderService.getBlob());
        }
      }),
      this.bundleDownloaderService.getAvatarname().subscribe((name: string) => {
        this.avatarName = name;
      })
    );
  }

  public async loadAvatar(blob: Blob) {
    const arrayBuffer = await blob.arrayBuffer();
    const manager = new THREE.LoadingManager();
    manager.addHandler(/\.tga$/i, new TGALoader());
    const gltfLoader = new GLTFLoader(manager);
    gltfLoader.parse(arrayBuffer, '', async (object) => {
      object.scene.scale.set(1, 1, 1);
      this.model = await this.traverseAvatar(object.scene);
      this.model.setRotationFromEuler(this.avatarRotation);
      this.model.position.set(this.avatarPosition.x, this.avatarPosition.y, this.avatarPosition.z);
      this.threeAvatar = this.buildAvatarInfo(object.animations);
      this.unityControllerService.setAvatarState();
      this.avatarInfo.next(this.threeAvatar);
    }, (error) => {
      console.log(error);
    });
  }

  private async traverseAvatar(fbx: THREE.Object3D) {
    const promises: Promise<void>[] = [];
    this.avatarMaterial = await this.readMaterial()
    fbx.traverse(async (child) => {
      promises.push(
        new Promise((resolve) => {
          if (child.name.toLowerCase().includes("hair") || child.name.toLowerCase().includes("skysims")) {
            this.setHairMaterial(child as THREE.Mesh);
          }
          else if ((child as THREE.Mesh).isMesh && !this.excludedObjects.includes(child.name.toLowerCase())) {
            this.setStandardMaterial(child as THREE.Mesh);
            if (child.name.toLowerCase().includes("body") && child.name.toLowerCase().includes("_1")) {
              this.skinnedMesh = child as THREE.SkinnedMesh;
            }
            else if (child.name.toLowerCase().includes("tongue")) {
              this.tongueSkinnedMesh = child as THREE.SkinnedMesh;
            }
          }
          else {
            this.checkBones(child as THREE.Object3D);
          }
          resolve();
        })
      );
    });
    await Promise.all(promises);
    return fbx;
  }

  private async readMaterial(): Promise<AvatarMaterials | null> {
    const response = await fetch(avatarMaterialDictionary[this.avatarName]);
    if(!response.ok) return null;
    const data: AvatarMaterials = await response.json();
    return data;
  }

  private setHairMaterial(mesh: THREE.Mesh) {
    if(!this.avatarMaterial) return;
    if (this.hairAlreadyProcessed) {
      mesh.visible = false;
    }
    mesh.material = mesh.material as THREE.MeshPhysicalMaterial;
    mesh.material.side = this.avatarMaterial.hair.side as THREE.Side;
    mesh.material.depthTest = this.avatarMaterial.hair.depthTest;
    mesh.material.transparent = this.avatarMaterial.hair.transparent;
    mesh.material.opacity = this.avatarMaterial.hair.opacity;
    (mesh.material as THREE.MeshPhysicalMaterial).reflectivity = this.avatarMaterial.hair.reflectivity;
    mesh.renderOrder = this.avatarMaterial.hair.renderOrder;
    (mesh.material as THREE.MeshPhysicalMaterial).roughness = this.avatarMaterial.hair.roughness;
    mesh.material.depthWrite = this.avatarMaterial.hair.depthWrite;
    mesh.material.polygonOffset = this.avatarMaterial.hair.polygonOffset;
    mesh.material.polygonOffsetFactor = this.avatarMaterial.hair.polygonOffsetFactor;
    mesh.material.polygonOffsetUnits = this.avatarMaterial.hair.polygonOffsetUnits;
    this.hairAlreadyProcessed = true;
  }

  private setStandardMaterial(mesh: THREE.Mesh) {
    if(!this.avatarMaterial){
      (mesh.material as THREE.MeshPhysicalMaterial).roughness = 1;
      return;
    }
    if (this.excludedObjects.some(excluded => mesh.name.toLowerCase().includes(excluded))) {
      if (!mesh.name.toLowerCase().includes("body") || mesh.name.toLowerCase().includes("_6")) {
        return;
      }
    }
    if ((mesh.material as THREE.MeshPhysicalMaterial).name.toLowerCase().includes('head')) {
      (mesh.material as THREE.MeshPhysicalMaterial).roughness = this.avatarMaterial.head.roughness;
      (mesh.material as THREE.MeshPhysicalMaterial).depthWrite = this.avatarMaterial.head.depthWrite;
      return;
    }
    else if ((mesh.material as THREE.MeshPhysicalMaterial).name.toLowerCase().includes('arm')) {
      (mesh.material as THREE.MeshPhysicalMaterial).roughness = this.avatarMaterial.arm.roughness;
      return;
    }
    else if ((mesh.material as THREE.MeshPhysicalMaterial).name.toLowerCase().includes('cornea')) {
      (mesh.material as THREE.MeshPhysicalMaterial).roughness = this.avatarMaterial.cornea.roughness;
      (mesh.material as THREE.MeshPhysicalMaterial).normalScale = new THREE.Vector2(this.avatarMaterial.cornea.normalScale.x, this.avatarMaterial.cornea.normalScale.y);
      mesh.renderOrder = this.avatarMaterial.cornea.renderOrder;
      return;
    }
    else if (mesh.name.toLowerCase().includes('ff') || mesh.name.toLowerCase().includes('shirt')) {
      (mesh.material as THREE.MeshPhysicalMaterial).normalScale = new THREE.Vector2(this.avatarMaterial.shirt.normalScale.x, this.avatarMaterial.shirt.normalScale.y);
      (mesh.material as THREE.MeshPhysicalMaterial).depthWrite = this.avatarMaterial.shirt.depthWrite;
      (mesh.material as THREE.MeshPhysicalMaterial).roughness = this.avatarMaterial.shirt.roughness;
      if (this.avatarMaterial.shirt.brilliant) {
        (mesh.material as THREE.MeshPhysicalMaterial).color.setRGB(this.avatarMaterial.shirt.brilliantColor.r, this.avatarMaterial.shirt.brilliantColor.g, this.avatarMaterial.shirt.brilliantColor.b);
      }
      return;
    }
    (mesh.material as THREE.MeshPhysicalMaterial).roughness = this.avatarMaterial.default.roughness;
  }

  public getAvatarInfo(): Observable<ThreeAvatar> {
    return this.avatarInfo.asObservable();
  }

  private buildAvatarInfo(animations: THREE.AnimationClip[]): ThreeAvatar {
    return {
      object: this.model,
      skinnedMesh: this.skinnedMesh,
      lipsyncInfo: {
        tongueSkinnedMesh: this.tongueSkinnedMesh,
        jawBone: this.jawBone,
        jawBoneRotation: this.jawBoneRotation,
      },
      gazeInfo: {
        baseHead: this.baseHead,
        rightEye: this.rightEye,
        leftEye: this.leftEye
      },
      animations: animations
    }
  }

  private checkBones(child: THREE.Object3D) {
    if (child.name.toLowerCase().includes("jawroot")) {
      if (!this.jawFound) {
        this.jawBone = child;
        this.jawBoneRotation = this.jawBone.rotation.z;
        this.jawFound = true;
      }
    }
    else if (child.name.toLowerCase().includes("base_head")) {
      this.baseHead = child;
    }
    else if (child.name.toLowerCase().includes("l_eye")) {
      this.leftEye = child;
    }
    else if (child.name.toLowerCase().includes("r_eye")) {
      this.rightEye = child;
    }
  }
}

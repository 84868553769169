import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { EngineComponent } from './components/engine/engine.component';
import { ChatSectionComponent } from './components/chat-section/chat-section.component';
import { ControlsSectionComponent } from './components/controls-section/controls-section.component';
import { MicSectionComponent } from './components/mic-section/mic-section.component';
import { SwitchlangComponent } from './components/shared/switchlang/switchlang.component';
import { ModalAddressComponent } from './components/shared/modal-address/modal-address.component';
import { LogDisplayComponent } from './components/tools/log-display/log-display.component';
import { ServiceUnavailableComponent } from './components/service-unavailable/service-unavailable.component';

import { ToastrModule } from 'ngx-toastr';

// NG Bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselCardsComponent } from './components/shared/carousel-cards/carousel-cards.component';

// NGX Bootstrap
import { ModalModule } from 'ngx-bootstrap/modal';

// PrimeNG
import { DropdownModule } from 'primeng/dropdown';

//pdf Viewer
import { PdfViewerModule } from 'ng2-pdf-viewer';

// NGX TRANSLATE
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { HttpClient } from '@angular/common/http';

import { SafeHtmlDirective } from './directives/safe-html.directive';
import { SafeYoutubeEmbedDirective } from './directives/safe-youtube-embed.directive';

import { ServiceWorkerModule } from '@angular/service-worker';
import { KeyboardComponent } from './components/shared/keyboard/keyboard.component';
import { BujaComponent } from './components/threejs/models/buja/buja.component';
import { MaxSessionsComponent } from './components/max-sessions/max-sessions.component';
import { NumerickeyboardComponent } from './components/shared/numerickeyboard/numerickeyboard.component';
import { ThreejsAvatarsComponent } from './components/threejs/threejs-avatars/threejs-avatars.component';
import { LoadingAvatarComponent } from './components/shared/loading-avatar/loading-avatar.component';
import { PdfViewerModalComponent } from './components/pdf-viewer-modal/pdf-viewer-modal.component';
import { VideoEngineComponent } from './components/engine/strategies/video-engine/video-engine.component';
import { LoadingChatComponent } from './components/shared/loading-chat/loading-chat.component';
import { InitialViewComponent } from './components/initial-view/initial-view.component';
import { MainComponent } from './components/main/main.component';
import { InitialViewBujaComponent } from './components/initial-view-buja/initial-view-buja.component';
import { UiViewComponent } from './components/ui-view/ui-view.component';
import { FlowKeyModalComponent } from './components/flow-key-modal/flow-key-modal.component';
import { ShiftTableComponent } from './components/chat-section/shift-table/shift-table.component';
import { YouTubePlayer, YouTubePlayerModule } from '@angular/youtube-player';
import { WarningModalComponent } from './components/warning-modal/warning-modal.component';
import { VerticalUiBackgroundComponent } from './components/vertical-ui-background/vertical-ui-background.component';
import { FileAttachmentComponent } from './components/shared/file-attachment/file-attachment.component';

@NgModule({
  declarations: [
    AppComponent,
    EngineComponent,
    ChatSectionComponent,
    ControlsSectionComponent,
    MicSectionComponent,
    ModalAddressComponent,
    CarouselCardsComponent,
    SwitchlangComponent,
    SafeHtmlDirective,
    SafeYoutubeEmbedDirective,
    LogDisplayComponent,
    ServiceUnavailableComponent,
    KeyboardComponent,
    BujaComponent,
    MaxSessionsComponent,
    NumerickeyboardComponent,
    ThreejsAvatarsComponent,
    LoadingAvatarComponent,
    PdfViewerModalComponent,
    VideoEngineComponent,
    LoadingChatComponent,
    InitialViewComponent,
    MainComponent,
    InitialViewBujaComponent,
    UiViewComponent,
    FlowKeyModalComponent,
    ShiftTableComponent,
    WarningModalComponent,
    VerticalUiBackgroundComponent,
    FileAttachmentComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    PdfViewerModule,
    YouTubePlayerModule,

    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added

    NgbModule,
    NgbDatepickerModule,

    // PrimeNG
    DropdownModule,

    //NGX TRANSLATE
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),

    // NGX Bootstrap
    ModalModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AudioControllerService {

  private audio: HTMLAudioElement;
  private voice: HTMLAudioElement;
  private music: HTMLAudioElement;
  private volume = 50;
  private audioState = new Subject<boolean>();
  private isIOs = false;
  private isLocked = true;

  constructor() {
    this.audio = new Audio();
    this.voice = new Audio();
    this.music = new Audio();

    this.setVolume();
  }

  private setVolume() {
    const volume = localStorage.getItem('volume');
    const volumeParse = volume ? parseInt(volume) : 50;
    let volumeOp = 0.001 * volumeParse + Math.pow(10, volumeParse / 100 - 1) - 0.1;

    if (volumeOp < 0) {
      volumeOp = 0;
    } else if (volumeOp > 1) {
      volumeOp = 1;
    }

    this.volume = volumeOp;
    this.audio.volume = this.volume;
    this.voice.volume = this.volume;
    this.music.volume = this.volume;
  }

  public playAudio(url: string) {
    if (this.isLocked && this.isIOs) {
      this.voice.src = '';
      this.isLocked = false;
    }
    this.audio.src = url;
    this.audio.volume = this.volume;
    const canplaythroughHandler = () => {
      this.audio.play();
      this.audio.removeEventListener('canplaythrough', canplaythroughHandler);
    }
    this.audio.addEventListener('canplaythrough', canplaythroughHandler);

    this.audio.addEventListener('error', (e) => {
      console.log('Error al cargar el archivo de audio:', e);
    });

    this.audio.load();
  }

  public playMusic(url: string) {
    this.music.src = url;
    this.music.volume = this.volume;

    this.music.addEventListener('canplaythrough', () => {
      this.music.play();

      // Disminuir el volumen progresivamente durante los primeros tres minutos
      const decreaseInterval = setInterval(() => {
        if (this.music.currentTime <= 180) {
          const decreaseAmount = Math.floor(this.volume / (180 * 10));
          this.music.volume -= decreaseAmount;

          if (this.music.volume < 0) {
            this.music.volume = 0;
          }
        } else {
          this.music.volume = 0;
          clearInterval(decreaseInterval);
        }
      }, 100);
    });

    this.music.addEventListener('error', (e) => {
      console.log('Error al cargar el archivo de música:', e);
    });

    this.music.addEventListener('ended', () => {
      this.music.currentTime = 0;
      this.music.play();
    });

    this.music.load();
  }

  public pauseMusic() {
    this.music.pause();
  }

  public resumeMusic() {
    this.music.play();
  }

  public playAudioFromBlob(blob: Blob) {
    const blobUrl = URL.createObjectURL(blob);
    this.voice.src = blobUrl;
    this.voice.volume = this.volume;
    const canplaythroughHandler = () => {
      this.setAudioState(true);
      this.voice.play();
      this.voice.removeEventListener('canplaythrough', canplaythroughHandler);
    }
    
    const timeUpdateHandler = () => {
      const bufferTime = 0.3; // Tiempo antes de finalizar para pausar el audio
      if (this.voice.currentTime >= this.voice.duration - bufferTime) {
        this.skipAudioFromBlob();
        this.voice.removeEventListener('timeupdate', timeUpdateHandler); // Desuscribirse para evitar múltiples disparos
        window.receiveSpeechFinished();
      }
    };

  this.voice.addEventListener('timeupdate', timeUpdateHandler);

    this.voice.addEventListener('canplaythrough', canplaythroughHandler);
    this.voice.load();
    if (this.isIOs) {
      this.voice.play();
    }
  }

  public skipAudioFromBlob() {
    this.setAudioState(false);
    this.voice.pause();
    this.voice.src = '';
  }

  public updateVolume(volume: number) {
    let volumeOp = 0.001 * volume + Math.pow(10, volume / 100 - 1) - 0.1;

    if (volumeOp < 0) {
      volumeOp = 0;
    } else if (volumeOp > 1) {
      volumeOp = 1;
    }

    this.volume = volumeOp;
    this.audio.volume = volumeOp;
    this.voice.volume = volumeOp;
    this.music.volume = volumeOp;
  }

  public getAudioState(): Observable<boolean> {
    return this.audioState.asObservable();
  }

  public setAudioState(state: boolean) {
    this.audioState.next(state);
  }

  public setIOs() {
    this.isIOs = true;
  }
}

interface AvatarAnimTimescale {
    default: number;
    idle: number;
    talk: number;
    greeting: number;
    point: number;
}

const defaultTimescale = {
    default: 0.2,
    idle: 0.2,
    talk: 0.5,
    greeting: 0.7,
    point: 0.2
}

export const avatarAnimTimescale: {[key: string]: AvatarAnimTimescale} = {
    "albert": defaultTimescale,
    "nati_brinsa": defaultTimescale,
    "nati_konekta": defaultTimescale,
    "nati_threejs": defaultTimescale,
    "neida_cenit": defaultTimescale,
    "neida_chec": defaultTimescale,
    "neida_threejs": defaultTimescale,
    "neida2_threejs": defaultTimescale,
    "paula": defaultTimescale,
    "felipe": defaultTimescale,
    "ema_threejs": defaultTimescale,
    "neida_ocensa": defaultTimescale,
    "robot": {
        default: 0.7,
        idle: 0.7,
        talk: 1,
        greeting: 1,
        point: 1
    }
}
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ColorPickerService {

  constructor() { }

  private hexToRGB(hex: string): number[] {
    hex = hex.replace(/^#/, '');

    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return [r, g, b];
  }

  private getLuminosity(r: number, g: number, b: number): number {
    const rsRGB = r / 255;
    const gsRGB = g / 255;
    const bsRGB = b / 255;

    const rLinear = rsRGB <= 0.03928 ? rsRGB / 12.92 : Math.pow((rsRGB + 0.055) / 1.055, 2.4);
    const gLinear = gsRGB <= 0.03928 ? gsRGB / 12.92 : Math.pow((gsRGB + 0.055) / 1.055, 2.4);
    const bLinear = bsRGB <= 0.03928 ? bsRGB / 12.92 : Math.pow((bsRGB + 0.055) / 1.055, 2.4);

    return 0.2126 * rLinear + 0.7152 * gLinear + 0.0722 * bLinear;
  }

  /**
  * Decides between black and white depending on the color passed
  * @param hex - input hex color
  * @param defaultColor - color to apply by default
  * @returns black or white hex color if hex is defined, else default color is returned
  */
  public getBlackWhiteColorByContrast(hex: string | undefined, defaultColor: string): string {
    if (!hex) {
      return defaultColor;
    }
    if (hex.length !== 7) {
      return defaultColor;
    }
    const [r, g, b] = this.hexToRGB(hex);
    const colorLuminosity = this.getLuminosity(r, g, b);
    if (colorLuminosity > 0.5) {
      return '#000000';
    }
    return '#ffffff';
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-loading-chat',
  templateUrl: './loading-chat.component.html',
  styleUrls: ['./loading-chat.component.scss']
})
export class LoadingChatComponent {

}

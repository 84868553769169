import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { UnityControllerService } from 'src/app/services/unity-controller.service';
import * as THREE from 'three';
import { characterIndexToAzureIndex, blendShapeDictionary } from '../../dictionaries/threejs-avatars.component.azure-dict';
import { AvatarLoaderService } from '../avatar/avatar-loader.service';
import { ThreeAvatar } from 'src/app/models/three-avatar.model';

@Injectable({
  providedIn: 'root'
})
export class AzureLipsyncService {
  subscriptions$: Subscription[] = [];

  private lipsyncState = new Subject<boolean>();
  private movementsState = new Subject<any>();

  private skinnedMesh!: THREE.SkinnedMesh;
  private tongueSkinnedMesh!: THREE.SkinnedMesh;
  private jawBone!: THREE.Object3D;
  private jawBoneRotation!: number;
  private nameKeys!: string[];
  private indexKeys!: number[];
  private azureValueKeys!: number[];
  private lipSyncEnded = true;
  private allBlendData!: number[][];
  private endTime!: number;
  private frameTime!: number;
  private tongueIndex!: number;
  private clock = new THREE.Clock(false);
  private avatarHasLipsync = false;

  constructor(private unityControllerService: UnityControllerService, private avatarLoaderService: AvatarLoaderService) {
    this.initServices();
  }

  initServices() {
    this.subscriptions$.push(
      this.unityControllerService.getSpeechBlendData().subscribe((data: any) => {
        if(this.avatarHasLipsync) this.startSpeech(data);
      }),
      this.unityControllerService.getSpeechCanceled().subscribe(() => {
        if(this.avatarHasLipsync) this.endLipsync();
      }),
      this.avatarLoaderService.getAvatarInfo().subscribe((avatarInfo: ThreeAvatar) => {
        if(!avatarInfo.lipsyncInfo?.jawBone) return;
        this.avatarHasLipsync = true;
        this.setAvatarInfo(avatarInfo);
        this.initLipsyncConfig();
      }));
  }
  private setAvatarInfo(avatarInfo: ThreeAvatar) {
    if(!avatarInfo.skinnedMesh || !avatarInfo.lipsyncInfo) return;
    this.skinnedMesh = avatarInfo.skinnedMesh;
    this.tongueSkinnedMesh = avatarInfo.lipsyncInfo.tongueSkinnedMesh;
    this.jawBone = avatarInfo.lipsyncInfo.jawBone;
    this.jawBoneRotation = avatarInfo.lipsyncInfo.jawBoneRotation;
  }
  private initLipsyncConfig() {
    const charToAzureBS = characterIndexToAzureIndex(this.skinnedMesh);
    this.nameKeys = Object.keys(blendShapeDictionary);
    this.indexKeys = Array.from(charToAzureBS.keys());
    this.azureValueKeys = Object.values(blendShapeDictionary);
  }
  private startSpeech(data: any) {
    if (data.speech === '' || !this.skinnedMesh){
      this.movementsState.next(data.movements);
      return;
    }
    this.allBlendData = this.collectBlendData(data.blendData);

    const frameAmount = this.allBlendData.length;
    const shortenPercentage = 0.0035;
    const duration = (data.audioDuration / 10000000) * (1 - shortenPercentage);
    this.frameTime = duration / frameAmount;

    const finishOffset = 0.7;
    this.endTime = duration - finishOffset;
    if (this.tongueSkinnedMesh.morphTargetDictionary) this.tongueIndex = this.tongueSkinnedMesh.morphTargetDictionary["Tongue_Out"];
    this.movementsState.next(data.movements);
    this.lipSyncEnded = false;
    this.lipsyncState.next(true);
    this.clock.start();
  }

  private collectBlendData(blendData: any) {
    let allBlendData: number[][] = [];
    blendData.forEach((bd: any) => {
      allBlendData = allBlendData.concat(bd.BlendShapes);
    });

    return allBlendData;
  }

  public playSpeech() {
    if(!this.avatarHasLipsync) return;
    if (!this.lipSyncEnded) {
      if (this.clock.elapsedTime < this.endTime) {
        this.updateBlendShapes(this.allBlendData, this.frameTime);
      }
      else {
        this.endLipsync();
      }
    }
  }

  private updateBlendShapes(blendData: number[][], frameTime: number) {
    const run = this.clock.getElapsedTime();

    const frameIndex = Math.floor(run / frameTime);
    if (frameIndex < blendData.length) {
      const blendShape = blendData[frameIndex];
      this.updateLipsync(blendShape);
      if (this.tongueSkinnedMesh.morphTargetInfluences) this.tongueSkinnedMesh.morphTargetInfluences[this.tongueIndex] = blendShape[51];
      this.updateJawRotation(blendShape, 0);
    }
  }

  private updateLipsync(blendShape: number[]) {
    for (let index = 0; index < this.indexKeys.length; index++) {
      const value = blendShape[this.azureValueKeys[index]];
      const key = this.nameKeys[index];
      const characterIndex = this.indexKeys[index];
      if (!this.skinnedMesh.morphTargetInfluences) return;
      if (key === "Mouth_Close") {
        this.skinnedMesh.morphTargetInfluences[characterIndex] = value * 0.3;
      }
      else if (key === "Jaw_Open") {
        this.skinnedMesh.morphTargetInfluences[characterIndex] = value * 0.3;
      }
      else if (key.includes("Mouth_Down_Lower")) {
        this.skinnedMesh.morphTargetInfluences[characterIndex] = value * 0.1;
      }
      else {
        this.skinnedMesh.morphTargetInfluences[characterIndex] = value * 0.9;
      }
    }
  }

  private updateJawRotation(blendshape: number[], zJawRotation: number) {
    if (this.jawBone) {
      zJawRotation = blendshape[blendShapeDictionary["Jaw_Open"]] * 0.2;
      zJawRotation -= zJawRotation * 0.1;
      this.jawBone.setRotationFromEuler(new THREE.Euler(this.jawBone.rotation.x, this.jawBone.rotation.y, zJawRotation + this.jawBoneRotation));
    }
  }

  private endLipsync() {
    this.jawBone.setRotationFromEuler(new THREE.Euler(this.jawBone.rotation.x, this.jawBone.rotation.y, this.jawBoneRotation));
    this.clock.stop();
    this.lipSyncEnded = true;
    this.lipsyncState.next(false);
  }

  public getLipsyncState(): Observable<boolean> {
    return this.lipsyncState.asObservable();
  }

  public getMovementState(): Observable<any> {
    return this.movementsState.asObservable();
  }
}

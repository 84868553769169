import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { UnityControllerService } from 'src/app/services/unity-controller.service';
import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';

@Injectable({
  providedIn: 'root',
})
export class CameraMovementService {
  subscriptions$: Subscription[] = [];
  private cameraState = new Subject<string>();

  private camera!: THREE.PerspectiveCamera;
  private standardCameraPosition!: THREE.Vector3;
  private standardCameraRotation!: THREE.Quaternion;
  private midCameraPosition!: THREE.Vector3;
  private midCameraRotation!: THREE.Quaternion;
  private closeUpCameraPosition!: THREE.Vector3;
  private closeUpCameraRotation!: THREE.Quaternion;
  private currentPosition = 'Standard';
  private executing = false;

  constructor(private unityControllerService: UnityControllerService) {
    this.initServices();
  }

  private initServices() {
    this.subscriptions$.push(
      this.unityControllerService
        .getCameraState()
        .subscribe((cameraMov: string) => {
          if (window.innerWidth / window.innerHeight > 1)
            this.moveCamera(cameraMov);
        })
    );
  }

  public update() {
    if (!this.executing) return;
    TWEEN.update();
  }

  private moveCamera(cameraMov: string) {
    if (this.camera == null || this.currentPosition === cameraMov) return;
    this.executing = true;
    switch (cameraMov) {
      case 'ChangeToMid':
        this.currentPosition = 'ChangeToMid';
        this.executeTransition(this.midCameraPosition, this.midCameraRotation);
        break;
      case 'Standard':
        this.currentPosition = 'Standard';
        this.executeTransition(this.standardCameraPosition, this.standardCameraRotation);
        break;
      case 'ChangeToCloseUp':
        this.currentPosition = 'ChangeToCloseUp';
        this.executeTransition(this.closeUpCameraPosition, this.closeUpCameraRotation);
        break;
    }
    this.cameraState.next(cameraMov);
  }

  private executeTransition(
    targetPosition: THREE.Vector3,
    targetRotation: THREE.Quaternion
  ) {
    new TWEEN.Tween(this.camera.position)
      .to(targetPosition, 1000)
      .easing(TWEEN.Easing.Quadratic.Out)
      .start()
      .onComplete(() => {
        this.executing = false;
      });
    new TWEEN.Tween(this.camera.quaternion)
      .to(targetRotation, 1000)
      .easing(TWEEN.Easing.Quadratic.Out)
      .start();
  }

  public setCamera(camera: THREE.PerspectiveCamera) {
    this.camera = camera;
  }

  public changeMidCameraTarget(newPosition: THREE.Vector3, newRotation: THREE.Quaternion) {
    this.midCameraPosition = newPosition.clone();
    this.midCameraRotation = newRotation.clone();

  }

  public changeCloseUpCameraTarget(newPosition: THREE.Vector3, newRotation: THREE.Quaternion) {
    this.closeUpCameraPosition = newPosition.clone();
    this.closeUpCameraRotation = newRotation.clone();
  }

  public changeStandardCameraTarget(newPosition: THREE.Vector3, newRotation: THREE.Quaternion) {
    this.standardCameraPosition = newPosition.clone();
    this.standardCameraRotation = newRotation.clone();
  }

  public getCameraState(): Observable<string> {
    return this.cameraState.asObservable();
  }
}

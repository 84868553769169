import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-file-attachment',
  templateUrl: './file-attachment.component.html',
  styleUrls: ['./file-attachment.component.scss']
})
export class FileAttachmentComponent {
  @Input() fileName = '';
  @Output() eraseFile = new EventEmitter();

  clearFile(){
    this.fileName = '';
    this.eraseFile.emit();
  }
}

import { Component } from '@angular/core';
import { SwPush } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Nati-App';

  public readonly VAPID_PUBLIC_KEY = 'BJ9n8kBCjS-T9DRSJ4CGOx25v0P-ZkEqACdMu7a1kaH6igm_2L-finPtHHmk2ZT6ylXJnuxfBMNH_O2qDnNa3zs';

  constructor(private swPush: SwPush) {
    // this.subscribeToNotifications();
  }

  subscribeToNotifications() {
    this.swPush.requestSubscription({
      serverPublicKey: this.VAPID_PUBLIC_KEY
    }).then(sub => {
      console.log('Notification Subscription: ', sub);
      const token = JSON.parse(JSON.stringify(sub));
      console.log('Token: ', token);
    }).catch(err => console.error('Could not subscribe to notifications', err));
  }
}

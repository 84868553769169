<div id="locationContainer" class="location-container" *ngIf="isWatson">
  <p id="flowLocation" class="location">
    <span *ngFor="let location of locationFlow; let lastLocation = last" class="inner-location">
      <span *ngIf="!lastLocation">{{location}} / </span>
      <b *ngIf="lastLocation">{{location}}<span class="invisible-char">x</span> </b>
    </span>
  </p>
</div>
<hr class="location-line" *ngIf="isWatson && locationFlow.length > 0">
<div class="avatar-space" *ngIf="!noAvatar"></div>
<div class="container-chat" #chat [ngClass]="{'elder-font-general' : elderLayout, 'vertical-max-height': aspectRatio < 1}"
  [ngStyle]="{'max-height': keyboardStatus ? '23vh' : ''}">
  <div #messagesList class="messages-list">
    <div *ngFor="let messageChat of messagesChat; let lastMessage = last" [@zoomIn]>
      <!-- Assitant messages -->
      <div *ngIf="messageChat.from === 'assistant'">
        <p class="message-from-assistant" *ngIf="messageChat.order === 1">{{avatarName}}:</p>
        <div class="assistant-message-container">
          <div class="assistant-message left shadow-filter">
            <div class="assistant-message-content" [innerHTML]="messageChat.text"></div>
          </div>
        </div>
      </div>
      <!-- para el componente date picker -->
      <app-monthpicker *ngIf="messageChat.datepicker" (choseDateEvent)="sendDate($event)"></app-monthpicker>
      <!-- para el componente date picker -->
      <!-- Dynamic Image -->
      <img *ngIf="messageChat.image ! undefined" class="img-fluid chat-image" src="{{messageChat.image}}"
        alt="message" (click)="showFullSizeImg(messageChat.image)" (load)="imageLoaded()"
        (keyup)="showFullSizeImg(messageChat.image)" tabindex="0">

      <!-- Dynamic Youtube Video with URL -->
      <div class="assistant-message-video" *ngIf="messageChat.youtubevideo">
        <div class="assistant-message-content-video">
          <iframe title="assistant video" width="100%" height="415" [appSafeYoutubeEmbed]="messageChat.youtubevideo" []
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
            allowfullscreen></iframe>
        </div>
      </div>

      <!-- Dynamic checkbox -->
      <div class="assitan-message-checkbox my-2" *ngIf="messageChat.checkbox && messageChat.checkbox.length > 0">
        <div class="assistan-message-content-checkbox">
          <div class="form-check" *ngFor="let checkbox of messageChat.checkbox; let i = index">
            <input class="form-check-input" type="checkbox" [value]="checkbox.name" [id]="'checkbox-' + i">
            <label class="form-check-label" [for]="'checkbox-' + i">
              {{checkbox.name}}
            </label>
          </div>
          <button type="button" class="btn dynamic-button my-2" (click)="selectedCheckboxes()">Enviar opciones</button>
        </div>
      </div>

      <!-- Dynamic select -->
      <div class="assistant-message-select" *ngIf="messageChat.select">
        <div class="assistant-message-content-select">
          <select id="select-municipality" class="form-select my-2" name="selectedMunicipio"
            [(ngModel)]="selectedMunicipio">
            <option [ngValue]="municipio" *ngFor="let municipio of municipios">{{municipio.NombreLocalidad}}</option>
          </select>
          <button type="button" class="btn dynamic-button my-2" (click)="confirmMunicipality()"
            [disabled]="!selectedMunicipio">Confirmar municipio</button>
        </div>
      </div>

      <!-- Numeric keyboard inside chat -->
      <app-numerickeyboard [showInputFeedback]="true" #numericKeyboard
        *ngIf="lastMessage && virtualNumericKeyboard"></app-numerickeyboard>
      <!-- Dynamic Buttons -->
      <div class="assistant-message-button mb-1" *ngIf="messageChat.buttons && messageChat.buttons.length > 0">
        <div #buttonContainer class="assistant-message-content-button">
          <div class="grid gap-2">
            <button *ngFor="let button of messageChat.buttons; let i = index" type="button"
              class="btn dynamic-button g-col-6" (click)="onButtonClick(button)"
              [ngClass]="{'button-clicked': button.isClicked, 'elder-font': elderLayout}">
              <div>
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"
                  *ngIf="button.icon === 'volver'">
                  <path
                    [ngStyle]="{'fill': button.isClicked ? 'var(--dynamic-button-pressed-text-color)' : 'var(--dynamic-button-bg-color)'}"
                    d="M15.8543 23.1484L7.62606 14.9202H25.9992V11.0869H7.62606L15.8543 2.8586L13.1441 0.148438L0.289062 13.0035L13.1441 25.8586L15.8543 23.1484Z" />
                </svg>
                {{ button.name }}
              </div>
            </button>
          </div>
        </div>
      </div>

      <!-- Dynamic Carousel -->
      <ng-container *ngIf="messageChat.cards && messageChat.cards.length > 0">
        <div class="container-carousel" #container>
          <div class="left-control-card" *ngIf="totalPages > 1" (click)="currentPage !== 1 && changePage(-1)"
            [ngClass]="{'disabled':currentPage === 1}" (keydown.enter)="currentPage !== 1 && changePage(-1)"
            tabindex="0"></div>
          <div class="cards-list" [ngClass]="{'w-100': totalPages === 1}">
            <div class="overflow-card-list" [ngStyle]="{'width': overflowWidth, 'left': pagePosition}">
              <div class="card-list" *ngFor="let card of messageChat.cards; let index = index"
                [ngStyle]="{'width': cardWidth}" (click)="onCardClick(card)" (keydown.enter)="onCardClick(card)"
                tabindex="0">
                <div class="card-item">
                  <img src="{{card.icon}}" alt="" class="img-card">
                  <span class="text-card">{{card.name}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="right-control-card" *ngIf="totalPages > 1" (click)="currentPage !== totalPages && changePage(+1)"
            [ngClass]="{'disabled':currentPage === totalPages}"
            (keydown.enter)="currentPage !== totalPages && changePage(+1)" tabindex="0"></div>
        </div>
      </ng-container>

      <!-- User messages -->
      <div *ngIf="messageChat.from === 'user'">
        <p class="message-from-user mt-3">{{'ui.chatSection.userMessage' | translate}}</p>
        <div class="user-message-container">
          <div class="user-message right shadow-filter">
            <div class="user-message-content">
              <p *ngIf="!messageChat.userImage && !messageChat.userVideo">{{messageChat.text}}</p>
              <a *ngIf="messageChat.userImage" href="{{messageChat.userImage}}" download="{{messageChat.text}}">{{messageChat.text}}</a>
              <a *ngIf="messageChat.userVideo" href="{{messageChat.userVideo}}" download="{{messageChat.text}}">{{messageChat.text}}</a>
            </div>
          </div>
        </div>
      </div>

      <div class="user-media-container">
        <img *ngIf="messageChat.userImage ! undefined" class="img-fluid chat-image" src="{{messageChat.userImage}}"
          alt="message" (click)="showFullSizeImg(messageChat.image)" (load)="imageLoaded()"
          (keyup)="showFullSizeImg(messageChat.image)" tabindex="0">
      </div>

      <div class="user-media-container">
        <video id="user-video" *ngIf="messageChat.userVideo" controls src="{{messageChat.userVideo}}"></video>
      </div>
    </div>

    <!-- Show dots loading -->
    <div class="assistant-message left shadow-filter" *ngIf="showDotsLoading" [@zoomInDots]>
      <div class="assistant-message-content">
        <div class="dots-loader"></div>
      </div>
    </div>
  </div>
</div>

<!-- Skip buttons -->
<div class="skip-buttons-container" *ngIf="showSkipButton">
  <button class="skip-button" (click)="nextMessage()">
    {{'ui.skipButtons.skipMessage' | translate}}
    <svg class="skip-msg-svg icons-ui" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path class="skip-svg-path"
        d="M12 12V7.65c0-.11.025-.22.072-.316c.152-.314.5-.428.775-.253l6.86 4.349c.093.059.17.147.221.253c.153.314.054.71-.221.885l-6.86 4.35a.516.516 0 0 1-.277.081c-.315 0-.57-.291-.57-.651zc0 .23-.106.451-.293.57l-6.86 4.35a.516.516 0 0 1-.277.08c-.315 0-.57-.291-.57-.651V7.651c0-.11.025-.22.072-.316c.152-.314.5-.428.775-.253l6.86 4.349c.093.059.17.147.221.253c.049.1.072.209.072.315" />
    </svg>
  </button>
  <button class="skip-button" (click)="stopMessages()">
    {{'ui.skipButtons.skipToEnd' | translate}}
    <svg class="skip-svg icons-ui" viewBox="0 0 20 32" fill="none" xmlns="http://www.w3.org/2000/svg" [@zoomInDots]
      title="stop">
      <path class="skip-svg-path"
        d="M3.28125 27.5366C2.6875 28.0304 1.85625 28.1429 1.15 27.8116C0.44375 27.4804 0 26.7741 0 25.9991V5.99914C0 5.22414 0.45 4.51789 1.15 4.18664C1.85 3.85539 2.68125 3.96164 3.28125 4.46164L15.2812 14.4616L16 15.0616V5.99914C16 4.89289 16.8937 3.99914 18 3.99914C19.1063 3.99914 20 4.89289 20 5.99914V25.9991C20 27.1054 19.1063 27.9991 18 27.9991C16.8937 27.9991 16 27.1054 16 25.9991V16.9366L15.2812 17.5366L3.28125 27.5366Z" />
    </svg>
  </button>
</div>
<!-- Skip buttons -->

<!-- Input -->
<div class="input-container" [ngStyle]="{'justify-content': noAvatar ? 'flex-start' : 'space-between'}">
  <app-mic-section *ngIf="microphoneAllowed" [noAvatar]="noAvatar"></app-mic-section>

  <div class="input-message float-end" [ngStyle]="{'width': !microphoneAllowed ? '100%' : null}">
    <input type="text" id="chat-input" class="form-control shadow-sm" #chatInput
      placeholder="{{'ui.chatSection.chatPlaceholder' | translate}}" (keydown.enter)="sendMessage()"
      [(ngModel)]="userMessage" (ngModelChange)="onMessageChange()" (drop)="dropFileAttachment($event)" (dragover)="onDragOver($event)" maxlength="512" [disabled]="!chatStatus">
    <div class="input-elements-container" [ngStyle]="{'width': fileName ? '100%' : '20%'}">
      <div class="input-file-container" *ngIf="fileName">
        <app-file-attachment class="w-100 py-2 bg-white" [fileName]="fileName" (eraseFile)="clearFile()"></app-file-attachment>
      </div>
      <div class="input-button-container">
        <button type="button" title="attach" (click)="fileInput.click()" *ngIf="checkFileButton()">
          <svg class="icons-ui-stroke" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14 2V8H20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 18V12" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9 15H15" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>  
        </button>
        <input type="file" #fileInput class="file-selection" title="attach" accept=".png, .pdf, .jpeg, .jpg, .bmp, .heif, .mp4" (change)="onFileSelected($event)">
        <button type="button" title="send" (click)="sendMessage()" *ngIf="!iconSendMessageStatus">
          <svg xmlns="http://www.w3.org/2000/svg" class="icons-ui" viewBox="0 0 24 24">
            <path
              d="m21.426 11.095-17-8A.999.999 0 0 0 3.03 4.242L4.969 12 3.03 19.758a.998.998 0 0 0 1.396 1.147l17-8a1 1 0 0 0 0-1.81zM5.481 18.197l.839-3.357L12 12 6.32 9.16l-.839-3.357L18.651 12l-13.17 6.197z" />
          </svg>
        </button>
      </div>
    </div>
    
  </div>
</div>

<div class="preview" #previewImage>
  <div class="preview-content">
    <span class="close-button" (click)="closePreview()" (keydown.enter)="closePreview()" tabindex="0">&times;</span>
    <img src="{{previewImageSrc}}" class="img-fluid" alt="Visualización imagen" />
  </div>
</div>
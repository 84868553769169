<div class="shift-table-background" [@fadeIn]="videoStatus" [@fadeOut]="videoStatus"></div>

<div class="video-container" #videoContainer [@fadeIn]="videoStatus" [@fadeOut]="videoStatus">
    <youtube-player videoId="iCvUFavByJE" #video [width]="videoWidth" [height]="videoHeight"></youtube-player>
</div>


<div [ngClass]="showNameColumn ? 'table-container' : 'table-container-no-name'">
    <div class="assistant-message left shadow-filter">
        <div class="assistant-message-content"
        [ngStyle]="{'grid-template-columns': showNameColumn ? 'repeat(2, 1fr) 3fr' : 'repeat(2, 1fr)'}">
        <div class="title-cell">TURNO</div>
        <div class="title-cell">TAQUILLA</div>
        <div class="title-cell" *ngIf="showNameColumn">NOMBRE</div>
        <div class="body-cell" *ngFor="let shift of shifts">{{shift}}</div>
    </div>
</div>
</div>
<ng-template #contentAddress let-modalAddress>
	<div class="modal-header">
    <div>
      <h4 class="modal-title fw-bold" id="modal-basic-title">Dirección</h4>
      <h6 class="text-black-50">Ejemplo: Calle 74A 21C 50 Sur</h6>
    </div>
		<button type="button" class="btn-close" aria-label="Close" (click)="modalAddress.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<form [formGroup]="addressForm">
			<div class="d-flex flex-wrap gap-2 justify-content-center align-items-center">
        <div class="mb-3">
          <label for="tipoVia" class="form-label fw-bold">Tipo vía</label>
          <select name="tipoVia" id="tipoVia" class="form-select address-input" formControlName="tipoVia">
            <option [defaultSelected]></option>
            <option value="Calle">Calle</option>
            <option value="Carrera">Carrera</option>
            <option value="Diagonal">Diagonal</option>
            <option value="Avenida">Avenida</option>
            <option value="Carretera">Carretera</option>
            <option value="Circular">Circular</option>
            <option value="Circunvalar">Circunvalar</option>
            <option value="Bulevar">Bulevar</option>
            <option value="Transversal">Transversal</option>
            <option value="Troncal">Troncal</option>
            <option value="Variante">Variante</option>
            <option value="Vía">Vía</option>
            <option value="Corregimiento">Corregimiento</option>
            <option value="Kilómetro">Kilómetro</option>
            <option value="Vereda">Vereda</option>
            <option value="Autopista">Autopista</option>
          </select>
        </div>

        <div class="mb-3">
          <label for="numeroPrincipal" class="form-label fw-bold">Número principal</label>
          <input type="text" class="form-control address-input" id="numeroPrincipal" formControlName="numeroPrincipal" placeholder="Ingrese un número">
        </div>

        <div class="mb-3">
          <label for="letra" class="form-label fw-bold">Letra</label>
          <select name="letra" id="letra" class="form-select address-input" formControlName="letra">
            <option [defaultSelected]></option>
            <option value="A">A</option>
            <option value="AD">AD</option>
            <option value="B">B</option>
            <option value="C">C</option>
            <option value="D">D</option>
            <option value="E">E</option>
            <option value="F">F</option>
            <option value="G">G</option>
            <option value="H">H</option>
            <option value="I">I</option>
            <option value="J">J</option>
            <option value="K">K</option>
            <option value="L">L</option>
            <option value="M">M</option>
            <option value="N">N</option>
            <option value="O">O</option>
            <option value="P">P</option>
            <option value="Q">Q</option>
            <option value="R">R</option>
            <option value="S">S</option>
            <option value="T">T</option>
            <option value="U">U</option>
            <option value="V">V</option>
            <option value="W">W</option>
            <option value="X">X</option>
            <option value="Y">Y</option>
            <option value="Z">Z</option>
          </select>
        </div>

        <div class="mb-3">
          <label for="complemento" class="form-label fw-bold">Complemento</label>
          <select name="complemento" id="complemento" class="form-select address-input" formControlName="complemento">
            <option [defaultSelected]></option>
            <option value="Norte">Norte</option>
            <option value="Sur">Sur</option>
            <option value="Este">Este</option>
            <option value="Oeste">Oeste</option>
          </select>
        </div>

        <p class="numeral">#</p>

        <div class="mb-3">
          <label for="numeroSecundario" class="form-label fw-bold">Número secundario</label>
          <input type="text" class="form-control address-input" id="numeroSecundario" formControlName="numeroSecundario" placeholder="Ingrese un número">
        </div>

        <div class="mb-3">
          <label for="letraSecundario" class="form-label fw-bold">Letra</label>
          <select name="letraSecundario" id="letraSecundario" class="form-select address-input" formControlName="letraSecundario">
            <option [defaultSelected]></option>
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
            <option value="D">D</option>
            <option value="E">E</option>
            <option value="F">F</option>
            <option value="G">G</option>
            <option value="H">H</option>
            <option value="I">I</option>
            <option value="J">J</option>
            <option value="K">K</option>
            <option value="L">L</option>
            <option value="M">M</option>
            <option value="N">N</option>
            <option value="O">O</option>
            <option value="P">P</option>
            <option value="Q">Q</option>
            <option value="R">R</option>
            <option value="S">S</option>
            <option value="T">T</option>
            <option value="U">U</option>
            <option value="V">V</option>
            <option value="W">W</option>
            <option value="X">X</option>
            <option value="Y">Y</option>
            <option value="Z">Z</option>
          </select>
        </div>

        <div class="line"></div>

        <div class="mb-3">
          <label for="placa" class="form-label fw-bold">Placa</label>
          <input type="text" class="form-control address-input" id="placa" formControlName="placa" placeholder="Ingrese placa">
        </div>

        <div class="mb-3">
          <label for="complementoSecundario" class="form-label fw-bold">Complemento</label>
          <select name="complementoSecundario" id="complementoSecundario" class="form-select address-input" formControlName="complementoSecundario">
            <option [defaultSelected]></option>
            <option value="Norte">Norte</option>
            <option value="Sur">Sur</option>
            <option value="Este">Este</option>
            <option value="Oeste">Oeste</option>
          </select>
        </div>

        <div class="mb-3">
          <label for="interior" class="form-label fw-bold">Interior</label>
          <select name="interior" id="interior" class="form-select address-input" formControlName="interior">
            <option [defaultSelected]></option>
            <option value="Casa">Casa</option>
            <option value="Apartamento">Apartamento</option>
            <option value="Oficina">Oficina</option>
            <option value="Interior">Interior</option>
          </select>
        </div>

        <div class="mb-3">
          <label for="numeroInterior" class="form-label fw-bold">Número interior</label>
          <input type="text" class="form-control address-input" id="numeroInterior" formControlName="numeroInterior" placeholder="Ingrese un número">
        </div>
			</div>

      <div class="d-block m-auto text-center my-3">
        <label for="address-output" class="form-label fw-bold">Dirección ingresada</label>
        <input type="text" id="address-output" [readOnly]="true" class="form-control address-output shadow-sm" [value]="address" placeholder="Aquí podras visualizar la dirección">
      </div>

      <div class="d-block m-auto mt-4">
        <app-keyboard></app-keyboard>
      </div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-clear shadow-sm" (click)="clearForm()">Limpiar</button>
		<button type="button" class="btn btn-send shadow-sm" ngbAutofocus [disabled]="!addressForm.valid" (click)="modalAddress.close()">Enviar</button>
	</div>
</ng-template>

<!-- <button class="btn btn-lg btn-info" (click)="openModal()">Dirección</button> -->

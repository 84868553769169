import { Component } from '@angular/core';

@Component({
  selector: 'app-service-unavailable',
  templateUrl: './service-unavailable.component.html',
  styleUrls: ['./service-unavailable.component.scss']
})
export class ServiceUnavailableComponent {

  redirectToNati() {
    window.open('https://nati.ai', '_blank');
  }
}

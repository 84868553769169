import { Component } from '@angular/core';

@Component({
  selector: 'app-max-sessions',
  templateUrl: './max-sessions.component.html',
  styleUrls: ['./max-sessions.component.scss']
})
export class MaxSessionsComponent {

  redirectToNati() {
    window.open('https://nati.ai', '_blank');
  }
}

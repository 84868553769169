import { Component, ElementRef, ViewChild, OnInit, ChangeDetectorRef, AfterViewInit, OnDestroy, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { UseCasesService } from 'src/app/services/chat/use-cases.service';
import { UnityControllerService } from 'src/app/services/unity-controller.service';
import { QueueProcessorService } from 'src/app/services/chat/queue-processor.service';
import { AudioControllerService } from 'src/app/services/audio-controller.service';
import { SpeechToTextService } from 'src/app/services/chat/speech-to-text.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { concatMap, Subscription } from 'rxjs';
import { TranslatorService } from 'src/app/services/translator.service';
import { TenantDataService } from 'src/app/services/tenant/tenant-data.service';
import { allowedFileTypes } from 'src/app/models/constants/file-extensions';
import { FileExtensions, FileTypes } from 'src/app/models/enums/file-attachment';
import { FileDTO } from 'src/app/models/file-attachment.model';
import { GenericModalService } from 'src/app/services/utils/generic-modal.service';
import { TipoNLP } from 'src/app/models/enums/tiponlp';
import { DatumButtons, StartButtons, TenantData } from 'src/app/models/tenant-data.model';

declare global {
  interface Window { SendUserInteraction: (event: string) => void; }
}

// Definir una animación de zoomIn para el chat
export const zoomIn = trigger('zoomIn', [
  transition(':enter', [
    style({ opacity: 0, transform: 'scale(0.2)' }),
    animate('500ms', style({ opacity: 1, transform: 'scale(1)' })),
  ]),
]);

export const zoomInDots = trigger('zoomInDots', [
  transition(':enter', [
    style({ opacity: 0, transform: 'scale(0.2)' }),
    animate('500ms 0.5s', style({ opacity: 1, transform: 'scale(1)' })),
  ]),
]);

@Component({
  selector: 'app-chat-section',
  templateUrl: './chat-section.component.html',
  styleUrls: ['./chat-section.component.scss'],
  animations: [zoomIn, zoomInDots]
})
export class ChatSectionComponent implements OnInit, AfterViewInit, OnDestroy {

  subscriptions$: Subscription[] = [];

  // Un solo array para los mensajes de usuario y asistente para mostrarlos en el chat de forma ordenada
  messagesChat: any[] = [];
  userMessage = '';
  conversationStatus = false;
  keyboardStatus = false;
  chatStatus = false;
  iconSendMessageStatus = false;
  showSkipButton = false;
  locationFlow: string[] = [];
  previewImageSrc = '';
  fileAttached: File[] = [];
  fileAttachedBase64: ArrayBuffer | string = '';
  fileType = ''; 
  fileName = '';
  fileExtension = '';
  nlpType = TipoNLP.llm;
  completeBase64File = '';
  private maxFileSize = 20 * 1024 * 1024;
  private fileID = 0;
  backWords: { [idioma: string]: string[] } = {
    'es': ['atrás', 'volver', 'anterior', 'regresar'],
    'en': ['back', 'return', 'previous', 'return', 'finish'],
    'pt': ['voltar', 'retornar', 'anterior', 'regressar'],
    'de': ['zurück', 'zurückkehren', 'vorherige', 'zurückkehren', 'finish'],
    'fr': ['retour', 'revenir', 'précédent', 'retour'],
    'zh-Hans': ['后退', '返回', '前', '返回'],
    'it': ['indietro', 'ritorno', 'precedente', 'tornare', 'finire']
  };
  currentLanguage!: string;

  @ViewChild('chat') chat!: ElementRef;
  @ViewChild('previewImage') previewImage!: ElementRef;
  @ViewChild('chatInput') chatInput!: ElementRef;
  @ViewChild('messagesList', { read: ElementRef, static: true }) messagesList!: ElementRef;
  @Input() noAvatar!: boolean;
  @Input() mainFlow!: string;
  @Input() isWatson!: boolean;
  @Input() isSingleFlow!: boolean;
  @Input() elderLayout!: boolean;
  @Input() microphoneAllowed!: boolean;
  @Input() fileAttachment!: boolean;

  @Output() onUserMessageAdded = new EventEmitter<number>();
  private userMessageCount = 0;
  avatarName!: string;

  // Dynamic carousel
  totalCards!: number;
  currentPage = 1;
  pagePosition = "0%";
  cardsPerPage!: number;
  totalPages!: number;
  overflowWidth!: string;
  cardWidth!: string;
  containerWidth!: number;
  container: ElementRef | undefined;
  virtualNumericKeyboard = false;
  @ViewChild("container", { static: false }) set containerRef(ref: ElementRef) {
    if (ref) {
      this.container = ref;
      this.windowResized();
    }
  }

  @HostListener("window:resize") windowResize() {
    this.windowResized();
  }

  municipios: Array<any> = [];
  selectedMunicipio: any;

  showDotsLoading = false;
  assitantMessageIndex = 0;
  aspectRatio = window.innerWidth / window.innerHeight;

  constructor(
    private webSocketService: WebSocketService,
    private useCasesService: UseCasesService,
    private unityControllerService: UnityControllerService,
    private queueProcessor: QueueProcessorService,
    private audioControllerService: AudioControllerService,
    private speechToTextService: SpeechToTextService,
    private cdRef: ChangeDetectorRef,
    private translator: TranslatorService,
    private tenantDataService: TenantDataService,
    private genericModalService: GenericModalService
  ) {
  }

  ngOnInit(): void {
    this.initServices();
    this.tenantDataService.getDatosTenantEvent().pipe(
      concatMap((res: TenantData) => {
        this.avatarName = res.data.personaDigital.nombre;
        return this.tenantDataService.getFlowEvent();
      })
    ).subscribe((res: DatumButtons) => {
      this.nlpType = res.tipoNLP;
    });
  }

  ngAfterViewInit() {
    // Add event listener to the preview image to close it when clicked outside of it
    document.addEventListener('click', (event: any) => {
      if (event.target.classList.contains('preview-content')) {
        this.closePreview();
      }
    });
  }

  windowResized() {
    const newCardsPerPage = this.getCardsPerPage();
    if (newCardsPerPage !== this.cardsPerPage) {
      this.cardsPerPage = newCardsPerPage;
      this.initializeSlider();
      if (this.currentPage > this.totalPages) {
        this.currentPage = this.totalPages;
        this.populatePagePosition();
      }
    }
  }

  initServices() {
    this.queueProcessor.getLoadingMessage().subscribe(() => {
      this.showDotsLoading = true
      this.chatStatus = false
    });
    // Service to get the messages from the assistant, and push them to the chat
    this.subscriptions$.push(
      this.queueProcessor.getMessage().subscribe((conversation: any) => {
        if (conversation.text === '') return;

        // Add to the message the from property
        conversation.from = 'assistant';
        this.assitantMessageIndex++;
        conversation.order = this.assitantMessageIndex;
        // Add to the message the detail property with the actions to show the image
        conversation.image = conversation.detail.find((action: any) => action.accion === 'showImage');
        if (conversation.image) {
          conversation.image = conversation.image.url;
        }

        // Add to the message the detail property with the actions to show the youtube video with url
        conversation.youtubevideo = conversation.detail.find((action: any) => action.accion === 'showYoutubeVideo');
        if (conversation.youtubevideo) {
          conversation.youtubevideo = conversation.youtubevideo.url;
        }

        conversation.datepicker = conversation.detail.find((action: any) => action.accion === 'monthPicker');
        if (conversation.datepicker) {
          conversation.datepicker = true;
        }

        // Add to the message the buttons to show (if there are any)
        const buttonsToShow = this.getButtonsToShow(conversation.detail);
        if (buttonsToShow.length > 0) {
          conversation.buttons = buttonsToShow;
        }

        // Add to the message the checkbox to show (if there is any)
        const checkboxToShow = this.getCheckboxToShow(conversation.detail);
        if (checkboxToShow.length > 0) {
          conversation.checkbox = checkboxToShow;
        }

        // Add to the message the select to show (if there is any)
        const selectToShow = conversation.detail.find((action: any) => action.accion === 'showMunicipioDropdown');
        if (selectToShow) {
          this.useCasesService.getDataMunicipiosAntioquia().then((data: any) => {
            this.municipios = data;
            this.selectedMunicipio = this.municipios[1];
          });
          conversation.select = selectToShow;
        }

        // Add to the message the carousel to show (if there is any)
        const cardsCarouselToShow = this.getCardsCarouselToShow(conversation.detail);
        if (cardsCarouselToShow.length > 0) {
          conversation.cards = cardsCarouselToShow;
        }

        // If in the message exist a url, convert it to a link
        this.formatMessageText(conversation);
        this.messagesChat.push(conversation);
        this.showDotsLoading = false;
        this.conversationStatus = true;

        setTimeout(() => {
          // if (buttonsToShow.length > 8) return;
          this.scrollToBottom();
        }, 500);
      })
    );

    // Service to know when the conversation has ended
    this.subscriptions$.push(
      this.useCasesService.getStopConversation().subscribe(() => {
        this.webSocketService.setEndConversation(true);
        this.conversationStatus = false;

        this.useCasesService.setVirtualKeyboardState(false);
        this.useCasesService.setVirtualNumericKeyboardState(false);

        setTimeout(() => {
          this.assitantMessageIndex = 0;
          this.messagesChat = [];
          this.locationFlow = [];
          this.fileID = 0;
        }, 500);
      })
    );

    // Service to get the recognized speech from the microphone, abd push it to the chat
    this.subscriptions$.push(
      this.speechToTextService.getRecognizedSpeech().subscribe((speech: string) => {
        if (speech === '') {
          return;
        }
        this.disableButtons();
        this.addUserMessage(speech);
        this.webSocketService.SendMessageThroughSocket(speech);
        this.showDotsLoading = true;
        this.useCasesService.setVirtualKeyboardState(false);
        this.useCasesService.setVirtualNumericKeyboardState(false);

        this.scrollToBottom();
      })
    );

    // Service to get the address from the modal, and push it to the chat
    this.subscriptions$.push(
      this.useCasesService.getAddressMessage().subscribe((address: string) => {
        if (address === '') return;

        this.disableButtons();
        this.addUserMessage(address);

        const { CodigoLocalidad } = this.selectedMunicipio;
        const addressInfo = {
          idDepartamento: "5",
          idMunicipio: CodigoLocalidad || "0",
          direccion: address,
        }

        this.webSocketService.setAddresInfo(addressInfo);
        this.webSocketService.sendAddressInfo();

        this.scrollToBottom();
      })
    );

    // Service to know the status of the keyboard (on/off)
    this.subscriptions$.push(
      this.unityControllerService.getKeyboardOnOff().subscribe((status: boolean) => {
        this.keyboardStatus = status;

        if (status === true) {
          this.scrollToBottom();
        }
      })
    );

    this.subscriptions$.push(
      this.useCasesService.getShowLoading().subscribe(() => {
        setTimeout(() => {
          this.showDotsLoading = true;

          this.webSocketService.SendMessageThroughSocket('si');
          this.scrollToBottom();
        }, 0);
      })
    );

    this.subscriptions$.push(
      this.webSocketService.getChatState().subscribe((status: boolean) => {
        this.chatStatus = status;
      }),
      this.queueProcessor.getClearChat().subscribe(() => {
        this.messagesChat = [];
        this.locationFlow = [];
        this.assitantMessageIndex = 0;
        this.fileID = 0;
      })
    );

    this.subscriptions$.push(
      this.useCasesService.getVirtualKeyboardMessage().subscribe((message: string) => {
        this.userMessage = message;
        this.onMessageChange();
      }),
      this.useCasesService.getVirtualKeyboardSendMessage().subscribe(() => {
        this.sendMessage();
      })
    );

    this.subscriptions$.push(
      this.useCasesService.getVirtualNumericKeyboardState().subscribe((status: boolean) => {
        if (status === true) {
          this.virtualNumericKeyboard = true;
          this.useCasesService.setEventVirtualNumericKeyboard(this.chatInput.nativeElement);

          setTimeout(() => {
            this.useCasesService.setEventVirtualNumericKeyboard(this.chatInput.nativeElement);
            this.chatInput.nativeElement.focus();
          }, 100);
        } else {
          this.virtualNumericKeyboard = false;
        }
      }),
      this.useCasesService.getVirtualNumericKeyboardMessage().subscribe((message: string) => {
        this.userMessage = message;
        this.onMessageChange();
      }),
      this.useCasesService.getVirtualNumericKeyboardSendMessage().subscribe(() => {
        this.sendMessage();
      })
    );

    // Service to know when the queue is processing a message and when it has finished
    this.subscriptions$.push(
      this.queueProcessor.getMessageAddedSubject().subscribe((messages: number) => {
        this.iconSendMessageStatus = true;
        this.showSkipButton = messages > 1;
      }),
      this.queueProcessor.getProcessingCompleteSubject().subscribe(() => {
        this.chatStatus = true
        this.iconSendMessageStatus = false;
        this.showSkipButton = false;
      })
    );

    this.subscriptions$.push(
      this.translator.getLenguageState().subscribe((langCode: string) => {
        this.currentLanguage = langCode;
      })
    )

    this.currentLanguage = this.translator.getOriginalLanguage();
  }

  private formatMessageText(conversation: any) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    if (conversation.text.match(urlRegex)) {
      conversation.text = conversation.text.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
    }

    const boldRegex = /\*\*(.*?)\*\*/g;

    let boldTextArray
    do {
      conversation.text = conversation.text.replace("**", "<b>").replace("**", "</b>");
      boldTextArray = conversation.text.match(boldRegex);
    } while (boldTextArray?.length > 0)

    conversation.text = conversation.text.replace(/\n/g, '<br>');
  }

  // Skip the current message and cancel the speech
  stopMessages() {
    this.iconSendMessageStatus = false;
    this.showSkipButton = false
    this.audioControllerService.playAudio('/assets/audio/button-click.mp3');
    this.queueProcessor.skipQueue();
    this.useCasesService.setMicState(3);
    this.queueProcessor.clearMicrophoneActions();
  }

  nextMessage() {
    this.audioControllerService.playAudio('/assets/audio/button-click.mp3');
    const lastMessage = this.queueProcessor.skipMessage();
    if (lastMessage) {
      this.iconSendMessageStatus = false;
      this.useCasesService.setMicState(3);
      this.queueProcessor.clearMicrophoneActions();
    }
  }

  // Send the user message to the websocket
  sendMessage() {
    if(this.fileName) this.sendFile();
    else if (this.userMessage.trim() === '') {
      this.scrollToBottom();
      return;
    } else {
      if (this.selectedMunicipio && this.selectedMunicipio.CodigoLocalidad) {
        const { CodigoLocalidad } = this.selectedMunicipio;
        const addressInfo = {
          idDepartamento: "5",
          idMunicipio: CodigoLocalidad,
          direccion: this.userMessage,
        };
        this.webSocketService.setAddresInfo(addressInfo);
      }
      this.addUserMessage(this.userMessage);
      this.audioControllerService.playAudio('/assets/audio/button-click.mp3');
      this.queueProcessor.clearQueue();
      this.useCasesService.setMicState(3);
      this.queueProcessor.clearMicrophoneActions();
      this.webSocketService.SendMessageThroughSocket(this.userMessage);
      this.userMessage = '';
      this.showDotsLoading = true;

      this.disableButtons();
      this.scrollToBottom();

      this.useCasesService.setVirtualKeyboardState(false);
      this.useCasesService.setVirtualNumericKeyboardState(false);
    }
  }

  // Dynamic buttons ============================================================
  getButtonsToShow(detail: any[]): any[] {
    if (this.locationFlow.length == 0 && !this.isSingleFlow) {
      if (this.translator.getCurrentLanguage() !== "es")
        this.translator.translateText(this.mainFlow).subscribe((res: any) => {
          this.locationFlow.push(res[0].translations[0].text);
        })
      else this.locationFlow.push(this.mainFlow);
    }

    if (detail.length === 0) return [];

    const buttonsToShow: any[] = [];
    for (const action of detail) {
      if (action.accion === 'showAdditionalButtons') {
        for (let i = 0; i < action.btnsList.length; i++) {
          const button = action.btnsList[i];
          button.index = i; // Add the index to the button to know which one was clicked
          button.isClicked = false; // Add the isClicked property and initialize it as false
          buttonsToShow.push(button);
        }
      }
    }

    return buttonsToShow;
  }


  onButtonClick(button: any) {
    button.isClicked = true;
    this.audioControllerService.playAudio('/assets/audio/button-click.mp3');
    this.disableButtons();

    this.addUserMessage(button.name);
    this.queueProcessor.clearQueue();
    this.useCasesService.setMicState(3);
    this.useCasesService.setVirtualNumericKeyboardState(false);
    this.queueProcessor.clearMicrophoneActions();
    this.webSocketService.SendMessageThroughSocket(button.name);
    this.iconSendMessageStatus = false;
    this.showDotsLoading = true;
    this.processFlowLocation(button.name);
    this.scrollToBottom();
  }

  disableButtons() {
    const buttons = document.getElementsByClassName('dynamic-button');

    for (let i = 0; i < buttons.length; i++) {
      buttons[i].setAttribute('disabled', 'true');
    }
  }
  // Dynamic buttons ============================================================

  // Dynamic checkbox ===========================================================
  getCheckboxToShow(detail: any[]): any[] {
    if (detail.length === 0) return [];

    const checkboxToShow: any[] = [];

    for (const action of detail) {
      if (action.accion === 'showCheckboxes') {
        for (let i = 0; i < action.btnsList.length; i++) {
          const checkbox = action.btnsList[i];
          checkbox.index = i; // Add the index to the checkbox to know which one was clicked
          checkbox.isClicked = false; // Add the isClicked property and initialize it as false
          checkboxToShow.push(checkbox);
        }
      }
    }

    return checkboxToShow;
  }

  selectedCheckboxes() {
    const selectedCheckboxes: string[] = [];

    const checkboxes = Array.from(document.querySelectorAll('.form-check-input'));

    checkboxes.forEach((checkbox) => {
      const inputCheckBox = checkbox as HTMLInputElement;
      if (inputCheckBox.checked) {
        selectedCheckboxes.push(inputCheckBox.value);
      }
    });

    if (selectedCheckboxes.length === 0) return;

    const stringCheckboxes = selectedCheckboxes.join(', ');

    this.disableButtons();
    this.addUserMessage(stringCheckboxes);
    this.audioControllerService.playAudio('/assets/audio/button-click.mp3');
    this.queueProcessor.clearQueue();
    this.useCasesService.setMicState(3);
    this.queueProcessor.clearMicrophoneActions();
    this.webSocketService.SendMessageThroughSocket(stringCheckboxes);
    this.userMessage = '';
    this.iconSendMessageStatus = false;

    // Disable checkboxes
    checkboxes.forEach((checkbox) => {
      const inputCheckBox = checkbox as HTMLInputElement;
      inputCheckBox.disabled = true;
    });

    this.scrollToBottom();
  }
  // Dynamic checkbox ===========================================================

  // Dynamic select =============================================================
  confirmMunicipality() {
    const { NombreLocalidad, CodigoLocalidad } = this.selectedMunicipio;
    if (!NombreLocalidad || !CodigoLocalidad) return;

    this.disableButtons();
    this.addUserMessage(NombreLocalidad);
    this.audioControllerService.playAudio('/assets/audio/button-click.mp3');
    this.queueProcessor.clearQueue();
    this.useCasesService.setMicState(3);
    this.queueProcessor.clearMicrophoneActions();
    this.webSocketService.SendMessageThroughSocket(NombreLocalidad);
    this.userMessage = '';
    this.iconSendMessageStatus = false;

    this.scrollToBottom();
  }
  // Dynamic select =============================================================


  // Dynamic carousel of cards ==================================================
  getCardsCarouselToShow(detail: any[]): any[] {
    if (detail.length === 0) return [];

    const cardsCarouselToShow: any[] = [];

    this.totalCards = 0;
    for (const action of detail) {
      if (action.accion === 'showCarousel') {
        for (let i = 0; i < action.btnsList.length; i++) {
          this.totalCards++;
          const card = action.btnsList[i];
          card.index = i; // Add the index to the card to know which one was clicked
          card.isClicked = false; // Add the isClicked property and initialize it as false
          cardsCarouselToShow.push(card);
        }
      }
    }

    return cardsCarouselToShow;
  }

  onCardClick(card: any) {
    card.isClicked = true;

    this.audioControllerService.playAudio('/assets/audio/button-click.mp3');

    this.disableButtons();
    this.disableCarouselDivs();
    this.addUserMessage(card.name, [], card.icon);
    this.queueProcessor.clearQueue();
    this.useCasesService.setMicState(3);
    this.queueProcessor.clearMicrophoneActions();
    this.webSocketService.SendMessageThroughSocket(card.name);
    this.iconSendMessageStatus = false;

    this.scrollToBottom();
  }


  initializeSlider() {
    this.totalPages = Math.ceil(this.totalCards / this.cardsPerPage);
    this.overflowWidth = `calc(${this.totalPages * 100}% + ${this.totalPages * 10}px)`;
    this.cardWidth = `calc((${100 / this.totalPages}% - ${this.cardsPerPage * 10}px) / ${this.cardsPerPage})`;
  }

  getCardsPerPage() {
    return Math.floor(this.container?.nativeElement.offsetWidth / 200);
  }

  changePage(incrementor: number) {
    this.currentPage += incrementor;
    this.populatePagePosition();
  }

  populatePagePosition() {
    this.pagePosition = `calc(${-100 * (this.currentPage - 1)}% - ${10 * (this.currentPage - 1)}px)`;
  }

  disableCarouselDivs() {
    const carouselDivs = document.getElementsByClassName('card-list');

    for (let i = 0; i < carouselDivs.length; i++) {
      carouselDivs[i].classList.add('card-list-disabled');
    }
  }
  // Dynamic carousel of cards ==================================================

  // Show the image in full size when clicked ===================================
  showFullSizeImg(img: any) {
    this.previewImageSrc = img;
    this.previewImage.nativeElement.style.display = 'block';
  }

  closePreview() {
    this.previewImage.nativeElement.style.display = 'none';
  }
  // Show the image in full size when clicked ===================================

  imageLoaded() {
    this.scrollToBottom();
  }

  onMessageChange() {
    window.SendUserInteraction("inputEvent");
  }

  // Detect changes and scroll to the bottom of the chat
  scrollToBottom() {
    this.cdRef.detectChanges();
    const msgContainerChildren = this.messagesList.nativeElement.children
    const btnContainer = msgContainerChildren[msgContainerChildren.length - 1]
    const chatElement = this.chat.nativeElement;
    chatElement.scrollTo({ top: chatElement.scrollHeight - btnContainer.clientHeight - 30, behavior: 'smooth' });
  }
  //#region File attachment
  checkFileButton(): boolean {
    return this.nlpType === 'nlpExterno' && this.fileAttachment && !this.fileName && !this.iconSendMessageStatus;
  }

  dropFileAttachment(event: DragEvent){
    event.preventDefault();
    event.stopPropagation();
    if(!event.dataTransfer?.files) return;
    let files = Array.from(event.dataTransfer.files).filter((file: File) => {
      return allowedFileTypes.includes(file.type as FileTypes);
    });

    if(files.length === 0) {
      this.genericModalService.showInvalidFormatModal();
      return;
    }
    files = files.filter(file => file.size < this.maxFileSize);
    if(files.length === 0) {
      this.genericModalService.showMaxSizeWarningModal();
      return;
    }
    this.convertFileToBase64(files);
  }

  onDragOver(event: DragEvent){
    event.preventDefault();
  }

  onFileSelected(event: Event){
    const input = event.target as HTMLInputElement;
    if(!input.files || input.files.length === 0) return;
    const files = Array.from(input.files).filter(file => file.size < this.maxFileSize);
    if(files.length === 0) {
      this.genericModalService.showMaxSizeWarningModal();
      return;
    }
    this.convertFileToBase64(files);
  }

  convertFileToBase64(files: File[]){
    this.fileAttached = [];
    this.fileAttached.push(...files);
    this.fileName = files[0].name.split('.')[0];
    this.fileType = files[0].type;
    this.fileExtension = this.fileType.split('/')[1];
    const reader = new FileReader();
    
    reader.onload = () => {
      if(!reader.result) return;
      this.completeBase64File = reader.result as string;
      this.fileAttachedBase64 = this.completeBase64File.split(',')[1];
    }
    reader.readAsDataURL(this.fileAttached[0]);
  }

  private sendFile(){
    this.fileID++;
    const fileToSend: FileDTO = {
      id: this.fileID.toString(),
      fileName: this.fileName,
      base64: this.fileAttachedBase64,
      type: this.fileType as FileTypes,
      extension: this.fileExtension as FileExtensions
    };
    if(this.fileType.split('/')[0] === 'image' && this.fileType.split('/')[1] !== 'heif') this.addUserMessage(this.fileName, [], undefined, this.completeBase64File);
    else if(this.fileType.split('/')[0] === 'video') this.addUserMessage(this.fileName, [], undefined, undefined, this.completeBase64File);
    else this.addUserMessage(this.fileName);
    this.audioControllerService.playAudio('/assets/audio/button-click.mp3');
    this.queueProcessor.clearQueue();
    this.useCasesService.setMicState(3);
    this.queueProcessor.clearMicrophoneActions();

    this.webSocketService.SendMessageThroughSocket('', fileToSend);
    this.userMessage = '';
    this.showDotsLoading = true;
    this.disableButtons();
    this.scrollToBottom();

    this.useCasesService.setVirtualKeyboardState(false);
    this.useCasesService.setVirtualNumericKeyboardState(false);
    this.clearFile();
  }

  clearFile(){
    this.fileName = '';
    this.fileAttachedBase64 = '';
    this.fileType = '';
    this.fileExtension = '';
  }

  //#endregion
  private addUserMessage(message: string, detail = [], icon = undefined, image = '', video = '') {
    const userMessage: any = {
      "text": message,
      "from": "user",
      "detail": detail,
      "userImage": image,
      "userVideo": video
    };
    if (icon) {
      userMessage.icon = icon;
    }
    this.messagesChat.push(userMessage);
    this.userMessageCount++;
    this.onUserMessageAdded.emit(this.userMessageCount);
    this.assitantMessageIndex = 0;
  }

  private processFlowLocation(buttonName: string) {
    if (this.backWords[this.currentLanguage].some(word => buttonName.toLowerCase().includes(word))) {
      this.locationFlow.pop();
      return;
    }
    this.locationFlow.push(buttonName);
  }

  ngOnDestroy(): void {
    this.subscriptions$.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  //#region date picker
  sendDate(date: string){
    this.audioControllerService.playAudio('/assets/audio/button-click.mp3');

    this.addUserMessage(date);
    this.queueProcessor.clearQueue();
    this.useCasesService.setMicState(3);
    this.useCasesService.setVirtualNumericKeyboardState(false);
    this.queueProcessor.clearMicrophoneActions();
    this.webSocketService.SendMessageThroughSocket(date);
    this.iconSendMessageStatus = false;
    this.showDotsLoading = true;
    this.scrollToBottom();
  }
  //#endregion

  applyBorderToButtons(){
    const styles = getComputedStyle(document.documentElement);
    const var1 = styles.getPropertyValue('--dynamic-button-text-color');
    const var2 = styles.getPropertyValue('--background-color');
    return var1 !== var2 ? {'border-color:': var1} : {'border': 'none'}
  }
}

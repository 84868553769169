import * as THREE from "three";

export interface BujaSize {
    scale: THREE.Vector3;
    position: THREE.Vector3;
    movementOffset: number;
}

export const getSize = (width: number, height: number): BujaSize | undefined => {
    const sizeRerturn = {} as BujaSize;
    const isLandscape = width > height ? true : false;
    const aspectRatio = width / height;
    const heightBound = 4.5
    const screenToEngineRescale = height / heightBound
    const scaleToFitHeight = 1.45
    sizeRerturn.position = new THREE.Vector3()
    if (isLandscape) {
        sizeRerturn.position.x = - width / screenToEngineRescale * 0.5
        sizeRerturn.scale = new THREE.Vector3().setScalar(aspectRatio * scaleToFitHeight * 0.25)
        sizeRerturn.movementOffset = 0.005
    } else {
        sizeRerturn.position.y = height / screenToEngineRescale * 0.65
        sizeRerturn.scale = new THREE.Vector3().setScalar(scaleToFitHeight * 0.25)
        sizeRerturn.movementOffset = 0.0015
    }
    return sizeRerturn;
}
<div class="monthpicker-container">
    <div class="month-picker">
        <span class="month-title"><b>{{'dates.startDate' | translate}}</b></span>
        <div class="dropdown-container mb-4">
            <div class="d-flex flex-column month-dropdown-container">
                <select class="month-dropdown form-select mt-2" name="selectedYear" [(ngModel)]="selectedStartYear">
                    <option [ngValue]="null" disabled selected>{{'dates.year' | translate}}</option>
                    <option [ngValue]="year" *ngFor="let year of years">{{year}}</option>
                </select>
                <small *ngIf="!selectedStartYear" class="d-block text-danger">{{'dates.errors.yearSelect' | translate}}</small>
            </div>
            <div class="d-flex flex-column month-dropdown-container">
                <select class="month-dropdown form-select mt-2" name="selectedMonth" [(ngModel)]="selectedStartMonth">
                    <option [ngValue]="null" disabled selected>{{'dates.month' | translate}}</option>
                    <option [ngValue]="month" *ngFor="let month of months">{{month}}</option>
                </select>
                <small *ngIf="!selectedStartMonth" class="d-block text-danger text-invalid">{{'dates.errors.monthSelect' | translate}}</small>
            </div>
        </div>
        <span class="month-title"><b>{{'dates.endDate' | translate}}</b></span>
        <div class="dropdown-container mb-1">
            <div class="d-flex flex-column month-dropdown-container">
                <select class="month-dropdown form-select mt-2" name="selectedYear" [(ngModel)]="selectedEndYear">
                    <option [ngValue]="null" disabled selected>{{'dates.year' | translate}}</option>
                    <option [ngValue]="year" *ngFor="let year of years">{{year}}</option>
                </select>
                <small *ngIf="!selectedEndYear" class="d-block text-danger">{{'dates.errors.yearSelect' | translate}}</small>
            </div>

            <div class="d-flex flex-column month-dropdown-container">
                <select class="month-dropdown form-select mt-2" name="selectedMonth" [(ngModel)]="selectedEndMonth">
                    <option [ngValue]="null" disabled selected>{{'dates.month' | translate}}</option>
                    <option [ngValue]="month" *ngFor="let month of months">{{month}}</option>
                </select>
                <small *ngIf="!selectedEndMonth" class="d-block text-danger text-invalid">{{'dates.errors.monthSelect' | translate}}</small>
            </div>
        </div>
        <small *ngIf="!checkDateRange()" class="d-block text-danger text-invalid">{{'dates.errors.rangeError' | translate}}</small>
    </div>

    <button class="btn dynamic-button g-col-6" [disabled]="validateDatePicked() == false || checkDateRange() == false" (click)="choseDate()">{{'dates.confirm' | translate}}</button>
</div>
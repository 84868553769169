const materialPath = "../../../../../assets/threejs/models/materials/";
const standardPreset = "standard_material_preset.json";
const noOffsetMaterial = "no_offset_material_preset.json";
const brilliantMaterial = "brilliant_material_preset.json";

export const avatarMaterialDictionary: {[key: string]: string} = {
    "albert": `${materialPath}/${standardPreset}`,
    "nati_brinsa": `${materialPath}/${standardPreset}`,
    "nati_konekta": `${materialPath}/${standardPreset}`,
    "nati_threejs": `${materialPath}/${standardPreset}`,
    "neida_cenit": `${materialPath}/${standardPreset}`,
    "neida_chec": `${materialPath}/${standardPreset}`,
    "neida_threejs": `${materialPath}/${standardPreset}`,
    "neida2_threejs": `${materialPath}/${standardPreset}`,
    "paula": `${materialPath}/${noOffsetMaterial}`,
    "felipe": `${materialPath}/${brilliantMaterial}`,
    "ema_threejs": `${materialPath}/${brilliantMaterial}`,
    "neida_ocensa": `${materialPath}/${standardPreset}`
}
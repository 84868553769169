<div class="modal-body">
  <div class="service-unavailable-container">
      <div class="row m-0 row-container">
          <div class="col d-block m-auto">
              <div class="w-100 bg-nati-avatar">
                  <img src="../../../assets/images/nati-logo-nuevo.png" class="position-absolute top-0 m-3" alt="Nati" width="155" height="65">

                  <div class="text-center">
                      <img src="../../../assets/images/service-unavailable/nati.png" alt="Nati avatar" class="img-fluid avatar">
                  </div>

                  <div class="blur-container">
                      <div class="blur-text">
                          <h5>Plataforma Nati de <br><b>Personas digitales</b></h5>
                          <p class="mt-3">Inteligencia artificial para automatizar las conversaciones con los clientes.</p>
                      </div>
                  </div>
              </div>
          </div>

          <div class="col">
              <div class="w-75 d-block m-auto text-center">

                  <h4 class="text-center fw-bold">Se superó el número de licencias adquiridas</h4>

                  <hr class="line-unavailable">

                  <p class="service-unavailable-text">Actualmente no tiene licencias disponibles para activar.</p>
                  <p class="service-unavailable-text">Recomendamos revisar la cantidad de dispositivos activos o si lo requiere, solicite incremento del número de licencias disponibles en su suscripción.</p>

                  <hr class="line-unavailable">

                  <button type="button" class="btn btn-go-to-site" (click)="redirectToNati()">Ir al sitio web de Nati.ai <span class="arrow right"></span></button>
              </div>
          </div>
      </div>
  </div>
</div>

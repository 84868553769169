<div class="container-controls">
  <div class="controls">
    <div class="vol-slider">
      <input class="range vertical-lowest-first volume-slider" type="range" min="0" max="100" step="1" value="100"
          [(ngModel)]="volume" (ngModelChange)="onVolumeChange(volume)" [class.visible]="isSliderVisible"
          [@fadeInOut]="isSliderVisible ? 'visible' : 'void'">
    </div>
    <div class="volume-control" (mouseenter)="showSlider()" (mouseleave)="hideSlider()" *ngIf="showVolume">
      
      <svg xmlns="http://www.w3.org/2000/svg" class="icons-ui volume-icon" viewBox="0 0 24 24" [appSafeHtml]="imgVolume"
        (click)="volumeMute()"></svg>
    </div>

    <div class="btn-tooltip" *ngIf="isTodosFlow && showRestart">
      <button class="home-button shadow-sm" (click)="goHome()">
        <svg xmlns="http://www.w3.org/2000/svg" class="icons-ui" width="24" height="24" viewBox="0 0 24 24">
          <path
            d="M10 11H7.101l.001-.009a4.956 4.956 0 0 1 .752-1.787 5.054 5.054 0 0 1 2.2-1.811c.302-.128.617-.226.938-.291a5.078 5.078 0 0 1 2.018 0 4.978 4.978 0 0 1 2.525 1.361l1.416-1.412a7.036 7.036 0 0 0-2.224-1.501 6.921 6.921 0 0 0-1.315-.408 7.079 7.079 0 0 0-2.819 0 6.94 6.94 0 0 0-1.316.409 7.04 7.04 0 0 0-3.08 2.534 6.978 6.978 0 0 0-1.054 2.505c-.028.135-.043.273-.063.41H2l4 4 4-4zm4 2h2.899l-.001.008a4.976 4.976 0 0 1-2.103 3.138 4.943 4.943 0 0 1-1.787.752 5.073 5.073 0 0 1-2.017 0 4.956 4.956 0 0 1-1.787-.752 5.072 5.072 0 0 1-.74-.61L7.05 16.95a7.032 7.032 0 0 0 2.225 1.5c.424.18.867.317 1.315.408a7.07 7.07 0 0 0 2.818 0 7.031 7.031 0 0 0 4.395-2.945 6.974 6.974 0 0 0 1.053-2.503c.027-.135.043-.273.063-.41H22l-4-4-4 4z">
          </path>
        </svg>
      </button>
      <div class="tooltip-custom tooltip-up-custom">Reiniciar</div>
    </div>

    <div class="btn-tooltip" *ngIf="!isTodosFlow && showRestart">
      <button class="home-button shadow-sm" (click)="refreshConversation()">
        <svg xmlns="http://www.w3.org/2000/svg" class="icons-ui" width="24" height="24" viewBox="0 0 24 24">
          <path
            d="M10 11H7.101l.001-.009a4.956 4.956 0 0 1 .752-1.787 5.054 5.054 0 0 1 2.2-1.811c.302-.128.617-.226.938-.291a5.078 5.078 0 0 1 2.018 0 4.978 4.978 0 0 1 2.525 1.361l1.416-1.412a7.036 7.036 0 0 0-2.224-1.501 6.921 6.921 0 0 0-1.315-.408 7.079 7.079 0 0 0-2.819 0 6.94 6.94 0 0 0-1.316.409 7.04 7.04 0 0 0-3.08 2.534 6.978 6.978 0 0 0-1.054 2.505c-.028.135-.043.273-.063.41H2l4 4 4-4zm4 2h2.899l-.001.008a4.976 4.976 0 0 1-2.103 3.138 4.943 4.943 0 0 1-1.787.752 5.073 5.073 0 0 1-2.017 0 4.956 4.956 0 0 1-1.787-.752 5.072 5.072 0 0 1-.74-.61L7.05 16.95a7.032 7.032 0 0 0 2.225 1.5c.424.18.867.317 1.315.408a7.07 7.07 0 0 0 2.818 0 7.031 7.031 0 0 0 4.395-2.945 6.974 6.974 0 0 0 1.053-2.503c.027-.135.043-.273.063-.41H22l-4-4-4 4z">
          </path>
        </svg>
      </button>
      <div class="tooltip-custom tooltip-up-custom">Reiniciar</div>
    </div>

    <div class="btn-tooltip exit-button" *ngIf="showExit">
      <button class="home-button shadow-sm" (click)="redirectTo()">
        <svg xmlns="http://www.w3.org/2000/svg" class="icons-ui" width="24" height="24" viewBox="0 0 24 24">
          <path d="M16 13v-2H7V8l-5 4 5 4v-3z"></path>
          <path
            d="M20 3h-9c-1.103 0-2 .897-2 2v4h2V5h9v14h-9v-4H9v4c0 1.103.897 2 2 2h9c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2z">
          </path>
        </svg>
      </button>
      <div class="tooltip-custom tooltip-up-custom">Salir</div>
    </div>

    <div class="btn-tooltip" *ngIf="showDownload && ableToDownloadConversation">
      <button class="home-button shadow-sm" (click)="downloadConversation()">
        <svg xmlns="http://www.w3.org/2000/svg" class="icons-ui" width="24" height="24" viewBox="0 0 24 24">
          <path
            d="M18.948 11.112C18.511 7.67 15.563 5 12.004 5c-2.756 0-5.15 1.611-6.243 4.15-2.148.642-3.757 2.67-3.757 4.85 0 2.757 2.243 5 5 5h1v-2h-1c-1.654 0-3-1.346-3-3 0-1.404 1.199-2.757 2.673-3.016l.581-.102.192-.558C8.153 8.273 9.898 7 12.004 7c2.757 0 5 2.243 5 5v1h1c1.103 0 2 .897 2 2s-.897 2-2 2h-2v2h2c2.206 0 4-1.794 4-4a4.008 4.008 0 0 0-3.056-3.888z">
          </path>
          <path d="M13.004 14v-4h-2v4h-3l4 5 4-5z"></path>
        </svg>
      </button>
      <div class="tooltip-custom tooltip-up-custom">Descargar</div>
    </div>

    <div class="btn-tooltip" *ngIf="showWidgetButtons">
      <button class="home-button shadow-sm widgetButton" (click)="minimizeWidget()">
        <svg width="24" height="24" viewBox="0 0 24 24" class="icons-ui-stroke" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 12H19" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>                    
      </button>
      <div class="tooltip-custom tooltip-up-custom">Minimizar</div>
    </div>

    <div class="btn-tooltip" *ngIf="showWidgetButtons">
      <button class="home-button shadow-sm widgetButton" (click)="closeWidget()">
        <svg width="24" height="24" viewBox="0 0 24 24" class="icons-ui"  fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.21967 6.21967C6.51256 5.92678 6.98744 5.92678 7.28033 6.21967L17.7803 16.7197C18.0732 17.0126 18.0732 17.4874 17.7803 17.7803C17.4874 18.0732 17.0126 18.0732 16.7197 17.7803L6.21967 7.28033C5.92678 6.98744 5.92678 6.51256 6.21967 6.21967Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M17.7803 6.21967C18.0732 6.51256 18.0732 6.98744 17.7803 7.28033L7.28033 17.7803C6.98744 18.0732 6.51256 18.0732 6.21967 17.7803C5.92678 17.4874 5.92678 17.0126 6.21967 16.7197L16.7197 6.21967C17.0126 5.92678 17.4874 5.92678 17.7803 6.21967Z"/>
        </svg>
      </button>
      <div class="tooltip-custom tooltip-up-custom">Cerrar</div>
    </div>
  </div>
</div>
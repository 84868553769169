import * as THREE from 'three';

export const blendShapeDictionary: {[key: string]: number} = {
    "Eye_Squint_L": 5,
    "Eye_Wide_L": 6,
    "Eye_Squint_R": 12,
    "Eye_Wide_R": 13,
    "Jaw_Forward": 14,
    "Jaw_L": 15,
    "Jaw_R": 16,
    "Jaw_Open": 17,
    "Mouth_Close": 18,
    "Mouth_Funnel_Up_L": 19,
    "Mouth_Funnel_Up_R": 19,
    "Mouth_Funnel_Down_L": 19,
    "Mouth_Funnel_Down_R": 19,
    "Mouth_Pucker_Up_L": 20,
    "Mouth_Pucker_Up_R": 20,
    "Mouth_Pucker_Down_L": 20,
    "Mouth_Pucker_Down_R": 20,
    "Mouth_L": 21,
    "Mouth_R": 22,
    "Mouth_Smile_L": 23,
    "Mouth_Smile_R": 24,
    "Mouth_Frown_L": 25,
    "Mouth_Frown_R": 26,
    "Mouth_Stretch_L": 29,
    "Mouth_Stretch_R": 30,
    "Mouth_Roll_In_Lower_L": 31,
    "Mouth_Roll_In_Lower_R": 31,
    "Mouth_Roll_In_Upper_L": 32,
    "Mouth_Roll_In_Upper_R": 32,
    "Mouth_Shrug_Lower": 33,
    "Mouth_Shrug_Upper": 34,
    "Mouth_Press_L": 35,
    "Mouth_Press_R": 36,
    "Mouth_Down_Lower_L": 37,
    "Mouth_Down_Lower_R": 38,
    "Mouth_Up_Upper_L": 39,
    "Mouth_Up_Upper_R": 40,
    "Brow_Drop_L": 41,
    "Brow_Drop_R": 42,
    "Brow_Raise_Inner_L": 43,
    "Brow_Raise_Inner_R": 43,
    "Brow_Raise_Outer_L": 44,
    "Brow_Raise_Outer_R": 45,
    "Cheek_Puff_L": 46,
    "Cheek_Puff_R": 46,
    "Cheek_Raise_L": 47,
    "Cheek_Raise_R": 48,
    "Nose_Sneer_L": 49,
    "Nose_Sneer_R": 50
}

export const characterIndexToAzureIndex = (skinnedMesh: THREE.SkinnedMesh): Map<number, number> => {
    const characterIndexToAzureIndexDict = new Map();
    const characterNameToAzureIndex = blendShapeDictionary;

    for (const key in characterNameToAzureIndex) {
        const value = characterNameToAzureIndex[key];
        if (skinnedMesh.morphTargetDictionary) {
            const index: number = skinnedMesh.morphTargetDictionary[key];
            if (index !== undefined) {
                characterIndexToAzureIndexDict.set(index, value);
            }
        }         
    }

    return characterIndexToAzureIndexDict;
    
}
import { Injectable } from '@angular/core';
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
import { UseCasesService } from './use-cases.service';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TranslatorService } from '../translator.service';

declare global {
  interface Window { totalSTT_Time: number; }
  interface Window { totalTTS_Time: number; }
}

window.totalSTT_Time = 0;
window.totalTTS_Time = 0;

@Injectable({
  providedIn: 'root'
})
export class SpeechToTextService {

  private speechTextSubject = new Subject<string>(); // subject to emit processed messages
  private startRecognizing = new Subject<void>();
  speechConfig: any;
  audioConfig: any;
  recognizer: any;
  stoppedRecognizer = false;

  phraseList: any;

  constructor(private useCasesService: UseCasesService, private translatorService: TranslatorService) {
  }

  // Listener STT
  public initSTT() {
    this.speechConfig = SpeechSDK.SpeechConfig.fromSubscription(environment.azureCognitiveServices.apiKey, environment.azureCognitiveServices.region);
    this.speechConfig.speechRecognitionLanguage = this.translatorService.getRegionCode();
  }

  public updateRecognitionLanguage(language: string) {
    try {
      this.speechConfig.speechRecognitionLanguage = language;
    } catch (error) {
      return;
    }
  }

  public async startSTT() {
    const region = this.translatorService.getRegionCode();
    this.speechConfig.speechRecognitionLanguage = region.includes('zh') ? 'zh-CN' : region;
    if (this.recognizer == null) {
      this.audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
    }
    this.recognizer = new SpeechSDK.SpeechRecognizer(this.speechConfig, this.audioConfig);

    await this.recognizer.startContinuousRecognitionAsync();
    this.stoppedRecognizer = false;
    this.phraseList = SpeechSDK.PhraseListGrammar.fromRecognizer(this.recognizer);
    this.phraseList.addPhrase("Buja");
    this.phraseList.addPhrase("Comfama");

    this.recognizer.sessionStarted = () => {
      this.startRecognizing.next();
    }

    this.recognizer.recognizing = (s: any, e: any) => {
      if (e.result.text != null) {
        const text = e.result.text;
        if (text.length >= 512) {
          const duration = e.result.duration / 10000000;
          window.totalSTT_Time = duration;

          this.useCasesService.setMicState(0);
          this.recognizer.stopContinuousRecognitionAsync();
          this.speechTextSubject.next(text);
        }
      }
    };

    this.recognizer.recognized = async (s: any, e: any) => {
      if (this.stoppedRecognizer === true) {
        this.stoppedRecognizer = false;
      } else {
        if (e.result.text != null) {
          const text = e.result.text;
          const duration = e.result.duration / 10000000;
          window.totalSTT_Time = duration;

          this.useCasesService.setMicState(0);
          await this.recognizer.stopContinuousRecognitionAsync();
          this.speechTextSubject.next(text);
        }

        this.stoppedRecognizer = false;
      }
    };
  }

  public stopSTT() {
    if (this.recognizer) {
      this.stoppedRecognizer = true;
      this.recognizer.stopContinuousRecognitionAsync();
    }
  }

  // Observable to know when the speech has been recognized by the STT
  public getRecognizedSpeech(): Observable<any> {
    return this.speechTextSubject.asObservable();
  }

  public getStartRecognizing(): Observable<void>{
    return this.startRecognizing.asObservable();
  }
}

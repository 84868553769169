import { Injectable } from '@angular/core';
import * as THREE from 'three';

@Injectable({
  providedIn: 'root'
})
export class ThreeClockService {

  public clock: THREE.Clock;

  constructor() {
    this.clock = new THREE.Clock();
  }
}

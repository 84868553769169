import { Component, OnInit, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { UseCasesService } from 'src/app/services/chat/use-cases.service';

@Component({
  selector: 'app-keyboard',
  templateUrl: './keyboard.component.html',
  styleUrls: ['./keyboard.component.scss']
})
export class KeyboardComponent implements OnInit {
  elements!: {
    main: any;
    keysContainer: any;
    keys: any[];
  };

  eventHandlers!: {
    oninput: any;
    onclose: any;
    [key: string]: any;
  };

  properties!: {
    value: string;
    capsLock: boolean;
    typedText: string;
  };

  activeInput: any;

  @ViewChild('containerKeyboard', { static: true }) containerKeyboard!: ElementRef;
  @ViewChild('inputKeyboard', { static: true }) inputKeyboard!: ElementRef;

  constructor(private renderer: Renderer2, private useCasesService: UseCasesService) { }

  ngOnInit() {
    this.elements = {
      main: null,
      keysContainer: null,
      keys: []
    };

    this.eventHandlers = {
      oninput: null,
      onclose: null
    };

    this.properties = {
      value: "",
      capsLock: false,
      typedText: ""
    };

    this.initKeyboard();

    // Subscribe to the document for click events to get the active input
    document.addEventListener('click', (event) => {
      const targetElement = event.target as HTMLElement;

      if (targetElement.tagName === 'INPUT') {
        this.activeInput = targetElement;
        this.properties.value = this.activeInput.value;

        if (this.activeInput.id === 'chat-input') {
          this.useCasesService.setVirtualKeyboardMessage(this.properties.value);
        }
      }
    });
  }

  private initKeyboard() {
    this.elements.main = this.renderer.createElement('div');
    this.elements.keysContainer = this.renderer.createElement('div');

    this.renderer.addClass(this.elements.main, 'keyboard');
    this.renderer.addClass(this.elements.keysContainer, 'keyboard__keys');

    this.renderer.appendChild(this.elements.keysContainer, this.createKeys());

    this.elements.keys = this.elements.keysContainer.querySelectorAll('.keyboard__key');

    this.renderer.appendChild(this.elements.main, this.elements.keysContainer);
    this.renderer.appendChild(this.containerKeyboard.nativeElement, this.elements.main);

    this.eventHandlers.oninput = (value: string) => {
      // this.properties.typedText = value;
      // this.useCasesService.setVirtualKeyboardMessage(this.properties.typedText);
    };
  }

  private createIconHTML(iconName: string): string {
    return `<i class="material-icons">${iconName}</i>`;
  }

  private createKeys(): DocumentFragment {
    const fragment = document.createDocumentFragment();
    const keyLayout = [
      "1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "backspace",
      "q", "w", "e", "r", "t", "y", "u", "i", "o", "p", "@",
      "caps", "a", "s", "d", "f", "g", "h", "j", "k", "l", "ñ",
      "#", "z", "x", "c", "v", "b", "n", "m", ",", ".", "?",
      "space"
    ];

    keyLayout.forEach(key => {
      const keyElement = document.createElement("button");
      const insertLineBreak = ["backspace", "@", "ñ", "?"].indexOf(key) !== -1;

      keyElement.setAttribute("type", "button");
      keyElement.classList.add("keyboard__key");

      switch (key) {
        case "backspace":
          keyElement.classList.add("keyboard__key--wide");
          keyElement.innerHTML = this.createIconHTML("backspace");

          keyElement.addEventListener("click", () => {
            this.properties.value = this.properties.value.substring(0, this.properties.value.length - 1);
            this.triggerEvent("oninput");
          });
          break;
        case "caps":
          keyElement.classList.add("keyboard__key--wide", "keyboard__key--activatable");
          keyElement.innerHTML = this.createIconHTML("keyboard_capslock");

          keyElement.addEventListener("click", () => {
            this.toggleCapsLock();
            keyElement.classList.toggle("keyboard__key--active", this.properties.capsLock);
          });
          break;
        case "space":
          keyElement.classList.add("keyboard__key--extra-wide");
          keyElement.innerHTML = this.createIconHTML("space_bar");

          keyElement.addEventListener("click", () => {
            this.properties.value += " ";
            this.triggerEvent("oninput");
          });
          break;
        default:
          keyElement.textContent = key.toLowerCase();

          keyElement.addEventListener("click", () => {
            this.properties.value += this.properties.capsLock ? key.toUpperCase() : key.toLowerCase();
            this.triggerEvent("oninput");
          });
          break;
      }

      fragment.appendChild(keyElement);

      if (insertLineBreak) {
        fragment.appendChild(document.createElement("br"));
      }
    });

    return fragment;
  }

  private triggerEvent(handlerName: string) {

    if (typeof this.eventHandlers[handlerName] == "function") {
      this.eventHandlers[handlerName](this.properties.value);

      // Update the input value
      if (this.activeInput) {
        this.renderer.setProperty(this.activeInput, 'value', this.properties.value);
        this.activeInput.dispatchEvent(new Event('input'));
      }
    }
  }

  private toggleCapsLock() {
    this.properties.capsLock = !this.properties.capsLock;

    for (const key of this.elements.keys) {
      if (key.childElementCount === 0) {
        key.textContent = this.properties.capsLock ? key.textContent.toUpperCase() : key.textContent.toLowerCase();
      }
    }
  }

  open(initialValue: string, oninput: (value: string) => void, onclose: () => void) {
    this.properties.value = initialValue || "";
    this.eventHandlers.oninput = oninput;
    this.eventHandlers.onclose = onclose;
  }

  close() {
    this.properties.value = "";
    this.eventHandlers.oninput = oninput;
    this.eventHandlers.onclose = onclose;
  }
}

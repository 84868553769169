<ng-template #pdfModal>
  <div class="modal-header">
    <div class="pdf-viewer-title-container">
      <h3 class="pdf-viewer-title">{{'ui.pdfViewer.title' | translate}}</h3>
    </div>
  </div>

  <div class="modal-body">
    <div class="modal-view-container" *ngIf="showPdf" [@fadeIn]="modalStatus" [@fadeOut]="modalStatus">
      <div class="pdf-viewer">
        <pdf-viewer [src]="pdfUrl" [rotation]="0" [original-size]="false" [show-all]="true" [fit-to-page]="false"
          [zoom]="1.4" [stick-to-page]="false" [render-text]="true" [external-link-target]="'blank'" [autoresize]="true"
          [show-borders]="false" [original-size]="true" style="width: 100%; height: 100%;"></pdf-viewer>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="print-buttons">
      <button id="cancelButton" class="btn print-button" (click)="cancel()">{{'ui.pdfViewer.cancel' |
        translate}}</button>
      <button id="printButton" class="btn print-button" (click)="printDocument()">{{'ui.pdfViewer.print' |
        translate}}</button>
    </div>
  </div>
</ng-template>
<!-- <div class="modal-bg" *ngIf="showPdf"></div> -->
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Swal, { SweetAlertIcon, SweetAlertResult } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class GenericModalService {

  constructor(private translateService: TranslateService) {}

  showSwalModal(titulo: string, texto: string, icono: SweetAlertIcon, colorIcono?: string, timer?: number,
    barraTimer?: boolean, textoConfirmar?: string, mostrarBotonCancelar?: boolean, textoCancelar?: string,
    mostrarBotonAceptar?: boolean): Promise<SweetAlertResult<any>> {
    return Swal.fire({
      customClass: {
        confirmButton: 'swalConfirmBtn',
        cancelButton: 'swalCancelBtn'
      },
      title: titulo,
      text: texto,
      icon: icono,
      ...(colorIcono && { iconColor: colorIcono }),
      ...(timer && { timer: timer }),
      ...(barraTimer && { timerProgressBar: barraTimer }),
      ...(textoConfirmar && { confirmButtonText: textoConfirmar }),
      ...(mostrarBotonCancelar && { showCancelButton: mostrarBotonCancelar }),
      ...(textoCancelar && { cancelButtonText: textoCancelar }),
      ...(mostrarBotonAceptar && { showConfirmButton: mostrarBotonAceptar })
    });
  }

  showMaxSizeWarningModal(){
    const title = this.translateService.instant('modal.maxSize.title');
    const text = this.translateService.instant('modal.maxSize.text');
    const acceptText = this.translateService.instant('modal.accept');
    this.showSwalModal(title, text, 'warning', '#0E2359', 3000, true, acceptText);
  }

  showInvalidFormatModal(){
    const title = this.translateService.instant('modal.wrongExtension.title');
    const text = this.translateService.instant('modal.wrongExtension.text');
    const acceptText = this.translateService.instant('modal.accept');
    this.showSwalModal(title, text, 'warning', '#0E2359', 5000, true, acceptText);
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  private logs: string[] = [];

  addInfo(message: string) {
    this.logs.push(`[INFO] ${message}`);
  }

  addWarning(message: string) {
    this.logs.push(`[WARNING] ${message}`);
  }

  addError(message: string) {
    this.logs.push(`[ERROR] ${message}`);
  }

  getLogs(): string[] {
    return this.logs;
  }

  setupConsoleLogging() {
    const originalError = console.error;
    const originalWarn = console.warn;
    const originalLog = console.log;

    console.error = (...args: any[]) => {
      this.addError(args.join(' '));
      originalError.apply(console, args);
    };

    console.warn = (...args: any[]) => {
      this.addWarning(args.join(' '));
      originalWarn.apply(console, args);
    };

    console.log = (...args: any[]) => {
      this.addInfo(args.join(' '));
      originalLog.apply(console, args);
    };
  }
}

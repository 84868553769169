import { Injectable } from '@angular/core';
import { EngineService } from './engine.service';
import { Params } from '@angular/router';
import { environment } from 'src/environments/environment';
import UnityWebgl from 'unity-webgl';
import { AvatarInteractionsService } from '../avatar/avatar-interactions.service';
import { BundleDownloader } from '../bundle-downloader.service';
import { UnityControllerService } from '../unity-controller.service';
import { Data, TenantData } from 'src/app/models/tenant-data.model';
import { Subject } from 'rxjs';

declare global {
  interface Window { InitUnityController: () => void; }
  interface Window { SetUnityUndefined: () => void; }
}

@Injectable({
  providedIn: 'root'
})
export class LoadUnityService {

  UnityInstance: any;
  progress = 0;
  // Size of the BuildWebGL files in MB and the total downloaded size in MB
  buildWebglSize = 59.00;
  totalDownloadedMB = 0;
  // Time and bytes used to calculate the download speed
  startTime = Date.now();
  startBytes = 0;
  downloadSpeed = 0;
  downloadSpeedUnit = 'Kb/s';
  lowDownloadSpeed = new Subject<boolean>();

  downloadSpeedTimer: any;

  // Toast variables to show a toast if the download speed is less than 300kb/s
  toastShown = false;
  minToastTime = 5000; // 5 seconds
  isIos!: boolean;
  bundleDecompressedStatus = false;
  personaDigitalData!: Data;

  constructor(
    private bundleDownloader: BundleDownloader,
    private unityControllerService: UnityControllerService) { }

  setIsIos(isIOS: boolean) {
    this.isIos = isIOS
  }

  setPersonaDigitalData(data: Data) {
    this.personaDigitalData = data;
  }

  getLowDownloadObservable(){
    return this.lowDownloadSpeed.asObservable();
  }

  loadUnityInstance() {
    this.UnityInstance = this.createUnityInstance()
      .on('progress', this.unityOnProgress)
      .on('mounted', this.unityOnMounted())
      .on('error', () => {
        console.error('Ha ocurrido un error en la aplicación de Unity', Error);
      }).on('crash', () => {
        console.error('La aplicación de Unity ha crasheado');
      }).on('log', (message: any) => {
        console.error('Mensaje de la aplicación de Unity: ', message);
      });
  }

  private createUnityInstance() {
    return new UnityWebgl('#canvas', {
      loaderUrl: environment.build.loaderUrl,
      dataUrl: environment.build.dataUrl,
      frameworkUrl: environment.build.frameworkUrl,
      codeUrl: environment.build.codeUrl,

      streamingAssetsUrl: environment.build.streamingAssetsUrl,
      companyName: "Innovati Software",
      productName: "Nati",
      productVersion: "1.0.0",
      webglContextAttributes: {
        alpha: true,
        antialias: true,
        failIfMajorPerformanceCaveat: true,
        powerPreference: "default",
        preserveDrawingBuffer: true,
        stencil: true,
        desynchronized: true,
        xrCompatible: false
      }
    });
  }

  unityOnProgress = (progress: any) => {
    const percent = Math.round(progress * 100);
    this.progress = percent;

    // Calculate the total downloaded size in MB
    const totalDownloadedBytes = Math.round(this.buildWebglSize * progress * 1024 * 1024);
    this.totalDownloadedMB = Math.round(totalDownloadedBytes / 1024 / 1024 * 100) / 100;

    // Calculate the download speed in Kb/s or Mb/s
    const currentTime = Date.now();
    const currentBytes = totalDownloadedBytes;
    const elapsedTimeInSeconds = (currentTime - this.startTime) / 1000;

    if (elapsedTimeInSeconds > 0) {
      const bytesPerSecond = (currentBytes - this.startBytes) / elapsedTimeInSeconds;
      this.downloadSpeed = Math.round(bytesPerSecond / 1024 * 100) / 100;

      if (this.downloadSpeed >= 1024) {
        this.downloadSpeed = Math.round(this.downloadSpeed / 1024 * 100) / 100;
        this.downloadSpeedUnit = 'Mb/s';
      } else {
        if (this.downloadSpeed < 300 && !this.toastShown && elapsedTimeInSeconds >= this.minToastTime / 1000) {
          this.lowDownloadSpeed.next(true);

          this.toastShown = true;
          setTimeout(() => {
            this.lowDownloadSpeed.next(false);
          }, 7000);
        }

        this.downloadSpeedUnit = 'Kb/s';
      }
    }

    // Use a timer to update the download speed every 700 ms
    clearTimeout(this.downloadSpeedTimer);
    this.downloadSpeedTimer = setTimeout(() => {

      this.startBytes = currentBytes;
      this.startTime = currentTime;
    }, 700);
  }

  unityOnMounted() {
    return () => {
      (window as any).UnityInstance = this.UnityInstance;
      const tenantDataString = JSON.stringify(this.personaDigitalData);


      window.InitUnityController();

      setTimeout(async () => {
        // Conexion con el websocket
        if (this.isIos) {
          this.UnityInstance.send('JSReceiver', 'SetIsiOS');
        }
        this.unityControllerService.sendTenantParams(tenantDataString);

        await this.processWhileDecompressing();
      }, 2000);

      this.unityControllerService.setUnityInstance(this.UnityInstance);

      setTimeout(() => {
        this.unityControllerService.sendOrientation((window.matchMedia("(orientation: landscape)").matches) ? 0 : 1);
        this.UnityInstance.send('JSReceiver', 'SetOrientation', (window.matchMedia("(orientation: landscape)").matches) ? 0 : 1);
      }, 2200);
    }
  }
  // Proceso que se ejecuta mientras se descomprime el bundle para validar que se haya descomprimido
  async processWhileDecompressing() {
    while (!this.bundleDecompressedStatus) {
      await new Promise((resolve) => setTimeout(resolve, 500));
    }

    if (this.bundleDecompressedStatus) {
      this.unityControllerService.sendBundleURL(this.bundleDownloader.getUrl());
      this.unityControllerService.sendBundleName(this.bundleDownloader.getFileName());
    }
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-vertical-ui-background',
  templateUrl: './vertical-ui-background.component.html',
  styleUrls: ['./vertical-ui-background.component.scss']
})
export class VerticalUiBackgroundComponent {

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { decompress } from 'brotli-compress';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})

export class BundleDownloader {
  private avatarName: Subject<string> = new Subject<string>();

  private blobUrl = '';
  private fileName = '';
  private statusDecompress$: Subject<boolean> = new Subject<boolean>();
  private avatarBlob!: Blob;

  constructor(private http: HttpClient) {

  }

  public getBundleLink(avatarId: string): Observable<any> {
    return this.http.get(environment.transversales.urlLinkAvatar + avatarId, { responseType: 'text' });
  }

  public downloadFile(url: string): Observable<any> {
    this.fileName = this.getFileNameFromUrl(url);
    this.avatarName.next(this.fileName.split(".")[0]);
    return this.http.get(url, { responseType: 'arraybuffer' });
  }

  public async decompressFile(data: ArrayBuffer): Promise<Observable<string>> {
    this.setStatusDecompress(false);

    const uint8Array = new Uint8Array(data);
    const decompressInput = await decompress(uint8Array);
    this.avatarBlob = new Blob([decompressInput], { type: 'application/octet-stream' });
    this.blobUrl = window.URL.createObjectURL(this.avatarBlob);


    this.setStatusDecompress(true);
    return new Observable<string>(observer => {
      observer.next(this.blobUrl);
    });
  }

  public async setLinkBundle(url: string): Promise<Observable<string>> {
    this.setStatusDecompress(false);

    this.blobUrl = url;

    this.setStatusDecompress(true);
    return new Observable<string>(observer => {
      observer.next(this.blobUrl);
    });
  }

  public async setBundle(data: any) {
    const uint8Array = new Uint8Array(data);
    this.avatarBlob = new Blob([uint8Array], { type: 'application/octet-stream' });
    this.blobUrl = window.URL.createObjectURL(this.avatarBlob);
  }
  
  public async setThreeBundle(data: any) {
    this.setStatusDecompress(false);

    this.setBundle(data)

    this.setStatusDecompress(true);
    return new Observable<string>(observer => {
      observer.next(this.blobUrl);
    });
  }

  public getUrl(): string {
    return this.blobUrl;
  }

  public getBlob(): Blob {
    return this.avatarBlob;
  }

  public freeBlobUrl(): void {
    window.URL.revokeObjectURL(this.blobUrl);
    this.blobUrl = '';
  }

  public getFileName(): string {
    return this.fileName;
  }

  //Get the name of the file from de the url, getting rid of the path and the last .bundle.br
  public getFileNameFromUrl(url: string): string {
    const lastIndexOfSlash = url.lastIndexOf('/');
    const fileName = url.substring(lastIndexOfSlash + 1).replace('.bundle.br', '');
    return fileName;
  }

  public getStatusDecompress(): Observable<boolean> {
    return this.statusDecompress$.asObservable();
  }

  public getAvatarname(): Observable<string> {
    return this.avatarName.asObservable();
  }

  public setStatusDecompress(status: boolean): void {
    this.statusDecompress$.next(status);
  }
}

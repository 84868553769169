import { Component, Input, OnInit } from '@angular/core';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { UnityControllerService } from 'src/app/services/unity-controller.service';
import { AudioControllerService } from 'src/app/services/audio-controller.service';
import { QueueProcessorService } from 'src/app/services/chat/queue-processor.service';
import { TenantDataService } from 'src/app/services/tenant/tenant-data.service';
import { UseCasesService } from 'src/app/services/chat/use-cases.service';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { Observable } from 'rxjs';


export interface InterfazControles {
  urlRedireccion: string;
  botonDescargar: boolean;
  botonReiniciar: boolean;
  botonSalir: boolean;
  botonVolumen: boolean;
  botonMicrofono: boolean;
  botonCargarArchivo: boolean;
}

@Component({
  selector: 'app-controls-section',
  templateUrl: './controls-section.component.html',
  styleUrls: ['./controls-section.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition(':enter, :leave', animate('300ms ease-in-out')),
    ])
  ]
})

export class ControlsSectionComponent implements OnInit {

  volume = 50;
  imgVolume = '';
  isVolumeMuted = false;

  volumePathSvgFull = '<path d="M16 21c3.527-1.547 5.999-4.909 5.999-9S19.527 4.547 16 3v2c2.387 1.386 3.999 4.047 3.999 7S18.387 17.614 16 19v2z"/><path d="M16 7v10c1.225-1.1 2-3.229 2-5s-.775-3.9-2-5zM4 17h2.697l5.748 3.832a1.004 1.004 0 0 0 1.027.05A1 1 0 0 0 14 20V4a1 1 0 0 0-1.554-.832L6.697 7H4c-1.103 0-2 .897-2 2v6c0 1.103.897 2 2 2zm0-8h3c.033 0 .061-.016.093-.019a1.027 1.027 0 0 0 .38-.116c.026-.015.057-.017.082-.033L12 5.868v12.264l-4.445-2.964c-.025-.017-.056-.02-.082-.033a.986.986 0 0 0-.382-.116C7.059 15.016 7.032 15 7 15H4V9z"/>';
  volumePathSvgLow = '<path d="M4 17h2.697l5.748 3.832a1.004 1.004 0 0 0 1.027.05A1 1 0 0 0 14 20V4a1 1 0 0 0-1.554-.832L6.697 7H4c-1.103 0-2 .897-2 2v6c0 1.103.897 2 2 2zm0-8h3c.033 0 .061-.016.093-.019a1.027 1.027 0 0 0 .379-.116c.026-.014.057-.017.082-.033L12 5.868v12.264l-4.445-2.964c-.025-.018-.056-.02-.082-.033a.977.977 0 0 0-.382-.116C7.059 15.016 7.032 15 7 15H4V9zm12-2v10c1.225-1.1 2-3.229 2-5s-.775-3.9-2-5z"/>';
  volumePathSvgMute = '<path d="m21.707 20.293-2.023-2.023A9.566 9.566 0 0 0 21.999 12c0-4.091-2.472-7.453-5.999-9v2c2.387 1.386 3.999 4.047 3.999 7a8.113 8.113 0 0 1-1.672 4.913l-1.285-1.285C17.644 14.536 18 13.19 18 12c0-1.771-.775-3.9-2-5v7.586l-2-2V4a1 1 0 0 0-1.554-.832L7.727 6.313l-4.02-4.02-1.414 1.414 18 18 1.414-1.414zM12 5.868v4.718L9.169 7.755 12 5.868zM4 17h2.697l5.748 3.832a1.004 1.004 0 0 0 1.027.05A1 1 0 0 0 14 20v-1.879l-2-2v2.011l-4.445-2.964c-.025-.017-.056-.02-.082-.033a.986.986 0 0 0-.382-.116C7.059 15.016 7.032 15 7 15H4V9h.879L3.102 7.223A1.995 1.995 0 0 0 2 9v6c0 1.103.897 2 2 2z"/>';

  isSliderVisible = false;
  @Input() isTodosFlow = false;
  urlRedirect = '';

  @Input() ui!: Observable<InterfazControles>;
  showVolume!: boolean;
  showExit!: boolean;
  showDownload!: boolean;
  showRestart!: boolean;

  constructor(
    private webSocketService: WebSocketService,
    private unityControllerService: UnityControllerService,
    private audioControllerService: AudioControllerService,
    private useCasesService: UseCasesService,
    private queueProcessorService: QueueProcessorService,
    private tenantDataService: TenantDataService
  ) { }

  ngOnInit() {
    this.webSocketService.getServiceUnavailableEvent().subscribe(() => {
      this.goHome();
    });
    this.subscribeTenantData();
  }

  private subscribeTenantData() {
    this.ui.subscribe({
      next: (data: InterfazControles) => {
        this.setButtonsVisibility(data);
        // Get the volume value from local storage
        this.initializeVolume();
      }, error: (error) => {
        console.error('Error al obtener los datos de controles ui: ', error);
      }
    });
  }

  private initializeVolume() {
    const volume = localStorage.getItem('volume');
    if (volume) {
      this.volume = parseInt(volume);
    }
    // Get the volume icon from local storage
    const volumeIcon = localStorage.getItem('volumeIcon');
    if (volumeIcon) {
      this.imgVolume = volumeIcon;
    } else {
      this.imgVolume = this.volumePathSvgFull;
    }
  }

  private setButtonsVisibility(ui: InterfazControles) {
    this.showDownload = ui.botonDescargar;
    this.showExit = ui.botonSalir;
    this.showRestart = ui.botonReiniciar;
    this.showVolume = ui.botonVolumen;
    this.urlRedirect = ui.urlRedireccion;
    this.initializeVolume();
  }

  onVolumeChange(volume: number) {
    if (volume > 50) {
      this.imgVolume = this.volumePathSvgFull;
    } else if (volume > 1 && volume < 50) {
      this.imgVolume = this.volumePathSvgLow;
    } else if (volume === 0) {
      this.imgVolume = this.volumePathSvgMute;
    }

    // Save in local storage the volume value and icon
    localStorage.setItem('volume', volume.toString());
    localStorage.setItem('volumeIcon', this.imgVolume);
    this.unityControllerService.volumeChange(volume);
    this.audioControllerService.updateVolume(volume);
  }

  volumeMute() {
    this.audioControllerService.playAudio('/assets/audio/button-click.mp3');

    this.isVolumeMuted = !this.isVolumeMuted;
    if (this.isVolumeMuted) {
      this.imgVolume = this.volumePathSvgMute;
      this.volume = 0;
    } else {
      this.imgVolume = this.volumePathSvgFull;

      // Get the volume value from local storage
      const volume = localStorage.getItem('volume');
      if (volume) {
        this.volume = parseInt(volume);
      }
    }

    this.unityControllerService.volumeChange(this.volume);
    this.audioControllerService.updateVolume(this.volume);
  }

  showSlider(): void {
    this.isSliderVisible = true;
  }

  hideSlider(): void {
    this.isSliderVisible = false;
  }

  goHome() {
    this.queueProcessorService.clearQueue();
    this.useCasesService.setStopConversation(true);
    this.unityControllerService.cancelSpeech();
    localStorage.removeItem('token');

    this.useCasesService.setVirtualKeyboardState(false);
    this.useCasesService.setVirtualNumericKeyboardState(false);
    this.unityControllerService.sendChangeCamera('Standard');
  }

  refreshConversation() {
    this.queueProcessorService.clearQueue();
    localStorage.removeItem('token');
    this.unityControllerService.cancelSpeech();
    this.webSocketService.restartConversation();

    this.queueProcessorService.setClearChat();

    this.useCasesService.setVirtualKeyboardState(false);
    this.useCasesService.setVirtualNumericKeyboardState(false);
    this.unityControllerService.sendChangeCamera('Standard');
  }

  redirectTo() {
    this.queueProcessorService.clearQueue();
    this.useCasesService.setStopConversation(true);
    localStorage.removeItem('token');
    this.useCasesService.setVirtualKeyboardState(false);
    this.useCasesService.setVirtualNumericKeyboardState(false);

    window.open(this.urlRedirect, '_self');
  }

  downloadConversation() {
    const id = this.webSocketService.getIdCliente();

    this.tenantDataService.getConversacion(id).subscribe({
      next: (data: any) => {
        const pdfBase64 = data.data;

        const binaryData = atob(pdfBase64);

        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryData.length; i++) {
          uint8Array[i] = binaryData.charCodeAt(i);
        }

        const blob = new Blob([uint8Array], { type: 'application/pdf' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'conversacion.pdf';

        link.click();
      },
      error: (error) => {
        console.error('Error al descargar la conversación: ', error);
      }
    })
  }
}

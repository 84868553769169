import { Component, Input } from '@angular/core';
import { UnityControllerService } from 'src/app/services/unity-controller.service';
import { AvatarLoaderService } from '../../threejs/threejs-avatars/services/avatar/avatar-loader.service';

@Component({
  selector: 'app-loading-avatar',
  templateUrl: './loading-avatar.component.html',
  styleUrls: ['./loading-avatar.component.scss']
})
export class LoadingAvatarComponent {
  @Input() noAvatar = false;
  @Input() message = 'ui.loadingAvatar';
  avatarLoaded = false;
  @Input() center = false;


  constructor(private unityControllerService: UnityControllerService, private avatarLoaderService: AvatarLoaderService) {
    this.initServices();
  }

  private initServices() {
    this.unityControllerService.getAvatarState().subscribe(() => {
      this.avatarLoaded = true;
    })
    this.avatarLoaderService.getAvatarInfo().subscribe(() => {
      this.avatarLoaded = true;
    })
  }
}

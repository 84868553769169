import {Component, ElementRef, Input, ViewChild } from '@angular/core';
import KeenSlider, { KeenSliderInstance } from 'keen-slider';
import { TranslatorService } from 'src/app/services/translator.service';

interface Lang {
  name: string,
  code: string
}

@Component({
  selector: 'app-switchlang',
  templateUrl: './switchlang.component.html',
  styleUrls: ['./switchlang.component.scss']
})
export class SwitchlangComponent {
  scrollFinished = false;
  scrollTimeout!: NodeJS.Timeout;
  @Input() elderLayout!: boolean;

  @ViewChild('sliderRef', { static: false }) sliderRef!: ElementRef; 
  languages: Lang[];
  selectedLanguage?: Lang;
  showLanguages = false;
  langButtonPressed = false;
  scrollValue!: number;
  slider!: KeenSliderInstance;
  sliderbuilt = false;
  currentSlide = 0;

  constructor(private translatorService: TranslatorService) {
    this.languages = [
      { name: 'Español', code: 'es' },
      { name: 'English', code: 'en' },
      { name: 'Français', code: 'fr' },
      { name: 'Português', code: 'pt' },
      { name: 'Deutsch', code: 'de' },
      { name: 'Italiano', code: 'it' },
      { name: '中国', code: 'zh-Hans' },

    ];
    translatorService.getLenguageState().subscribe((code: string) => {
      if (code === this.selectedLanguage?.code) return;
      this.selectedLanguage = this.languages.find(l => l.code === code);
      if(this.slider){
        this.slider.moveToIdx(this.languages.indexOf(this.selectedLanguage!));
      }
    });
  }

  buildSlider(): void {
    if(this.sliderbuilt) return;
    this.slider = new KeenSlider(this.sliderRef.nativeElement, {
      slides: {
        perView: 3,
        origin: 'center'
      },
      loop: true,
      vertical: true,
      slideChanged: this.updateSelectedSlide.bind(this)
    });
    this.attachSlideListeners();
    this.sliderbuilt = true;
  }

  attachSlideListeners(){
    this.slider.slides.forEach((slide, index) => {
      slide.addEventListener('click', () => {
        this.slider.moveToIdx(index);
      })
    });
  }

  switchLang() {
    try {
      this.translatorService.setTargetLenguage(this.selectedLanguage!.code);
    } catch (error) {
      console.error(error);
    }
  }

  toggleLanguages() {
    this.showLanguages = !this.showLanguages;
    this.langButtonPressed = true;
    if (this.showLanguages) {
      setTimeout(() => {
        this.buildSlider();

        this.langButtonPressed = false;
      }, 10)
    }
  }

  updateSelectedSlide(currentSlider: KeenSliderInstance) {
    this.currentSlide = currentSlider.track.details.rel;

    if(this.languages[this.currentSlide] && currentSlider.slides[this.currentSlide]){
      this.selectedLanguage = this.languages[this.currentSlide];
      this.switchLang();
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UrlParams } from 'src/app/models/parameters.model';

@Injectable({
  providedIn: 'root'
})
export class ShortenUrlService {

  private urlEnlaces = environment.transversales.urlEnlaces;

  constructor(private http: HttpClient) { }

  public getShortenUrl(id: string): Observable<UrlParams> {
    return this.http.get<UrlParams>(`${this.urlEnlaces}?guidEnlace=${id}`);
  }
}

import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AudioControllerService } from 'src/app/services/audio-controller.service';
import { TenantDataService } from 'src/app/services/tenant/tenant-data.service';

export const fadeOut = trigger('fadeOut', [
  state('visible', style({
    opacity: 1,
  })),
  state('hidden', style({
    opacity: 0,
  })),
  transition('visible => hidden', [
    animate('500ms')
  ])
])

export const fadeIn = trigger('fadeIn', [
  state('visible', style({
    opacity: 1
  })),
  state('hidden', style({
    opacity: 0
  })),
  transition('hidden => visible', [
    animate('500ms')
  ])
])
@Component({
  selector: 'app-initial-view',
  templateUrl: './initial-view.component.html',
  styleUrls: ['./initial-view.component.scss'],
  animations: [fadeOut, fadeIn]
})
export class InitialViewComponent implements OnInit {
  constructor(
    private audioControllerService: AudioControllerService,
    private tenantDataService: TenantDataService
  ) { }
  @ViewChild('initialViewApp', { static: true }) initialViewApp!: ElementRef;
  @ViewChild('imageBg', { static: true }) imageBg!: ElementRef;
  @ViewChild('logoTenant', { static: true }) logoTenant!: ElementRef;

  isVisible = "visible";
  avoidInitButton = false;
  @Input() isLoading!: boolean;
  @Input() noAvatar!: boolean;
  @Input() avoidInitiButtonSubject!: Observable<void>;
  logoTenantUrl!: string;
  previewAvatarImgUrl!: string;
  initButtonText!: string;

  @Output() skipPressed = new EventEmitter()

  ngOnInit(): void {
    this.subscribeTenantData();
    this.subscribeAvoidInitButton();
  }

  private subscribeTenantData() {
    this.tenantDataService.getDatosTenantEvent().subscribe((res) => {
      this.logoTenantUrl = res.data.personaDigital.interfazUsuario.logo;
      this.initButtonText = res.data.personaDigital.interfazUsuario.pantallaInicial.botonInicio.texto;
    });
    this.tenantDataService.getAvatarDataLocal().subscribe((res) => {
      this.previewAvatarImgUrl = res.data.uriPreview;
    });
  }

  private subscribeAvoidInitButton(){
    this.avoidInitiButtonSubject.subscribe({
      next: () => {
        this.avoidInitButton = true;
        this.skipInitialView();
      }
    })
  }

  skipInitialView() {
    this.isVisible = "hidden";
    this.audioControllerService.playAudio('/assets/audio/button-click.mp3');
    setTimeout(() => {
      this.imageBg.nativeElement.style.display = 'none';
      this.initialViewApp.nativeElement.style.display = 'none';
    }, 500)
    this.skipPressed.emit()
  }
}

import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { UseCasesService } from 'src/app/services/chat/use-cases.service';
import { WebSocketService } from 'src/app/services/web-socket.service';

export interface WarningMessage {
  title: string;
  message: string;
}


@Component({
  selector: 'app-warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: ['./warning-modal.component.scss']
})
export class WarningModalComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  @ViewChild('warnModal', { static: true }) warnModal!: ElementRef;
  warningTitle!: string;
  warningMessage!: string;
  modal!: NgbModalRef;

  constructor(
    private modalService: NgbModal,
    private useCasesService: UseCasesService,
    private webSocketService: WebSocketService
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.useCasesService.getShowWarningModal().subscribe((message: WarningMessage) => {
        this.warningTitle = message.title;
        this.warningMessage = message.message;
        this.modal = this.modalService.open(this.warnModal, { centered: true, backdrop: "static", size: "lg" });
      }),
      this.webSocketService.getEndConversationEvent().subscribe({
        next: () => {
          if(this.modal) this.modal.close();
        }
      }),
      this.useCasesService.getStopConversation().subscribe({
        next: () => {
          if(this.modal) this.modal.close();
        }
      })
    );
  }

  continue() {
    this.webSocketService.SendMessageThroughSocket("Confirmar modal advertencia");
    this.modal.close();
  }
  cancel() {
    this.webSocketService.SendMessageThroughSocket("Cancelar modal advertencia");
    this.modal.close();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}

import { Component, OnInit, ViewChild, Renderer2, ElementRef, AfterViewInit, Input, OnDestroy } from '@angular/core';
import { UseCasesService } from 'src/app/services/chat/use-cases.service';

@Component({
  selector: 'app-numerickeyboard',
  templateUrl: './numerickeyboard.component.html',
  styleUrls: ['./numerickeyboard.component.scss']
})
export class NumerickeyboardComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() showInputFeedback!: boolean;
  @ViewChild('inputFeedback') inputFeedBack!: ElementRef;

  elements!: {
    main: any;
    keysContainer: any;
    keys: any[];
  };

  eventHandlers!: {
    oninput: any;
    onclose: any;
    [key: string]: any;
  };

  properties!: {
    value: string;
    capsLock: boolean;
    typedText: string;
  };

  activeInput: any;

  constructor(private renderer: Renderer2, private useCasesService: UseCasesService) { }

  ngOnInit() {
    this.elements = {
      main: null,
      keysContainer: null,
      keys: []
    };

    this.eventHandlers = {
      oninput: null,
      onclose: null
    };

    this.properties = {
      value: "",
      capsLock: false,
      typedText: ""
    };

    this.eventHandlers.oninput = (value: string) => {
      // this.useCasesService.setVirtualNumericKeyboardMessage(this.properties.typedText);
    };

    // Subscribe to the document for click events to get the active input
    document.addEventListener('click', (event) => {
      const targetElement = event.target as HTMLElement;

      if (targetElement.tagName === 'INPUT') {
        this.activeInput = targetElement;
        this.properties.value = this.activeInput.value;

        if (this.activeInput.id === 'chat-input') {
          this.useCasesService.setVirtualNumericKeyboardMessage(this.properties.value);
        }
      }
    });
  }

  ngAfterViewInit(): void {
    this.useCasesService.getEventVirtualNumericKeyboard().subscribe((inputField: any) => {
      this.activeInput = inputField;
      this.properties.value = this.activeInput.value;

      if (this.activeInput.id === 'chat-input') {
        this.useCasesService.setVirtualNumericKeyboardMessage(this.properties.value);
      }
    });
  }

  private triggerEvent(handlerName: string) {

    if (typeof this.eventHandlers[handlerName] == "function") {
      this.eventHandlers[handlerName](this.properties.value);

      // Update the input value
      if (this.activeInput) {
        this.renderer.setProperty(this.activeInput, 'value', this.properties.value);
        if(this.showInputFeedback) this.inputFeedBack.nativeElement.value = this.properties.value;
        
        this.activeInput.dispatchEvent(new Event('input'));
      }
    }
  }

  public type(key: number){
    this.properties.value += key;
    this.triggerEvent("oninput");
  }

  public erase(){
    this.properties.value = this.properties.value.substring(0, this.properties.value.length - 1);
    this.triggerEvent("oninput");
  }

  public send(){
    this.useCasesService.setVirtualNumericKeyboardSendMessage();
    this.inputFeedBack.nativeElement.value = '';
  }

  open(initialValue: string, oninput: (value: string) => void, onclose: () => void) {
    this.properties.value = initialValue || "";
    this.eventHandlers.oninput = oninput;
    this.eventHandlers.onclose = onclose;
  }

  close() {
    this.properties.value = "";
    this.eventHandlers.oninput = oninput;
    this.eventHandlers.onclose = onclose;
  }

  ngOnDestroy(): void {
    if(this.activeInput){
      this.renderer.setProperty(this.activeInput, 'value', '');
    }
  }

}

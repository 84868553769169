import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Directive({
  selector: '[appSafeYoutubeEmbed]'
})
export class SafeYoutubeEmbedDirective implements OnChanges {

  @Input() appSafeYoutubeEmbed!: string | SafeResourceUrl;

  constructor(private elementRef: ElementRef, private renderer: Renderer2, private sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['appSafeYoutubeEmbed']) {
      const embedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.getEmbedUrl(this.appSafeYoutubeEmbed ? this.appSafeYoutubeEmbed.toString() : ''));
      this.renderer.setAttribute(this.elementRef.nativeElement, 'src', (embedUrl as any).changingThisBreaksApplicationSecurity + '?&autoplay=1' + '&mute=1');
    }
  }

  private getEmbedUrl(url: string): string {
    return url.replace('watch?v=', 'embed/');
  }
}

export enum WebSocketEvents {
    WATSON_START_CONVERSATION = 'startConversation',
    WATSON_CONVERSATION = 'conversation',
    WATSON_FINISH_CONVERSATION = 'stopConversation',
    LLM_START_CONVERSATION = 'startChatGPTConversation',
    LLM_CONVERSATION = 'chatGptConversation',
    LLM_FINISH_CONVERSATION = 'stopChatGptConversation',
    EXTERNAL_NLP_START_CONVERSATION = 'startExternalConversation',
    EXTERNAL_NLP_CONVERSATION = 'externalConversation',
    EXTENRAL_NLP_FINISH_CONVERSATION = 'stopExternalConversation',
    SPEAKER_START_CONVERSATION = 'speakerConnection'
}
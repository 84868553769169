import { Injectable } from '@angular/core';
import { EyeBlinkService } from './eye-blink.service';
import { GazeService } from './gaze.service';
import { HeadMovementService } from './head-movement.service';

@Injectable({
  providedIn: 'root'
})
export class GazeFacadeService {

  constructor(private eyeBlinkService: EyeBlinkService, private gazeService: GazeService,
    private headMovementService: HeadMovementService) { }

  public update(){
    this.eyeBlinkService.update();
    this.gazeService.update();
    this.headMovementService.update();
  }

  public setTargetPoint(targetPoint: THREE.Object3D){
    this.headMovementService.setTargetPoint(targetPoint);
  }
}

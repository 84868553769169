import * as THREE from 'three';

export const cameraPositions: { [key: string]: THREE.Vector3 } = {
  Standard: new THREE.Vector3(0, 14, 20),
  Mid: new THREE.Vector3(0, 15, 17),
  CloseUp: new THREE.Vector3(0, 16, 15),
  Mobile: new THREE.Vector3(0, 11.5, 30),
  NoChat: new THREE.Vector3(0, 15, 14),
  Widget: new THREE.Vector3(0, 16.7, 6)
};

export const cameraRotations: { [key: string]: THREE.Quaternion } = {
  Standard: new THREE.Quaternion().setFromEuler(
    new THREE.Euler(0, 0.01, 0, 'XYZ')
  ),
  Mid: new THREE.Quaternion().setFromEuler(
    new THREE.Euler(0, 0.01, 0, 'XYZ')
  ),
  CloseUp: new THREE.Quaternion().setFromEuler(
    new THREE.Euler(0, 0.01, 0, 'XYZ')
  ),
  Mobile: new THREE.Quaternion().setFromEuler(
    new THREE.Euler(0, 0.01, 0, 'XYZ')
  ),
  NoChat: new THREE.Quaternion().setFromEuler(
    new THREE.Euler(0, 0.01, 0, 'XYZ')
  ),
  Widget: new THREE.Quaternion().setFromEuler(
    new THREE.Euler(0, 0.01, 0, 'XYZ')
  )
};

export const targetPositions: { [key: string]: THREE.Vector3 } = {
  Standard: new THREE.Vector3(0, 15, 30),
  Mid: new THREE.Vector3(0, 15.5, 30),
  CloseUp: new THREE.Vector3(0, 17, 30),
  Mobile: new THREE.Vector3(0, 14, 30),
  NoChat: new THREE.Vector3(0, 16, 30),
  Widget: new THREE.Vector3(0, 14, 30),
};

export const epmPositions: { [key: string]: THREE.Vector3 } = {
  Standard: new THREE.Vector3(0, 16, 14),
  Mid: new THREE.Vector3(0, 16, 14),
  CloseUp: new THREE.Vector3(0, 16, 14),
  Mobile: new THREE.Vector3(0, 12, 27),
  NoChat: new THREE.Vector3(0, 15, 14),
}

export const epmRotations: { [key: string]: THREE.Quaternion } = {
  Standard: new THREE.Quaternion().setFromEuler(
    new THREE.Euler(0, 0.01, 0, 'XYZ')
  ),
  Mid: new THREE.Quaternion().setFromEuler(
    new THREE.Euler(0, 0.01, 0, 'XYZ')
  ),
  CloseUp: new THREE.Quaternion().setFromEuler(
    new THREE.Euler(0, 0.01, 0, 'XYZ')
  ),
  Mobile: new THREE.Quaternion().setFromEuler(
    new THREE.Euler(0, 0.01, 0, 'XYZ')
  ),
  NoChat: new THREE.Quaternion().setFromEuler(
    new THREE.Euler(0, 0.01, 0, 'XYZ')
  ),
}

export const targetEpm: { [key: string]: THREE.Vector3 } = {
  Standard: new THREE.Vector3(0, 17, 30),
  Mid: new THREE.Vector3(0, 17, 30),
  CloseUp: new THREE.Vector3(0, 17, 30),
  Mobile: new THREE.Vector3(0, 13, 30),
  NoChat: new THREE.Vector3(0, 16, 30),
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-monthpicker',
  templateUrl: './monthpicker.component.html',
  styleUrls: ['./monthpicker.component.scss']
})
export class MonthpickerComponent implements OnInit {
  years: number[] = [];
  months: string[] = [];
  selectedStartYear: number | null = null;
  selectedStartMonth: string | null = null;
  selectedEndYear: number | null = null;
  selectedEndMonth: string | null = null;

  @Input() yearsToShow: number = 11;
  @Output() choseDateEvent = new EventEmitter<string>();

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.years = this.populateYears();
    this.months = this.translateMonths();
  }

  private translateMonths(): string[] {
    return [
      this.translateService.instant('dates.months.january'),
      this.translateService.instant('dates.months.february'),
      this.translateService.instant('dates.months.march'),
      this.translateService.instant('dates.months.april'),
      this.translateService.instant('dates.months.may'),
      this.translateService.instant('dates.months.june'),
      this.translateService.instant('dates.months.july'),
      this.translateService.instant('dates.months.august'),
      this.translateService.instant('dates.months.september'),
      this.translateService.instant('dates.months.october'),
      this.translateService.instant('dates.months.november'),
      this.translateService.instant('dates.months.december')
    ];
  }

  private populateYears(): number[] {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - this.yearsToShow + 1;
    return Array.from({length: this.yearsToShow}, (_, i) => startYear + i).reverse();
  }

  private formatDate(year: number, month: string) : string {
    const monthNumber = this.months.indexOf(month) + 1;
    const formattedMonth = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
    const formattedYear = year % 100;

    return `${formattedYear}${formattedMonth}`;
  }

  choseDate(){
    if(!this.validateDatePicked() || !this.checkDateRange()) return; 
    const startDate = this.formatDate(this.selectedStartYear!, this.selectedStartMonth!);
    const endDate = this.formatDate(this.selectedEndYear!, this.selectedEndMonth!);
    const chosenDate = `${startDate}-${endDate}`;
    console.log(`chosen date: ${chosenDate}`);
    
    this.choseDateEvent.emit(chosenDate);
  }

  checkDateRange() : boolean {
    if(!this.selectedStartYear || !this.selectedEndYear) return true;
    if(!this.selectedStartMonth || !this.selectedEndMonth) return true;
    if (this.selectedEndYear > this.selectedStartYear) return true;
    if ((this.selectedEndYear === this.selectedStartYear) && 
        this.months.indexOf(this.selectedEndMonth) > this.months.indexOf(this.selectedStartMonth)) return true;
    return false;
  }

  validateDatePicked() : boolean {
    return this.selectedStartMonth !== null && this.selectedStartYear !== null && this.selectedEndMonth !== null && this.selectedEndYear !== null;
  }
}

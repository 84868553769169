import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UseCasesService } from 'src/app/services/chat/use-cases.service';

@Component({
  selector: 'app-modal-address',
  templateUrl: './modal-address.component.html',
  styleUrls: ['./modal-address.component.scss']
})
export class ModalAddressComponent implements OnInit {

  addressForm!: FormGroup;
  closeResult = '';
  address = '';
  isModalOpen = false;

  @ViewChild('contentAddress', { static: true }) contentAddress!: ElementRef;

  constructor(private modalService: NgbModal, private fb: FormBuilder, private useCasesService: UseCasesService) { }

  ngOnInit(): void {
    this.useCasesService.getShowModalAddress().subscribe(() => {
      if(!this.isModalOpen) {
        this.openModal();
      }
    });
  }

  openModal() {
    this.isModalOpen = true;

    this.addressForm = this.fb.group({
      tipoVia: new FormControl('', Validators.required),
      numeroPrincipal: new FormControl('', Validators.required),
      letra: new FormControl(''),
      complemento: new FormControl(''),
      numeroSecundario: new FormControl(''),
      letraSecundario: new FormControl(''),
      placa: new FormControl(''),
      complementoSecundario: new FormControl(''),
      interior: new FormControl(''),
      numeroInterior: new FormControl(''),
    });

    this.addressForm.valueChanges.subscribe((value) => {
      this.address = `${value.tipoVia || ''} ${value.numeroPrincipal || ''}${value.letra || ''} ${value.complemento || ''} #${value.numeroSecundario || ''}${value.letraSecundario || ''}-${value.placa || ''} ${value.complementoSecundario || ''} ${value.interior || ''} ${value.numeroInterior || ''}`;
    });

    this.modalService.open(this.contentAddress, { ariaLabelledBy: 'modal-basic-title', centered: true, animation: true, size: "xl", backdrop: "static" }).result.then(
      () => {
        this.isModalOpen = false;
        this.useCasesService.setAddressMessage(this.address);
        this.clearForm();
      }, () => {
        this.isModalOpen = false;
        this.clearForm();
      }
    );
  }

  clearForm() {
    this.addressForm.reset();
    this.address = '';
  }
}

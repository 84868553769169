export enum FileExtensions {
    PDF = 'pdf',
    PNG = 'png',
    JPEG = 'jpeg',
    JPG = 'jpg',
    BMP = 'bmp',
    HEIF = 'heif',
    MP4 = 'mp4'
}

export enum FileTypes {
    PDF = 'application/pdf',
    PNG = 'image/png',
    JPEG = 'image/jpeg',
    BMP = 'image/bmp',
    HEIF = 'image/heif',
    MP4 = 'video/mp4'
}
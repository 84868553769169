import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SuscripcionesActivas } from '../../models/active-subscriptions.model';

@Injectable({
  providedIn: 'root'
})
export class GetActiveSubscriptionsService {

  private urlObtenerSuscripcionesActivas: string = environment.urlObtenerSuscripcionesActivas;

  constructor(private http: HttpClient) { }

  checkUnavailableService(tenant: string): Observable<void> {
    const serviceUnavailable = new Subject<void>()
    this.http.get<SuscripcionesActivas>(this.urlObtenerSuscripcionesActivas + tenant + '/activas?order=asc')
      .subscribe({
        next: (res: SuscripcionesActivas) => {
          this.handleSubscriptionRes(res, serviceUnavailable);
        }, error: (err) => {
          console.log(err);
        }
      });
    return serviceUnavailable;
  }

  private handleSubscriptionRes(res: SuscripcionesActivas, serviceUnavailable: Subject<void>) {
    if (res.data.length > 0) {
      const dateEnd = res.data[0].fechaFin === null ? null : new Date(res.data[0].fechaFin);
      const dateNow = new Date();

      if (dateEnd !== null && dateEnd <= dateNow) {
        serviceUnavailable.next();
      }
    } else {
      serviceUnavailable.next();
    }
  }
}

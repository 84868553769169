<ng-template #warnModal>
    <div class="modal-header">
        <div class="header-container">
            <button class="close-button" (click)="cancel()">
                <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M3.33268 29.5807L0.416016 26.6641L12.0827 14.9974L0.416016 3.33073L3.33268 0.414062L14.9993 12.0807L26.666 0.414062L29.5827 3.33073L17.916 14.9974L29.5827 26.6641L26.666 29.5807L14.9993 17.9141L3.33268 29.5807Z"
                        fill="#545454" />
                </svg>
            </button>
            <div class="title-container">
                <svg class="warning-svg" viewBox="0 0 131 115" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M52.3072 8.92721L52.3076 8.92656C58.2674 -0.975105 72.7243 -0.9761 78.6927 8.92705L78.6928 8.92721L127.787 90.3531C127.787 90.3531 127.787 90.3531 127.787 90.3532C133.925 100.535 126.557 113.5 114.586 113.5H16.4138C4.44326 113.5 -2.92534 100.535 3.21284 90.3532C3.21285 90.3531 3.21286 90.3531 3.21287 90.3531L52.3072 8.92721ZM70.5495 34.1297C69.2093 32.7972 67.3928 32.0496 65.5 32.0496C63.6072 32.0496 61.7907 32.7972 60.4505 34.1297C59.1101 35.4624 58.356 37.2712 58.356 39.1584V63.5935C58.356 65.4807 59.1101 67.2895 60.4505 68.6222C61.7907 69.9547 63.6072 70.7023 65.5 70.7023C67.3929 70.7023 69.2093 69.9547 70.5495 68.6222C71.8899 67.2895 72.644 65.4807 72.644 63.5935V39.1584C72.644 37.2712 71.8899 35.4624 70.5495 34.1297ZM71.9977 92.4609C73.7221 90.7463 74.692 88.4196 74.692 85.9924C74.692 83.5651 73.7221 81.2384 71.9977 79.5238C70.2734 77.8094 67.936 76.8473 65.5 76.8473C63.064 76.8473 60.7266 77.8094 59.0023 79.5238C57.2779 81.2384 56.3081 83.5651 56.3081 85.9924C56.3081 88.4196 57.2779 90.7463 59.0023 92.4609C60.7266 94.1753 63.064 95.1374 65.5 95.1374C67.936 95.1374 70.2734 94.1753 71.9977 92.4609Z"
                        stroke="black" stroke-width="2" />
                </svg>
                <div class="title">
                    {{warningTitle}}
                </div>
            </div>
        </div>
    </div>

    <div class="modal-body">
        <div class="modal-container">
            {{warningMessage}}
        </div>
    </div>

    <div class="modal-footer">
        <div class="button-container">
            <button id="cancelButton" class="btn option-button" (click)="cancel()">{{'ui.warnModal.cancel' |
                translate}}</button>
            <button id="continueButton" class="btn option-button" (click)="continue()">{{'ui.warnModal.continue' |
                translate}}</button>
        </div>
    </div>
</ng-template>
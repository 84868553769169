import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  public printBlob(blobDoc: Blob, type: string){
    saveAs(blobDoc, `epmdocument-${type}.pdf`);
  }
}

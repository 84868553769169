<ng-template #modalTemplate>
    <div class="modal-header">
        <div class="header-container">
            <div class="quit-container" *ngIf="showQuitModal">
                <button class="quit-button" (click)="quitModal()">
                    <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                        d="M3.33268 29.5807L0.416016 26.6641L12.0827 14.9974L0.416016 3.33073L3.33268 0.414062L14.9993 12.0807L26.666 0.414062L29.5827 3.33073L17.916 14.9974L29.5827 26.6641L26.666 29.5807L14.9993 17.9141L3.33268 29.5807Z"
                        fill="#545454" />
                    </svg>
                </button>
            </div>
            <div class="title-container">
                <svg width="50" height="50" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="security-path"
                        d="M17 12C17.5304 12 18.0391 12.2107 18.4142 12.5858C18.7893 12.9609 19 13.4696 19 14V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V14C5 13.4696 5.21071 12.9609 5.58579 12.5858C5.96086 12.2107 6.46957 12 7 12V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V12ZM12 19C12.5304 19 13.0391 18.7893 13.4142 18.4142C13.7893 18.0391 14 17.5304 14 17C14 16.4696 13.7893 15.9609 13.4142 15.5858C13.0391 15.2107 12.5304 15 12 15C11.4696 15 10.9609 15.2107 10.5858 15.5858C10.2107 15.9609 10 16.4696 10 17C10 17.5304 10.2107 18.0391 10.5858 18.4142C10.9609 18.7893 11.4696 19 12 19ZM15 12V7C15 6.60603 14.9224 6.21593 14.7716 5.85195C14.6209 5.48797 14.3999 5.15726 14.1213 4.87868C13.8427 4.6001 13.512 4.37913 13.1481 4.22836C12.7841 4.0776 12.394 4 12 4C11.606 4 11.2159 4.0776 10.8519 4.22836C10.488 4.37913 10.1573 4.6001 9.87868 4.87868C9.6001 5.15726 9.37913 5.48797 9.22836 5.85195C9.0776 6.21593 9 6.60603 9 7V12H15Z"
                        fill="#0F172A" />
                </svg>

                <h3 class="title">{{'ui.flowKey.title' | translate}}</h3>
            </div>
        </div>
    </div>

    <div class="modal-body">
        <div class="modal-view-container">
            {{'ui.flowKey.instruction' | translate}}
            <input class="password-input form-control shadow-sm" type="password" [(ngModel)]="userKey"
                (keypress)="onKeyPressed($event)">
            <small *ngIf="showIncorrectKey" class="small-text text-danger text-invalid">
                {{'ui.flowKey.incorrect' | translate}}
            </small>
            <small *ngIf="showUnableToCheck" class="small-text text-danger text-invalid">
                {{'ui.flowKey.couldNotCheck' | translate}}
            </small>
        </div>
    </div>

    <div class="modal-footer">
        <div class="button-container">
            <button id="printButton" class="btn modal-button" (click)="sendKey()"
                [disabled]="disableSendButton">{{'ui.flowKey.continue' |
                translate}}
            </button>
            <button id="cancelButton" class="btn modal-button" (click)="exit()">
                <svg _ngcontent-gqi-c155="" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" class="exit-icon">
                    <path _ngcontent-gqi-c155="" d="M16 13v-2H7V8l-5 4 5 4v-3z"></path>
                    <path _ngcontent-gqi-c155=""
                        d="M20 3h-9c-1.103 0-2 .897-2 2v4h2V5h9v14h-9v-4H9v4c0 1.103.897 2 2 2h9c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2z">
                    </path>
                </svg>
                {{'ui.flowKey.exit' | translate}}
            </button>
        </div>
    </div>
</ng-template>
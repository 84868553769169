import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { randFloat } from 'three/src/math/MathUtils';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { UnityControllerService } from 'src/app/services/unity-controller.service';
import { UseCasesService } from 'src/app/services/chat/use-cases.service';

export interface Shift {
  turno: string,
  modulo: string,
  nombre_usuario: string
}

export const fadeOut = trigger('fadeOut', [
  state('visible', style({
    opacity: 1,
  })),
  state('hidden', style({
    opacity: 0,
  })),
  transition('visible => hidden', [
    animate('500ms')
  ])
])

export const fadeIn = trigger('fadeIn', [
  state('visible', style({
    opacity: 1
  })),
  state('hidden', style({
    opacity: 0
  })),
  transition('hidden => visible', [
    animate('500ms')
  ])
])
@Component({
  selector: 'app-shift-table',
  templateUrl: './shift-table.component.html',
  styleUrls: ['./shift-table.component.scss'],
  animations: [fadeOut, fadeIn]
})
export class ShiftTableComponent implements OnInit {
  @Input() showNameColumn = false;
  @Input() youTubeVideoUrl = 'https://www.youtube.com/watch?v=nAmKbeRRGVs';

  @ViewChild('videoContainer') videoContainer!: ElementRef
  @ViewChild('video') video!: YT.Player;
  videoWidth: number | undefined;
  videoHeight: number | undefined;
  playVideoTimeOut!: NodeJS.Timeout;
  videoStatus = 'visible';

  constructor(
    private webSocketService: WebSocketService,
    private unityControllerService: UnityControllerService,
    private useCasesService: UseCasesService
  ) { }

  ngOnInit(): void {
    this.loadYouTubeApi();
    this.videoWidth = window.innerWidth * 0.4;
    this.videoHeight = window.innerHeight + 10;
    this.unityControllerService.getSpeechState().subscribe(() => {
      this.playVideo()
    })
    this.subscribeNewShift();
  }

  private subscribeNewShift() {
    // this.webSocketService.getShiftObservable().subscribe((res: Shift) => {
    //   this.addShiftRow(res.turno, res.modulo, res.nombre_usuario)
    // })
  }

  private loadYouTubeApi() {
    const script = document.createElement('script');
    script.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(script);
  }

  shifts = new Array<string>();

  addShiftRow(shift: string, office: string, name = "") {
    this.pauseVideo();
    const rowSize = this.showNameColumn ? 3 : 2;
    const newRow = [shift, office];
    if (name !== "") newRow.push(name)
    this.shifts = [...newRow, ...this.shifts].slice(0, rowSize * 6);
  }

  playVideo(delayMs = 0) {
    this.playVideoTimeOut = setTimeout(() => {
      this.changeVideoStatus('visible');
      this.setVideoLoop()
      setTimeout(() => this.video.playVideo(), 500)
    }, delayMs);
  }

  private setVideoLoop(): void {
    const length = this.video.getDuration() - this.video.getCurrentTime()
    if (length > 0)
      this.playVideo(length * 1000);
  }

  pauseVideo() {
    this.changeVideoStatus('hidden');
    clearTimeout(this.playVideoTimeOut);
    this.video.pauseVideo();
  }

  private changeVideoStatus(status: string) {
    this.videoStatus = status;
    setTimeout(() => {
      this.videoContainer.nativeElement.style.display = status === 'hidden' ? 'none' : 'block';
    }, 500)
  }
}
import { Injectable } from '@angular/core';
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TenantData, Voz } from '../../models/tenant-data.model';
import { TranslatorService } from '../translator.service';
import { TenantDataService } from '../tenant/tenant-data.service';
import { speechWordReplaceArray } from './word-replace-array';

@Injectable({
  providedIn: 'root'
})
export class TextToSpeechService {

  speechConfig: any;
  voiceSettings!: Voz;
  gender!: string;
  languageCode = 'es-MX';
  blendData: Array<any> = [];
  voice!: string;


  constructor(
    private translatorService: TranslatorService,
    private tenantDataService: TenantDataService
  ) {
    this.subscribeTenantData();
  }

  subscribeTenantData() {
    this.tenantDataService.getDatosTenantEvent().subscribe({
      next: (res: TenantData) => {
        this.setVoiceSettings(res)
        const language = res.data.personaDigital.interfazUsuario.lenguaje;
        this.translatorService.setTargetLenguage(language.split('-')[0]);
      }
    })
  }

  textToSpeech(text: string): Observable<{ audio: Blob, blendData: any[], audioDuration: number }> {
    return new Observable(observer => {
      this.blendData = [];

      text = this.cleanSpeechText(text);

      const ssml = `<speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xml:lang="${this.translatorService.getRegionCode()}">
      <voice name="${this.translatorService.getVoice()}">
      <mstts:viseme type="FacialExpression"/>
      <prosody rate="${this.voiceSettings.rate}%" pitch="${this.voiceSettings.pitch}%">
        ${text}
      </prosody>
      </voice>
      </speak>`;
      this.speechConfig = SpeechSDK.SpeechConfig.fromSubscription(environment.azureCognitiveServices.apiKey, environment.azureCognitiveServices.region);
      this.speechConfig.speechSynthesisOutputFormat = SpeechSDK.SpeechSynthesisOutputFormat.Audio48Khz192KBitRateMonoMp3;

      const audioOutPutStream = SpeechSDK.AudioOutputStream.createPullStream();
      const audioConfig = SpeechSDK.AudioConfig.fromStreamOutput(audioOutPutStream);

      try {
        const syntheziser = new SpeechSDK.SpeechSynthesizer(this.speechConfig, audioConfig);

        syntheziser.visemeReceived = (s: any, e: any) => {
          const animation = JSON.parse(e.animation);
          this.blendData.push(animation);
        };

        syntheziser.speakSsmlAsync(ssml, (result) => {
          syntheziser.close();

          const audioBlob = new Blob([result.audioData], { type: 'audio/mp3' });
          observer.next({ audio: audioBlob, blendData: this.blendData, audioDuration: result.audioDuration });
          observer.complete();
        }, (error) => {
          console.log(error);
          observer.error(error);
        });
      } catch (error) {
        console.log(error);
        observer.error(error);
      }
    });
  }

  private cleanSpeechText(text: string) {
    if (text.includes('https://') || text.includes('http://')) {
      text = text.replace(/(https?:\/\/[^\s]+)/g, '');
    }
    if (text.includes('<')) {
      text = text.replace(/<([^>]+)>/g, '');
    }
    speechWordReplaceArray.forEach(element => {
      text = text.toLowerCase().replaceAll(element.match, element.replacement);
    });
    text = this.removeEmojis(text);
    text = this.formatNumbersForSSML(text);
    return text;
  }

  private formatNumbersForSSML(text: string): string {
    return text.replace(/(?:\$|€|£)?(\d{1,3}([.,]\d{3})*(?:,\d+)?)/g, (match) => {
      // Remover el símbolo de moneda y separadores de miles
      const cleanNumber = match.replace(/[$€£.,]/g, '');
      return `<say-as interpret-as="cardinal">${cleanNumber}</say-as>`;
    });
  }

  private removeEmojis(text: string){
    const emojiRegex = /\p{Extended_Pictographic}/gu;
    return text.replace(emojiRegex, '');
  }

  clearBlendData() {
    this.blendData = [];
  }

  setVoiceSettings(settings: TenantData) {
    this.voiceSettings = settings.data.personaDigital.voz;
    const voiceLanguage = settings.data.personaDigital.interfazUsuario.lenguaje.split('-')[0];
    this.voice = this.voiceSettings.voz;
    this.gender = settings['data']['personaDigital'].gender;
    this.translatorService.setVoiceSetting(this.voice, voiceLanguage, this.gender);
  }

  get TtsAllowed() {
    return this.voiceSettings.textToSpeech !== undefined ? this.voiceSettings.textToSpeech : true;
  }
}

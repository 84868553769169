<div class="container-carousel" #container>
	<div class="left" *ngIf="totalPages > 1" (click)="currentPage !== 1 && changePage(-1)" (keyup)="currentPage !== 1 && changePage(-1)" [ngClass]="{'disabled':currentPage === 1}" tabindex="0"></div>
	<div class="cards" [ngClass]="{'w-100': totalPages === 1}">
		<div class="overflow" [ngStyle]="{'width': overflowWidth, 'left': pagePosition}">
			<div class="card" *ngFor="let i of arr; let index = index" [ngStyle]="{'width': cardWidth}">
        <div>
          <img src="../../../../assets/images/interview.svg" alt="" class="img-card">
          <span class="text-card">Opción {{i}}</span>
        </div>
			</div>
		</div>
	</div>
	<div class="right" *ngIf="totalPages > 1" (click)="currentPage !== totalPages && changePage(+1)" (keyup)="currentPage !== totalPages && changePage(+1)" [ngClass]="{'disabled':currentPage === totalPages}" tabindex="0"></div>
</div>

import { Component, ElementRef, OnInit, AfterViewInit, ViewChild, OnDestroy, ViewContainerRef, Input } from '@angular/core';
import { DatumButtons } from 'src/app/models/tenant-data.model';
import { UnityControllerService } from 'src/app/services/unity-controller.service';
import { Observable } from 'rxjs';

// Buja

// Threejs
import { EngineService } from 'src/app/services/engine/engine.service';

@Component({
  selector: 'app-engine',
  templateUrl: './engine.component.html',
  styleUrls: ['./engine.component.scss'],
})

export class EngineComponent implements OnInit, AfterViewInit, OnDestroy {
  [x: string]: any;
  isIOS = false;
  lowDownloadSpeed = false;
  noAvatar = false;
  showLoading = true;
  isLoading = true;
  @Input() startPressedObservable!: Observable<void | DatumButtons>;
  centerAvatar = false;

  @ViewChild('canvas', { static: true }) canvas!: HTMLCanvasElement;
  @ViewChild('containerDiv', { static: true }) containerDiv!: ElementRef;

  @ViewChild('engineComponent', { read: ViewContainerRef, static: true }) engineComponent!: ViewContainerRef;

  constructor(
    private unityControllerService: UnityControllerService,
    private engineService: EngineService
  ) { }

  subscribeLowDownloadSpeed(obs: Observable<boolean>) {
    obs.subscribe((data: boolean) => {
      this.lowDownloadSpeed = data;
    })
  }

  ngOnInit() {
    this.engineService.initService(this);
    this.engineService.setStartPressedObservable(this.startPressedObservable);
    this.disableRightClick();
  }

  ngAfterViewInit() {
    if (this.isIOS) this.unityControllerService.setIOs();
  }

  private disableRightClick() {
    document.addEventListener('contextmenu', event => event.preventDefault());
  }

  showContainerDiv() {
    this.containerDiv.nativeElement.style.display = 'block';
  }

  clearComponent(): ViewContainerRef {
    this.engineComponent.clear();
    return this.engineComponent;
  }

  ngOnDestroy(): void {
    this.engineService.removeSubscriptions();
  }
}

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UseCasesService } from 'src/app/services/chat/use-cases.service';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-flow-key-modal',
  templateUrl: './flow-key-modal.component.html',
  styleUrls: ['./flow-key-modal.component.scss']
})
export class FlowKeyModalComponent implements OnInit {
  @ViewChild('modalTemplate', { static: true }) modalTemplate!: ElementRef;
  modal!: NgbModalRef;
  flowId!: string;
  userKey: any;
  @Input() urlRedirect: string | URL | undefined;
  showIncorrectKey = false;
  showUnableToCheck = false;
  disableSendButton = false;
  @Input() showQuitModal!: boolean;

  constructor(
    private modalService: NgbModal,
    private useCasesService: UseCasesService,
    private webSocketService: WebSocketService,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.initServices();
  }

  private initServices() {
    this.useCasesService.getFlowProtected().subscribe((res: string) => {
      this.flowId = res;
      this.modal = this.modalService.open(this.modalTemplate, { centered: true, backdrop: "static", size: "md" })

      this.disableSendButton = false;
      this.showIncorrectKey = false;
      this.showUnableToCheck = false;
      this.userKey = ''
    })

    this.webSocketService.getEndConversationEvent().subscribe(() => {
      if (this.modal)
        this.modal.close();
    })
  }

  onKeyPressed(event: KeyboardEvent) {
    if (event.key === "Enter") this.sendKey()
  }

  public exit() {
    window.open(this.urlRedirect, '_self');
  }

  public sendKey() {
    this.disableSendButton = true;
    this.showIncorrectKey = false;
    this.showUnableToCheck = false;

    const validateKeyBody = {
      idFlujo: this.flowId,
      claveIngresada: this.userKey
    }
    this.http.post(environment.transversales.urlValidarClave, validateKeyBody).subscribe({
      next: (res: any) => {
        if (res.statusCode === 200) {
          this.useCasesService.callKeyFlowVerified()
          this.modal.close();
        }
      },
      error: (err) => {
        if (err.status === 400) this.showIncorrectKey = true;
        else this.showUnableToCheck = true;
        this.disableSendButton = false;
      }
    })
  }

  quitModal() {
    this.modal.close();
  }

}


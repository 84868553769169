import { Component, OnInit } from '@angular/core';
import { LogService } from 'src/app/services/utils/log.service';

@Component({
  selector: 'app-log-display',
  templateUrl: './log-display.component.html',
  styleUrls: ['./log-display.component.scss']
})
export class LogDisplayComponent implements OnInit {
  logs: string[] = [];
  resizing = false;

  constructor(private logService: LogService) {}

  ngOnInit() {
    this.logService.setupConsoleLogging();
    this.logs = this.logService.getLogs();
  }

  getLogClass(log: string): string {
    if (log.toLowerCase().includes('error')) {
      return 'log-error';
    } else if (log.toLowerCase().includes('warning')) {
      return 'log-warning';
    }
    return 'log-info';
  }
}

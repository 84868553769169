import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { fadeIn, fadeOut } from '../initial-view/initial-view.component';
import { TenantDataService } from 'src/app/services/tenant/tenant-data.service';
import { DatumButtons, StartButtons } from 'src/app/models/tenant-data.model';

@Component({
  selector: 'app-initial-view-buja',
  templateUrl: './initial-view-buja.component.html',
  styleUrls: ['./initial-view-buja.component.scss'],
  animations: [fadeOut, fadeIn]
})
export class InitialViewBujaComponent implements OnInit {
  isVisible = "visible";
  initialButtonsData!: DatumButtons[];
  @ViewChild('initialView', { static: true }) initialView!: ElementRef;

  @Input() isLoading!: boolean;
  @Output() skipPressed = new EventEmitter<DatumButtons>();
  constructor(
    private tenantDataService: TenantDataService,
  ) { }

  ngOnInit(): void {
    this.subscribeTenantData();
  }

  private subscribeTenantData() {
    this.tenantDataService.getInitialButtonsEvent().subscribe((data: StartButtons) => {
      this.initialButtonsData = data.data;
    });
  }

  skipInitialView(data: DatumButtons) {
    this.isVisible = "hidden";
    setTimeout(() => {
      this.initialView.nativeElement.style.display = 'none';
    }, 500)
    this.skipPressed.emit(data);
  }
}

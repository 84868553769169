const welcomeMessages: string[] = [
    `!Hola¡, ¿Necesitas imprimir un cupón de pago con la información de tu factura de servicios públicos?.  ¡Aquí lo puedes hacer con el botón "Imprimir cupón de pago"!`,
    `Si quieres hacer un abono a la deuda de tu cuenta de servicios, toca el botón "Abonar a la factura".`,
    "¿Necesitas conocer los requisitos para realizar un trámite?  Elige el botón trámites, busca el trámite que necesitas y podrás ver la lista de requisitos.",
    `¿Necesitas separar tu factura de agua, energía o gas? Acércate y presiona el botón: "Separar servicio energía, agua o gas".`
]

export const getWelcomeMessage = (): string => {
    const index = Math.floor(Math.random() * welcomeMessages.length);   
    return welcomeMessages[index];
}
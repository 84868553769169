import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription, firstValueFrom } from 'rxjs';
import { TextToSpeechService } from './chat/text-to-speech.service';
import { AudioControllerService } from './audio-controller.service';
import { AudioData } from '../models/audio-data.model';
import { Audio } from 'three';
import { Engine } from '../models/enums/engine';

declare global {
  // JS > TS
  interface Window { receiveKeyboardOnOff(status: boolean): void; }
  interface Window { receiveSpeechFinished(): void; }
  interface Window { receiveAvatarLoaded(): void; }
}

declare const window: Window & typeof globalThis;

@Injectable({
  providedIn: 'root'
})
export class UnityControllerService {

  public UnityInstance: any;

  // Subjects to emit messages from when they are received
  private keyboardOnOff = new Subject<boolean>();
  private speakState = new Subject<void>();
  private speechState = new Subject<void>();
  private speechStateSubscription: Subscription | undefined;
  private avatarState = new Subject<void>();
  private speechBlendData = new Subject<any>();
  private cameraState = new Subject<string>();
  private speechCanceled = new Subject<void>();
  private isIOs = new Subject<void>();

  private audioBlob = new Subject<Blob>()
  private _engine = Engine.Unity;
  public get engine() {
    return this._engine;
  }
  public set engine(value) {
    this._engine = value;
  }

  private volumeLocal?: number;
  private speechDataFinal: AudioData = {
    audio: '',
    audioDuration: 0,
    blendData: [],
    speech: '',
    expressions: '',
    movements: ''
  };
  private speechStopped = false;

  private avatarLoaded = false;

  constructor(private textToSpeechService: TextToSpeechService, private audioControllerService: AudioControllerService) {
    window.receiveKeyboardOnOff = (status: boolean) => {
      this.keyboardOnOff.next(status);
    }

    window.receiveSpeechFinished = () => {
      this.speechState.next();
    }

    window.receiveAvatarLoaded = () => {
      this.avatarLoaded = true;
      this.avatarState.next();

      window.receiveAvatarLoadedUnityController();

      const volumeLocal = localStorage.getItem('volume');
      this.volumeLocal = parseInt(volumeLocal || '100');
      this.UnityInstance.send('JSReceiver', 'ChangeVolumen', this.volumeLocal);
    }
  }

  public setUnityInstance(UnityInstance: any) {
    this.UnityInstance = UnityInstance;
  }

  // Observable to know when the keyboard is on or off
  public getKeyboardOnOff(): Observable<boolean> {
    return this.keyboardOnOff.asObservable();
  }

  // Send the volume change to Unity WebGL
  public volumeChange(volume: number) {
    if (this.UnityInstance !== undefined) {
      this.UnityInstance.send('JSReceiver', 'ChangeVolumen', volume);
    } else {
      this.audioControllerService.updateVolume(volume);
    }
  }

  // Send speech to Unity to be processed and return the response when unity finish the speech
  public async sendSpeechDataToUnity(speechData: any): Promise<void> {
    return new Promise<void>((resolve) => {
      this.speechStopped = false;
      firstValueFrom(this.textToSpeechService.textToSpeech(speechData.speech)).then((audioData) => {
        if (this.speechStopped) {
          resolve();
          return;
        }
        const blobUrl = URL.createObjectURL(audioData.audio);
        this.speechDataFinal = {
          audio: blobUrl,
          audioDuration: audioData.audioDuration,
          blendData: audioData.blendData,
          speech: speechData.speech,
          expressions: speechData.expressions,
          movements: speechData.movements
        };
        this.speakState.next();
        if (this.UnityInstance === undefined) {
          if (audioData.audioDuration !== undefined && audioData.audioDuration != 0) {
            if (this.engine === Engine.Talkinghead)
              this.audioBlob.next(audioData.audio)
            else {
              this.audioControllerService.playAudioFromBlob(audioData.audio);
              this.speechBlendData.next(this.speechDataFinal);
            }
          }
          else {
            URL.revokeObjectURL(blobUrl);
            resolve();
          }
        } else {
          if (this.avatarLoaded) {
            this.UnityInstance.send('JSReceiver', 'SetSpeech', this.speechDataFinal);
          } else {

            this.audioControllerService.playAudioFromBlob(audioData.audio);
            this.speechState.next();
          }
        }

        this.audioControllerService.pauseMusic();

        if (this.speechStateSubscription) {
          this.speechStateSubscription.unsubscribe();
        }

        this.speechStateSubscription = this.getSpeechState().subscribe(() => {
          this.audioControllerService.resumeMusic();
          this.textToSpeechService.clearBlendData();
          URL.revokeObjectURL(blobUrl);
          resolve();
        });
      });
    });
  }

  public getSpeechBlendData(): Observable<void> {
    return this.speechBlendData.asObservable();
  }

  public getAudioBlob(): Observable<Blob> {
    return this.audioBlob.asObservable()
  }

  // Observable to know when the speech is finished
  public getSpeechState(): Observable<void> {
    return this.speechState.asObservable();
  }

  // Cancel the speech in Unity
  public cancelSpeech() {
    this.speechDataFinal = {
      audio: '',
      audioDuration: 0,
      blendData: [],
      speech: '',
      expressions: '',
      movements: ''
    };

    if (this.UnityInstance !== undefined) {
      this.UnityInstance.send('JSReceiver', 'InterruptResponse');
    } else {
      this.audioControllerService.skipAudioFromBlob();
      this.speechCanceled.next();
    }
    this.speechStopped = true;
  }

  public sendChangeCamera(cameraMov: string) {
    if (this.UnityInstance !== undefined) {
      this.UnityInstance.send('JSReceiver', 'ChangeCamera', cameraMov);
    }
    else {
      this.cameraState.next(cameraMov);
    }
  }

  public sendShow3DObject(objectName: string) {
    if (this.UnityInstance !== undefined) {
      this.UnityInstance.send('JSReceiver', 'Show3DObject', objectName);
    }
  }

  public sendBundleURL(bundleUrl: string) {
    if (this.UnityInstance !== undefined) {
      this.UnityInstance.send('JSReceiver', 'ReceiveBundleURL', bundleUrl);
    }
  }

  public sendBundleName(bundleUrl: string) {
    if (this.UnityInstance !== undefined) {
      this.UnityInstance.send('JSReceiver', 'ReceiveBundleName', bundleUrl);
    }
  }

  public sendTenantParams(tenantParams: string) {
    if (this.UnityInstance !== undefined) {
      this.UnityInstance.send('JSReceiver', 'SetTenantParams', tenantParams);
    }
  }

  public sendOrientation(orientation: number) {
    if (this.UnityInstance !== undefined) {
      this.UnityInstance.send('JSReceiver', 'SetOrientation', orientation);
    }
  }

  public sendIsiOS() {
    if (this.UnityInstance !== undefined) {
      this.UnityInstance.send('JSReceiver', 'SetIsiOS');
    }
  }

  public getAvatarState(): Observable<void> {
    return this.avatarState.asObservable();
  }

  public getCameraState(): Observable<string> {
    return this.cameraState.asObservable();
  }

  public getSpeechCanceled(): Observable<void> {
    return this.speechCanceled.asObservable();
  }

  public getIOs(): Observable<void> {
    return this.isIOs.asObservable();
  }

  public getSpeakState(): Observable<void> {
    return this.speakState.asObservable();
  }

  public setIOs() {
    this.audioControllerService.setIOs();
    this.isIOs.next();
  }

  public setSpeakState() {
    this.speakState.next();
  }

  public setAvatarState() {
    this.avatarState.next();
  }
}

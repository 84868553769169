import { Component, ViewChild, ElementRef, OnInit, Input } from '@angular/core';
import * as THREE from 'three';
import { Observable, Subscription } from 'rxjs';
import { AnimationSystemService } from './services/animation/animation-system.service';
import { ExpressionSystemService } from './services/animation/expressions/expression-system.service';
import { AvatarLoaderService } from './services/avatar/avatar-loader.service';
import { ThreeAvatar } from 'src/app/models/three-avatar.model';
import { AzureLipsyncService } from './services/lipsync/azure-lipsync.service';
import { GazeFacadeService } from './services/movement/gaze/gaze-facade.service';
import { Resizer } from './classes/resizer';
import { CameraMovementService } from './services/movement/camera/camera-movement.service';
import { InterfazUsuario } from 'src/app/models/tenant-data.model';

@Component({
  selector: 'app-threejs-avatars',
  templateUrl: './threejs-avatars.component.html',
  styleUrls: ['./threejs-avatars.component.scss'],
  providers: [GazeFacadeService, CameraMovementService]
})
export class ThreejsAvatarsComponent implements OnInit {
  @ViewChild('threeJsContainer') container!: ElementRef;
  @ViewChild('canvas') canvas!: ElementRef;
  subscriptions$: Subscription[] = [];

  public elderAdultLayout!: boolean;
  public windowWidth: number = window.innerWidth;
  public windowHeight: number = window.innerHeight;
  public aspectRatio: number = this.windowWidth / this.windowHeight;
  public fieldOfView = 20;
  public nearClippingPane = 0.01;
  public farClippingPane = 1000;

  private camera!: THREE.PerspectiveCamera;
  private targetPoint!: THREE.Object3D;
  private renderer!: THREE.WebGLRenderer;
  private scene = new THREE.Scene();
  private resizer!: Resizer;
  private background!: THREE.Color;
  private hasFocus = true;
  avatarLoaded = false;
  fullScreen = false;

  constructor(private avatarLoaderService: AvatarLoaderService, private azureLipsyncService: AzureLipsyncService,
    private animationSystemService: AnimationSystemService, private expressionSystemService: ExpressionSystemService,
    private gazeFacadeService: GazeFacadeService, private cameraMovementService: CameraMovementService) { }

  ngOnInit(): void {
    this.initServices();
  }

  initServices() {
    this.subscriptions$.push(
      this.avatarLoaderService.getAvatarInfo().subscribe((avatarInfo: ThreeAvatar) => {
        this.avatarLoaded = true;
        this.createScene(avatarInfo);
      })
    );
    window.addEventListener('focus', () => {
      this.hasFocus = true;
    });
    window.addEventListener('blur', () => {
      this.hasFocus = false;
    });
  }

  public setBackground(ui: InterfazUsuario) {
    if (ui.tipoFondo === "1") {
      this.background = new THREE.Color(ui.fondo);
    }
  }

  private async createScene(avatarInfo: ThreeAvatar) {
    this.scene = new THREE.Scene();
    if (this.background) this.scene.background = this.background;
    this.scene.add(avatarInfo.object);
    this.createLights();
    this.createCamera();
    this.targetPoint = new THREE.Object3D();
    this.scene.add(this.targetPoint);
    this.startRendering();
  }

  private createCamera() {
    this.camera = new THREE.PerspectiveCamera(
      this.fieldOfView,
      this.aspectRatio,
      this.nearClippingPane,
      this.farClippingPane
    );
    this.cameraMovementService.setCamera(this.camera);
  }

  private createLights() {
    const hemiLight = new THREE.HemisphereLight(0xF4D9B5, 0xF4D9B5, 1.2);
    this.scene.add(hemiLight);

    const target = new THREE.Object3D();
    target.position.set(0, 15, 0);
    this.scene.add(target);

    const directionalLight = new THREE.DirectionalLight(0xFFFFFF, 2.5);
    directionalLight.position.set(-0.5, 15, 2);

    const directionalLight2 = new THREE.DirectionalLight(0xFFFFFF, 2.3);
    directionalLight2.position.set(2, 13, -0.6)
    this.configureLight(directionalLight, target);
    this.configureLight(directionalLight2, target);

    this.scene.add(directionalLight);
    this.scene.add(directionalLight2);
  }

  private configureLight(light: THREE.DirectionalLight, target: THREE.Object3D) {
    light.target = target;
    light.castShadow = true;
    light.shadow.bias = -0.0001;
    light.shadow.mapSize.height = 1024 * 4;
    light.shadow.mapSize.width = 1024 * 4;
  }

  private startRendering() {
    this.renderer = new THREE.WebGLRenderer({ canvas: this.canvas.nativeElement, context: this.canvas.nativeElement.getContext('webgl2'), antialias: true });
    this.renderer.shadowMap.enabled = true;
    this.resizer = new Resizer(this.camera, this.renderer, this.container.nativeElement, this.targetPoint, this.elderAdultLayout, this.cameraMovementService, this.gazeFacadeService);
    this.render();
  }

  private render() {
    if (!document.hidden && this.hasFocus) {
      this.azureLipsyncService.playSpeech();
      this.animationSystemService.update();
      this.expressionSystemService.updateExpressions();
      this.gazeFacadeService.update();
      this.cameraMovementService.update();
      this.renderer.render(this.scene, this.camera);
    }
    requestAnimationFrame(() => this.render());
  }

  setCenterAvatarObservable(observable: Observable<boolean>) {
    observable.subscribe((res: boolean) => {
      this.fullScreen = res;
      this.recursiveSetNoChat(res);
    })
  }
  recursiveSetNoChat(value: boolean) {
    setTimeout(() => {
      if (this.resizer) this.resizer.setNoChat(value)
      else this.recursiveSetNoChat(value);
    }, 500);

  }

}
